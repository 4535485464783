import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerKeyPress } from "../../../utils";

const QuotationConfirm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  //Addition
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [number3, setNumber3] = useState(0);
  const [number4, setNumber4] = useState(0);
  const [number5, setNumber5] = useState(0);
  const [number6, setNumber6] = useState(0);
  const [number7, setNumber7] = useState(0);
  const [number8, setNumber8] = useState(0);
  const [number9, setNumber9] = useState(0);
  const [total, setTotal] = useState(0);

  //TAX
  const [pointsPossible, setPointsPossible] = useState(0);
  const [percentage, setPercentage] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post(
        "/v1/user/updateamountjob",
        $("#calculateform").serialize()
      ).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Updated Successfully..!", "success");
          document.getElementById("calculateform").reset();
          setBtnLoading(false);
          if (props.afterFinish) props.afterFinish();
        } else {
          toast.error("Something Went Wrong..!", "danger");
          //document.getElementById("frm_update").reset();
        }
        setIsLoaded(false);
      });
    } catch (error) {}
  };

  useEffect(() => {
    savelog();
  }, []);

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to Edit Enquiry Service Info ".props.data.id);
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const calculate = () => {
    let totCharge =
      $("#transportcharges").val() != ""
        ? parseFloat($("#transportcharges").val())
        : 0;
    let totCharge2 =
      $("#cartransportationcharges").val() != ""
        ? parseFloat($("#cartransportationcharges").val())
        : 0;
    let totCharge3 =
      $("#packingcharges").val() != ""
        ? parseFloat($("#packingcharges").val())
        : 0;
    let totCharge4 =
      $("#loadingcharges").val() != ""
        ? parseFloat($("#loadingcharges").val())
        : 0;
    let totCharge5 =
      $("#unloadingcharges").val() != ""
        ? parseFloat($("#unloadingcharges").val())
        : 0;
    let totCharge6 =
      $("#unpackingcharges").val() != ""
        ? parseFloat($("#unpackingcharges").val())
        : 0;
    let totCharge7 =
      $("#acremovingcharges").val() != ""
        ? parseFloat($("#acremovingcharges").val())
        : 0;
    let totCharge8 =
      $("#carpentercharges").val() != ""
        ? parseFloat($("#carpentercharges").val())
        : 0;
    let totCharge9 =
      $("#profitcharges").val() != ""
        ? parseFloat($("#profitcharges").val())
        : 0;
    setTotal(
      totCharge +
        totCharge2 +
        totCharge3 +
        totCharge4 +
        totCharge5 +
        totCharge6 +
        totCharge7 +
        totCharge8 +
        totCharge9
    );
  };

  const taxCalculate = () => {
    setPercentage((+total * +pointsPossible) / 100);
  };
  console.log(props.data.id);

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        <div className="">
          <Row className="mt-3">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="calculateform"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input
                  type="hidden"
                  value={props.data.enquiry_id}
                  name="enquiry_id"
                />

                <input type="hidden" value={total + percentage} name="amount" />
                <input type="hidden" value={total} name="total" />
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={4}>
                        <label>Transportation Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          size="md"
                          name="transportcharges"
                          id="transportcharges"
                          defaultValue={props.data.transportcharges}
                          onChange={(e) => setNumber1(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                    {/*<MDBInput 
											label="Transportation Charges"
											type="text"
											name="transportcharges"
											id="transportcharges"
											size="lg"
											value={number1}
											onChange={e => setNumber1(+e.target.value)}
											onKeyPress={integerKeyPress}
										/> */}
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={4}>
                        <label>Car Transportation Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="cartransportationcharges"
                          id="cartransportationcharges"
                          size="md"
                          defaultValue={props.data.cartransportationcharges}
                          onChange={(e) => setNumber2(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Packing Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="packingcharges"
                          id="packingcharges"
                          size="md"
                          defaultValue={props.data.packingcharges}
                          onChange={(e) => setNumber3(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Loading Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="loadingcharges"
                          id="loadingcharges"
                          size="md"
                          defaultValue={props.data.loadingcharges}
                          onChange={(e) => setNumber4(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Unloading Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="unloadingcharges"
                          id="unloadingcharges"
                          size="md"
                          defaultValue={props.data.unloadingcharges}
                          //value={number5}
                          onChange={(e) => setNumber5(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Unpacking & re arranging Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="unpackingcharges"
                          id="unpackingcharges"
                          size="md"
                          defaultValue={props.data.unpackingcharges}
                          //value={number6}
                          onChange={(e) => setNumber6(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Ac Removing Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="acremovingcharges"
                          id="acremovingcharges"
                          size="md"
                          defaultValue={props.data.acremovingcharges}
                          //value={number7}
                          onChange={(e) => setNumber7(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Carpenter/Electrician Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="carpentercharges"
                          id="carpentercharges"
                          size="md"
                          defaultValue={props.data.carpentercharges}
                          //value={number8}
                          onChange={(e) => setNumber8(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Profit Charges</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          size="md"
                          name="profitcharges"
                          id="profitcharges"
                          defaultValue={props.data.profitcharges}
                          //value={number9}
                          onChange={(e) => setNumber9(+e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1} className="mt-4">
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={calculate}
                      className="btn-block"
                    >
                      Calc
                    </Button>
                  </Col>
                  <Col md={1} className="mt-4">
                    <label>Total </label>
                  </Col>
                  <Col md={4} className="mt-3">
                    <Form.Control
                      type="text"
                      name="total"
                      id="total"
                      defaultValue={total > 0 ? total : props.data.total}
                      //value={total}
                      onKeyPress={integerKeyPress}
                      disabled
                    />
                  </Col>
                  <Col md={6} className="mt-3">
                    <Row>
                      <Col md={4}>
                        <label>Tax</label>
                      </Col>
                      <Col md={8}>
                        <Form.Control
                          type="text"
                          name="tax"
                          id="tax"
                          size="md"
                          defaultValue={props.data.tax}
                          //value={pointsPossible}
                          onChange={(e) => setPointsPossible(e.target.value)}
                          onKeyPress={integerKeyPress}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={1} className="mt-4">
                    <Button
                      size="sm"
                      variant="primary"
                      onClick={taxCalculate}
                      className="btn-block"
                    >
                      Tax
                    </Button>
                  </Col>
                  <Col md={1} className="mt-4">
                    <label>Grand Total</label>
                  </Col>
                  <Col md={4} className="mt-3">
                    <Form.Control
                      type="number"
                      name="amount"
                      id="amount"
                      placeholder="0"
                      defaultValue={
                        total > 0 ? total + percentage : props.data.amount
                      }
                      //value={total+percentage}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={10}></Col>
                  <Col md={2}>
                    <Button
                      type="submit"
                      size="md"
                      variant="success"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default QuotationConfirm;
