import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerIndMobile, integerKeyPress } from "../../../utils";
import Enquiry from "../../enquiry";

const OrderConfrimStatus = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [data, changeData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/job/updatebooked", $("#frm_update").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Updated Successfully..!", "success");
            document.getElementById("frm_update").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
            if (props.afterList) props.afterList();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("frm_update").reset();
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    savelog();
  }, []);

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to Edit Enquiry Service Info ".props.data.id);
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listdata = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/enquirylist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          //toast.success(res['data'].message, "success");
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <div>
        <Row className="mt-1">
          <Col md={12}>
            <Form
              action=""
              method="post"
              id="frm_update"
              validated={validated}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                value={props.data.enquiry_id}
                name="enquiry_id"
              />
              <Row className="mt-3">
                <Col md={2}>
                  <label>Status</label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    as="select"
                    name="update_status"
                    id="update_status"
                    size="md"
                    required
                  >
                    <option value="">- Select -</option>
                    <option value="Confirm">Confirm</option>
                    <option value="Booked">Booked</option>
                    <option value="Rejected">Rejected</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={2}>
                  <label>Text</label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    type="text"
                    size="md"
                    name="update_text"
                    id="update_text"
                    placeholder="Update Text"
                    className="text-uppercase"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={2}>
                  <label>Date</label>
                </Col>
                <Col md={9}>
                  <Form.Control
                    type="date"
                    size="md"
                    name="update_date"
                    id="update_date"
                    placeholder="Date"
                    required
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col md={12}>
                  <Button
                    type="submit"
                    size="md"
                    variant="primary"
                    className="btn-block"
                    disabled={btnLoading}
                  >
                    {btnLoading ? "Please wait.." : "Update"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderConfrimStatus;
