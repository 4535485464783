import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { MDBInput } from "mdb-react-ui-kit";
import API from "../../utils/api";
import { Link } from "react-router-dom";
import $ from "jquery";
import { Table } from "antd";
import EditPersonal from "./editPersonalInfo";
import EditService from "./editServiceInfo";
import OrderStatus from "./orderStatus";
import Quotation from "./quotation";
import { quotationPdf, numberToWords } from "../../utils";

const Enquiry = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [orderStatus, setOrderStatus] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);
  const [editService, setEditService] = useState(false);
  const [data, changeData] = useState([]);
  const [branch, changeBranch] = useState([]);
  const [enquiryData, setEnquiryData] = useState([]);
  const [peopleInfo, setPeopleInfo] = useState([]);
  const [validated, setValidated] = useState(false);
  const [btnEnable, setBtnEnable] = useState(false);
  const [whatsapp, changeWhatsapp] = useState([]);
  const [smsSend, changeSmsSend] = useState([]);
  const [quotation, changeQuotation] = useState([]);
  const [mail, changeMail] = useState([]);
  const [paginationPageSize, setpaginationPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, changeSearchData] = useState([]);
  const [dateField, setDateField] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [masterData, setMasterData] = useState([]);
  const [printData, setPrintData] = useState([]);
  const thStyle = {
    border: "1px solid black",
    borderRadius: "10px",
    padding: "1px",
  };
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const refdate = `${current.getDate()}`;
  const monthNames = [
    "JAN",
    "FEB",
    "MARCH",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEPT",
    "OCT",
    "Nov",
    "DEC",
  ];
  const d = new Date();

  useEffect(() => {
    var date =
      new Date().getDate() +
      "-" +
      (new Date().getMonth() + 1) +
      new Date().getFullYear();
    setDateField(date);
    listdata();
    savelog();
    //listBranch();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/user/enquirylist", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          //toast.success(res['data'].message, "success");
          changeData(d);
          setMasterData(d);
          setEnquiryData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handlePaginationSizeChange = (current, pageSize) => {
    console.log("current" + current);
    setpaginationPageSize(pageSize);
  };

  const handleTablePageChange = (current) => {
    setCurrentPage(current);
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Dashboard");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const editPersonal = (item) => {
    setEditData(item);
    setEditUser(true);
  };

  const UpdateOrder = (item) => {
    setEditData(item);
    setOrderStatus(true);
  };

  const UpdateAmount = (item) => {
    setEditData(item);
    setUpdateAmount(true);
  };

  const editServiceInfo = (item) => {
    setEditData(item);
    setEditService(true);
  };

  const handleDeleteClick = (item, enquiry_id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("enquiry_id", enquiry_id);
      API.post("v1/user/deleteenquiry", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          var d = data;
          var index = d.findIndex(function (obj) {
            return obj.id == enquiry_id;
          });
          if (index > -1) d.splice(index, 1);
          setEnquiryData(d);
          changeData(d);
          setIsLoaded(false);
          listdata();
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleDeleteClickCheck = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("peopleInfo", JSON.stringify(peopleInfo));
      API.post("v1/user/deleteenquirychecked", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          $(".checking").prop("checked", false);
          setBtnEnable(false);
          setIsLoaded(false);
          listdata();
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleChecked = (id, e) => {
    setBtnEnable(true);
    var att = peopleInfo;
    if (e.target.checked) {
      var index = att.findIndex(function (obj) {
        return obj.id == id;
      });
      if (index === -1) {
        att.push({ id: id, remarks: 0 });
      }
    } else {
      var index = att.findIndex(function (obj) {
        return obj.id == id;
      });
      att.splice(index, 1);
    }
    setPeopleInfo(att);
  };

  const handleSendWhatsapp = (item, name, mobile, amount, customer_title) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("name", name);
      form.append("mobile", mobile);
      form.append("amount", amount);
      form.append("customer_title", customer_title);
      API.post("v1/user/sendwhatsappenquiry", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success(
            "Quotation WhatsApp Message Sended Successfully",
            "success"
          );
          var d = res["data"].data;
          changeWhatsapp(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleSendSms = (item, mobile, name, amount, customer_title) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("amount", amount);
      form.append("customer_title", customer_title);
      API.post("v1/user/sendsmsenquiry", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Quotation SMS Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleQuotationMail = (
    item,
    emailid,
    mobile,
    name,
    customer_title,
    street,
    place,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    amount,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    storagecharges,
    octroicharges,
    e
  ) => {
    try {
      setBtnLoading(true);
      setPrintData(item);

      console.log($("#quotationpdff").html());
      var form = new FormData();
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("customer_title", customer_title);
      form.append("street", street);
      form.append("place", place);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("amount", amount);
      form.append("acremovingcharges", acremovingcharges);
      form.append("carpentercharges", carpentercharges);
      form.append("storagecharges", storagecharges);
      form.append("octroicharges", octroicharges);

      //form.append('quotationPdf', $('#quotationpdff').html());
      API.post("v1/user/sendquotationpdf", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Quotation Mail Sended Successfully", "success");
          var d = res["data"].data;
          changeQuotation(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleQuotationWhatsapp = (
    item,
    enquiry_id,
    emailid,
    mobile,
    name,
    customer_title,
    street,
    place,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    amount,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    storagecharges,
    octroicharges,
    e
  ) => {
    try {
      setBtnLoading(true);
      setPrintData(item);

      //console.log($("#quotationpdff").html());
      var form = new FormData();
      form.append("enquiry_id", enquiry_id);
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("customer_title", customer_title);
      form.append("street", street);
      form.append("place", place);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("amount", amount);

      //form.append('quotationPdf', $('#quotationpdff').html());
      API.post("v1/user/sendquotationwhatsapppdf", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Quotation WhatsApp Sended Successfully", "success");
          var d = res["data"].data;
          changeQuotation(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleSendWhatsappFollowUp = (item, city, city_ds, mobile) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("mobile", mobile);
      API.post("v1/user/sendwhatsappfollowup", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success(
            "FollowUp  WhatsApp Message Sended Successfully",
            "success"
          );
          var d = res["data"].data;
          changeWhatsapp(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  //sms
  const handleSendSmsFollowUp = (item, city, city_ds, mobile) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("mobile", mobile);
      API.post("v1/user/sendsmsfollowup", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listBranch = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listbranch").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBranch(d);
          //setBranchData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "",
      key: "",
      render: (item, id) => (
        <>
          <input
            type="checkbox"
            value={item.id}
            id="checkbox"
            className="checking"
            onChange={(e) => {
              handleChecked(item.id, e);
            }}
          />
        </>
      ),
    },
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "5px",
      render: (text, item, index) => (
        <>{(currentPage - 1) * paginationPageSize + (index + 1)}</>
      ),
    },
    {
      title: "Enquiry Date",
      dataIndex: "enquiry_date",
      key: "enquiry_date",
      width: "120px",
      align: "center",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      align: "center",
    },
    {
      title: "From",
      dataIndex: "place",
      key: "place",
      align: "center",
      render: (text, record) => (
        <>
          {record.place} - {record.city}
        </>
      ),
    },
    {
      title: "Destination",
      dataIndex: "place_ds",
      key: "place_ds",
      align: "center",
      render: (text, record) => (
        <>
          {record.place_ds} - {record.city_ds}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "id",
      key: "id",
      width: "100px",
      align: "center",
      render: (text, item) => (
        <>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => UpdateOrder(item)}
          >
            Update
          </Button>
          &nbsp;
        </>
      ),
    },
    {
      title: "Update Status",
      dataIndex: "update_text",
      key: "update_text",
      align: "center",
      render: (text, record) => (
        <span className="text-uppercase">
          {record.update_status} - {record.update_date}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record) => <span>{record.amount}</span>,
    },
    {
      title: "Role",
      dataIndex: "Username",
      key: "Username",
      align: "center",
      render: (text, record) => <span>{record.Username}</span>,
    },
    {
      title: "Quotation",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "70px",
      render: (text, item) => (
        <>
          <Button
            size="sm"
            variant="outline-warning"
            onClick={() => UpdateAmount(item)}
          >
            View
          </Button>
          &nbsp;&nbsp;&nbsp;
          <br />
        </>
      ),
    },
    {
      title: "Messages",
      dataIndex: "",
      key: "",
      align: "center",
      width: "50%",
      render: (text, item) => (
        <>
          {item.update_status != "Follow_Up" ? (
            <Button
              size="sm"
              variant="warning"
              data-toggle="tooltip"
              data-placement="top"
              title="SMS"
              onClick={() =>
                handleSendSms(
                  item,
                  item.mobile,
                  item.name,
                  item.amount,
                  item.customer_title
                )
              }
            >
              <i className="fas fa-comment-alt"></i>
            </Button>
          ) : (
            ""
          )}
          &nbsp;&nbsp;
          {item.update_status != "Follow_Up" ? (
            <Button
              size="sm"
              variant="success"
              data-toggle="tooltip"
              data-placement="top"
              title="WHATSAPP"
              onClick={() =>
                handleSendWhatsapp(
                  item,
                  item.name,
                  item.mobile,
                  item.amount,
                  item.customer_title,
                  item.enquiry_id
                )
              }
            >
              <i className="fas fa-sms"></i>
            </Button>
          ) : (
            ""
          )}
          {item.update_status == "Follow_Up" ? (
            <Button
              size="sm"
              variant="warning"
              data-toggle="tooltip"
              data-placement="top"
              title="SMS Follow Up"
              onClick={() =>
                handleSendSmsFollowUp(
                  item,
                  item.city,
                  item.city_ds,
                  item.mobile
                )
              }
            >
              <i className="fas fa-comment-alt"></i>
            </Button>
          ) : (
            ""
          )}
          &nbsp;&nbsp;
          {item.update_status == "Follow_Up" ? (
            <Button
              size="sm"
              variant="success"
              data-toggle="tooltip"
              data-placement="top"
              title="WHATSAPP Follow Up"
              onClick={() =>
                handleSendWhatsappFollowUp(
                  item,
                  item.city,
                  item.city_ds,
                  item.mobile
                )
              }
            >
              <i className="fas fa-sms"></i>
            </Button>
          ) : (
            ""
          )}
        </>
      ),
    },
    {
      title: "Mail Whatsapp",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, item) => (
        <>
          {item.amount != "0" ? (
            <Button
              size="sm"
              variant="info"
              data-toggle="tooltip"
              data-placement="top"
              title="MAIL"
              onClick={(e) =>
                handleQuotationMail(
                  item,
                  item.emailid,
                  item.mobile,
                  item.name,
                  item.customer_title,
                  item.street,
                  item.place,
                  item.pincode,
                  item.transportcharges,
                  item.cartransportationcharges,
                  item.packingcharges,
                  item.loadingcharges,
                  item.unloadingcharges,
                  item.unpackingcharges,
                  item.amount,
                  item.city,
                  item.city_ds,
                  item.acremovingcharges,
                  item.carpentercharges,
                  item.storagecharges,
                  item.octroicharges,
                  e
                )
              }
            >
              <i className="fas fa-envelope"></i>
            </Button>
          ) : (
            " - "
          )}{" "}
          {item.amount != "0" ? (
            <Button
              size="sm"
              variant="success"
              data-toggle="tooltip"
              data-placement="top"
              title="WHATSAPP QUOTATION"
              onClick={(e) =>
                handleQuotationWhatsapp(
                  item,
                  item.enquiry_id,
                  item.emailid,
                  item.mobile,
                  item.name,
                  item.customer_title,
                  item.street,
                  item.place,
                  item.pincode,
                  item.transportcharges,
                  item.cartransportationcharges,
                  item.packingcharges,
                  item.loadingcharges,
                  item.unloadingcharges,
                  item.unpackingcharges,
                  item.amount,
                  item.city,
                  item.city_ds,
                  item.storagecharges,
                  item.octroicharges,
                  e
                )
              }
            >
              <i className="fas fa-sms"></i>
            </Button>
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "Manage",
      dataIndex: "id",
      key: "id",
      width: "400%",
      align: "center",
      render: (text, item) => (
        <>
          <Button
            variant="success"
            size="sm"
            onClick={() => editPersonal(item)}
          >
            <i className="fa fa-user"></i>
          </Button>{" "}
          <Button
            variant="info"
            size="sm"
            onClick={() => editServiceInfo(item)}
          >
            <i className="fa fa-taxi"></i>
          </Button>{" "}
          <Button
            variant="danger"
            size="sm"
            onClick={() => handleDeleteClick(item, item.enquiry_id)}
          >
            <i className="fas fa-trash"></i>
          </Button>
        </>
      ),
    },
  ];

  const onSearch = (e) => {
    try {
      var d = masterData;
      const s = e.target.value.toUpperCase();
      let ndata = d.filter(function (item) {
        const name = item.name.toUpperCase();
        const mobile = item.mobile.toUpperCase();
        const amount = item.amount.toUpperCase();
        const placefrom = item.place.toUpperCase();
        const placeto = item.place_ds.toUpperCase();
        const city = item.city.toUpperCase();
        const cityds = item.city_ds.toUpperCase();
        return (
          name.indexOf(s) > -1 ||
          mobile.indexOf(s) > -1 ||
          amount.indexOf(s) > -1 ||
          placefrom.indexOf(s) > -1 ||
          placeto.indexOf(s) > -1 ||
          city.indexOf(s) > -1 ||
          cityds.indexOf(s) > -1
        );
      });
      if (s == "") {
        changeData(d);
      } else {
        changeData(ndata);
      }
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const changeDate = (e) => {
    setStartDate(e.target.value);
  };

  const enddate = (e) => {
    setEndDate(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const data = { startDate: startDate, endDate: endDate };
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/user/search/date", $("#date_search").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            //setEnquiryData(d);
            changeData(d);
            setIsLoaded(false);
            setBtnLoading(false);
          } else {
            changeData([]);
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const onSearchChange = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() === true) {
      e.stopPropagation();
      setValidated(true);
      return;
    }

    try {
      setBtnLoading(true);
      API.post("v1/job/search/branch", $("#search_branch").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            //setEnquiryData(d);
            changeData(d);
            setIsLoaded(false);
            setBtnLoading(false);
          } else {
            changeData([]);
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Card className="no-shadow">
        <Card.Body>
          <Row>
            <Col md={3}>
              <Card.Title>Enquiries - {data.length}</Card.Title>
            </Col>
            <Col md={6}>
              <Form
                action=""
                method="post"
                id="date_search"
                noValidate
                validated={validated}
                onSubmit={onSubmit}
              >
                <input type="hidden" name="user" value={context.adminUser.id} />
                <Row>
                  <Col md={5} className="mt-2">
                    <MDBInput
                      label="From Date"
                      type="date"
                      name="startDate"
                      id="startDate"
                      size="sm"
                      required
                      onChange={changeDate}
                      selected={startDate}
                      defaultValue={dateField}
                    />
                  </Col>
                  <Col md={5} className="mt-2">
                    <MDBInput
                      label="To Date"
                      type="date"
                      name="endDate"
                      id="endDate"
                      size="sm"
                      required
                      onChange={endDate}
                      selected={endDate}
                      defaultValue={dateField}
                    />
                  </Col>
                  <Col md={2} className="mt-2">
                    <Button
                      type="submit"
                      variant="success"
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <i className="fas fa-search"></i> Search
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col md={3}>
              <div className="input-group">
                <div className="input-group-text bg-white">
                  <i className="fa fa-search"></i>
                </div>
                <Form.Control
                  type="text"
                  size="md"
                  placeholder="Search by . . . . ."
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    onSearch(e);
                  }}
                />
              </div>
            </Col>
            {/*} <Col md={3}>
              <Row>
                <Form
                  action=""
                  method="post"
                  id="search_branch"
                  noValidate
                  validated={validated}
                  onSubmit={onSearchChange}
                >
                  <Col md={12}>
                    <Form.Control
                      as="select"
                      name="username"
                      id="username"
                      size="sm"
                    >
                      <option value=""> -SELECT- </option>
                      {branch.map((item, id) => {
                        return <option value={item.id}>{item.username}</option>;
                      })}
                    </Form.Control>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Button
                      type="submit"
                      variant="warning"
                      size="sm"
                      style={{ float: "right" }}
                    >
                      <i className="fas fa-eye"></i>
                    </Button>
                  </Col>
                </Form>
              </Row>
                    </Col> */}
          </Row>
          <hr />
          {btnEnable ? (
            <div style={{ marginBottom: 16 }}>
              <span>
                <Button
                  variant="outline-danger"
                  size="sm"
                  onClick={handleDeleteClickCheck}
                >
                  <i className="fas fa-trash"></i> Delete
                </Button>
              </span>
            </div>
          ) : (
            ""
          )}
          <div className="mt-10 ps-table">
            <Table
              size="large"
              columns={columns}
              bordered
              footer={() => `Total ${Object.keys(data).length} Enquiry in list`}
              dataSource={data}
              pagination={{
                showSizeChanger: true,
                pageSizeOptions: ["10", "50", "100"],
                pageSize: paginationPageSize,
                position: ["bottomRight"],
                onChange: handleTablePageChange,
                onShowSizeChange: handlePaginationSizeChange,
              }}
            />
          </div>
        </Card.Body>
      </Card>

      <Modal
        show={editUser}
        size="lg"
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Personal Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPersonal
            data={editData}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={editService}
        size="lg"
        onHide={() => setEditService(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Service Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditService
            data={editData}
            afterFinish={() => {
              setEditService(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={orderStatus}
        size="md"
        onHide={() => setOrderStatus(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderStatus
            data={editData}
            afterFinish={() => {
              setOrderStatus(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={updateAmount}
        size="lg"
        onHide={() => setUpdateAmount(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Quotation
            data={editData}
            afterFinish={() => {
              setUpdateAmount(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Enquiry;
