import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerIndMobile, integerKeyPress } from "../../../utils";
import $ from "jquery";
import { Link } from "react-router-dom";
import AddServiceInfo from "./AddServiceInfo";

const ServiceInfo = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [adminCompany, setAdminCompany] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setDataList] = useState(false);
  const [AddModal, setAddModal] = useState(false);
  const [addSelect, setaddSelect] = useState([]);
  const [stdata, stchangeData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  //const [item, setItem] = useState([]);

  useEffect(() => {
    savelog();
    getproduct();
    getmedia();
    getvehicle();
    listItem();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post(
        "/v1/user/updateenquiryservice",
        $("#frm_service").serialize()
      ).then((res) => {
        if (res["data"].errorcode === "200") {
          toast.success("Saved Successfully..!", "success");
          document.getElementById("frm_service").reset();
          setBtnLoading(false);
          if (props.afterFinish) props.afterFinish();
        } else {
          toast.error("Something Went Wrong..!", "danger");
          document.getElementById("frm_service").reset();
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {}
  };

  const listItem = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listitem").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          stchangeData(d);
          setIsLoaded(false);
        } else {
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleChange = (id, name, e) => {
    var d = [];
    var index = addSelect.findIndex(function (obj) {
      return obj.id == id;
    });
    if (index > -1) {
      d.push({ id: id, name: name, value: e });
      const arr = [...addSelect.filter((item, i) => i != index), ...d];
      setaddSelect(arr);
    }
  };

  const openBox = () => {
    var d = [];
    var id = $("#service").val();
    var product_list = $("#service  option:selected").attr("data-name");
    d.push({ id: id, name: product_list, value: "" });
    const arr = [...addSelect, ...d];
    setaddSelect(arr);
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to New Enquiry Service Info");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getproduct = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/surveylist").then((res) => {
        if (res["data"].status == "1") {
          setProductData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getmedia = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listmedia").then((res) => {
        if (res["data"].status == "1") {
          setMediaData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getvehicle = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listvehicle").then((res) => {
        if (res["data"].status == "1") {
          setVehicleData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const productChange = (e) => {
    let d = productData;
    var id = e.target.name;
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    if (index > -1) {
      d[index]["product_value"] = e.target.value;
      setProductData(d);
    }
  };

  const openModal = () => {
    setAddModal(true);
  };

  const checkService = (id) => {
    var d = addSelect;
    var bstate = false;
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    if (index === -1) {
      bstate = true;
    }
    return bstate;
  };

  return (
    <>
      <Form
        action=""
        method="post"
        id="frm_service"
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input
          type="hidden"
          value={JSON.stringify(addSelect)}
          name="products"
        />
        <input type="hidden" value={props.editId} name="enquiry_id" />
        <Card>
          <Card.Header>
            <span style={{ float: "right" }}>
              <Button variant="primary" size="sm" onClick={openModal}>
                <i className="fa fa-plus"></i> Add
              </Button>
            </span>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={3}>
                <Form.Control
                  as="select"
                  name="product_name"
                  id="service"
                  size="sm"
                >
                  <option value=""> -SELECT ITEM- </option>
                  {stdata.map((user, i) => {
                    if (checkService(user.id)) {
                      return (
                        <option value={user.id} data-name={user.product_name}>
                          {user.product_name}
                        </option>
                      );
                    }
                  })}
                </Form.Control>
              </Col>
              <Col md={2}>
                <Button variant="primary" size="sm" onClick={openBox}>
                  <i className="fa fa-plus"></i>{" "}
                </Button>
              </Col>
            </Row>
            <Row className="mt-3">
              {addSelect.map((item, i) => {
                return (
                  <Col md={3}>
                    <label>{item.name}</label>
                    <Form.Control
                      type="text"
                      size="sm"
                      placeholder={item.name}
                      onChange={(e) => {
                        handleChange(item.id, item.name, e.target.value);
                      }}
                    ></Form.Control>
                  </Col>
                );
              })}
            </Row>
            <Row className="mt-3">
              {/*<Col md={3} >
				<label>Vehicle</label>
				<Form.Control
					as="select"
					name="vehicle"
					required
					size="sm"
				>
					<option value="" > Select Vehicle </option>
					{(vehicleData && vehicleData.map((item, id) => {
						return <option value={item.id} > {item.regno} </option>
						}
					))}
				</Form.Control>
				</Col>  */}
              <Col md={3}>
                <label>Vehicle</label>
                <Form.Control
                  type="text"
                  name="vehicle"
                  size="sm"
                ></Form.Control>
              </Col>
              <Col md={3}>
                <label>
                  Basis <span className="text-danger">*</span>
                </label>
                <Form.Control as="select" name="basis" required size="sm">
                  <option value="Part Load"> Part Load </option>
                  <option value="Full Load"> Full Load </option>
                </Form.Control>
              </Col>
              <Col md={3}>
                <label>
                  Media <span className="text-danger">*</span>
                </label>
                <Form.Control as="select" name="media" required size="sm">
                  <option value=""> Select Media </option>
                  {mediaData &&
                    mediaData.map((item, id) => {
                      return <option value={item.id}> {item.media} </option>;
                    })}
                </Form.Control>
              </Col>
              <Col md={3}>
                <label>
                  Moving Date <span className="text-danger">*</span>
                </label>
                <Form.Control
                  type="date"
                  size="sm"
                  name="moving_date"
                  placeholder="Moving Date"
                  onKeyPress={integerKeyPress}
                  required
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <label>Comments</label>
                <Form.Control
                  as="textarea"
                  size="sm"
                  name="comments"
                  placeholder="Comments"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Link
                  to={{
                    pathname: "/survey",
                  }}
                />
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  disabled={btnLoading}
                >
                  {btnLoading ? "Please wait.." : "Update"}
                  <Link
                    to={{
                      pathname: "/survey",
                    }}
                  />
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
      <Modal
        show={AddModal}
        size="sm"
        onHide={() => setAddModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddServiceInfo
            afterFinish={() => {
              setAddModal(false);
              listItem();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default ServiceInfo;
