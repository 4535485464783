import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerIndMobile, decimalKeyPress } from "../../../utils";

const EditRejectedList = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/updatevehicle", $("#frm_edit").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Updated Successfully..!", "success");
            document.getElementById("frm_edit").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
            if (props.afterList) props.afterList();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            document.getElementById("frm_edit").reset();
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        <div className="container">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_edit"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input type="hidden" value={props.data.id} name="id" />
                <Row className="mt-2">
                  <Col md={2}>
                    <label>
                      Date<span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="update_date"
                      placeholder="date "
                      required
                      defaultValue={props.data.update_date}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>
                      Name<span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      placeholder="Name"
                      required
                      defaultValue={props.data.name}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Mobile</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="mobile"
                      placeholder="Mobile"
                      defaultValue={props.data.mobile}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>E-mail</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="emailid"
                      placeholder="emailid"
                      defaultValue={props.data.emailid}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>From</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="city"
                      placeholder="city"
                      defaultValue={props.data.city}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Floor / Lift</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="floor"
                      placeholder="floor"
                      defaultValue={props.data.floor}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Destination</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="city_ds"
                      placeholder="city_ds"
                      defaultValue={props.data.city_ds}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Floor / Lift</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="floor_ds"
                      placeholder="floor_ds"
                      defaultValue={props.data.floor_ds}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Basis</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="basis"
                      placeholder="basis"
                      defaultValue={props.data.basis}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Advertisements</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="media"
                      placeholder="media"
                      defaultValue={props.data.media}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Vehicle</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="vehicle"
                      placeholder="vehicle"
                      defaultValue={props.data.vehicle}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Moving Date</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="moving_date"
                      placeholder="moving_date"
                      defaultValue={props.data.moving_date}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>Comments</label>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="comments"
                      placeholder="comments"
                      defaultValue={props.data.comments}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EditRejectedList;
