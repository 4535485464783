import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../../context';
import API from '../../../utils/api';

const ViewBooked = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');
	

	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				{/* Login page design..*/}
					<div>
						<Row className="mt-1">
							<Col md={12}>
								<Table bordered width="100%">
								<tbody>
									<tr>
										<th>Name:</th><td>{props.data.name}</td>
										<th>Mobile:</th><td>{props.data.mobile}</td>
									</tr>
									<tr>
										<th>From:</th><td>{props.data.city}</td>
										<th>To:</th><td>{props.data.city_ds}</td>
									</tr>
									<tr>
										<th>Date:</th><td>{props.data.city}</td>
										<th>Load Details:</th><td>{props.data.city_ds}</td>
									</tr>
									<tr>
										<th>Update Date:</th><td>{props.data.update_date}</td>
										<th>Media:</th><td>{props.data.media}</td>
									</tr>
									<tr>
										<th>Load Details:</th><td>{props.data.basis}</td>
										<th>Amount:</th><td>{props.data.amount}</td>
									</tr>
									</tbody>
								</Table>
							</Col>
						</Row>
					</div>
				{/* Login page design..*/}
			</div>
			
		</>
	);
};
export default ViewBooked;