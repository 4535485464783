import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Form, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import { PsBredCrumb } from '../../comp';
import $ from 'jquery';

//import EditPersonal from './editPersonalInfo';
import Add from './add';

const Master = (props) =>{
	
	const context = useContext(PsContext);
	const [masterData, setMasterData] = useState([]);
	const [adminCompany, setAdminCompany] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [editData, setEditData] = useState([]);
	const [editUser, setEditUser] = useState(false);
    const [addModal, setAddModal] = useState(false);
	const [editService, setEditService] = useState(false);
	const [data, changeData] = useState([]);
	const [validated, setValidated] = useState(false);
	
	useEffect(() => {
		listdata();
		savelog();
	},[] );
	
	const listdata = () => {
	   try {
		    setIsLoaded(true);
			API.get('v1/user/list/namemaster').then(res => {
			   if (res['data'].status == '1') {
				 var d = res['data'].data;
				 //toast.success(res['data'].message, "success");
				 changeData(d);
				 setMasterData(d);
				 setIsLoaded(false);
			   }
			   else {
				alert(res['data'].message, "danger");
				setIsLoaded(false);
			   }
		 });
	   }
	   catch (error) {
			setIsLoaded(false);
	   }
	}

	const onSearch = (e) => {
		try {
			 var d = masterData;
			 const s = e.target.value.toUpperCase();
			 let ndata = d.filter(function (item) {
				 const name = item.name.toUpperCase();
				 const uname = item.username.toUpperCase();
				 return (name.indexOf(s) > -1  || uname.indexOf(s) > -1);
			 });
			 if(s == ''){
				 changeData(d);
			 } else{
				 changeData(ndata);
			 }
		}
		catch (error) {
			 setIsLoaded(false);
		}
	 }
	
	const savelog = () => {
	   try {
		    setIsLoaded(true);
			var form = new FormData();
			form.append('user',context.adminUser.id);
			form.append('company',context.adminCompany.id);
			form.append('type','POST');
			form.append('note','Click Dashboard');
			API.post('v1/user/savelog',form).then(res => {
			   if (res['data'].status == '1') {
				 setIsLoaded(false);
			   }
			   else {
				//alert(res['data'].msg, "danger");
				setIsLoaded(false);
			   }
		 });
	   }
	   catch (error) {
			setIsLoaded(false);
	   }
	}
	
	const editPersonal = (item) =>{
		setEditData(item);
		setEditUser(true);
	};
	
	const editServiceInfo = (item) =>{
		setEditData(item);
		setEditService(true);
	};
	
	
	const handleDeleteClick = (item,id) => {
		try {
			setIsLoaded(true);
			var form = new FormData();
			form.append('user',context.adminUser.id);
			form.append('company',context.adminCompany.id);
			form.append('id',id); 
			 API.post('v1/user/delete/namemaster',form).then(res => {
			if (res['data'].status == '1') {
			 toast.success("Delete Successfully","success");
			 var d = data;
			 var index = d.findIndex(function(obj){
			 return(obj.id==id);
			 });
			 if(index>-1)
			 d.splice(index,1);
			 changeData(d);
			 setMasterData(d);
			 setIsLoaded(false);
			 listdata();
			}
			else {
			 toast.error(res['data'].message, "danger");
			 setIsLoaded(false);
			}
			 });
		}
		catch (error) {
		 setIsLoaded(false);
		}
	} 
	
	return(
		<>  
          <Modal show={addModal} onHide={()=> {setAddModal(false) }} backdrop="static"> 
				<Modal.Header closeButton >
					<Modal.Title >Add</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Add  afterFinish={() => {setAddModal(false)}} afterList={() => listdata()} />
				</Modal.Body>
				<Modal.Footer>
				
				</Modal.Footer>
                </Modal> 
           
			{/*<div style={{ marginRight: 25 }}>
				<h5>
				    <Button onClick={()=> setAddModal(true) }><i class="fa fa-plus"></i> Add</Button>
				</h5> 
			</div> */}
				
			<Card className="no-shadow" >
			<PsBredCrumb title="Master" >
					<ul className="list-inline" >
						<li className="list-inline-item" >
							<div className="input-group" >
								<div className="input-group-text bg-white">
									<i className="fa fa-search"></i>
								</div>
								<Form.Control 
									type="text"
									size="md"
									placeholder="Search.."
									style={{ width: '200px' }}
									onChange={(e)=> {onSearch(e);}}
								/>
							</div>
						</li>
						<li className="list-inline-item" >
							<Button onClick={()=> setAddModal(true) }><i class="fa fa-plus"></i> Add</Button>
						</li>
					</ul>
				</PsBredCrumb>
				<Card.Body>
					<Row>
						<Col md={12}>
							<Table bordered className="table table-striped">
								<thead>
									<tr>
										<th style={{textAlign:'center'}}  className="font-weight-bold">S.No</th>
										<th style={{textAlign:'center'}}  className="font-weight-bold">Id</th>
										<th style={{textAlign:'center'}}  className="font-weight-bold">Username</th>
										<th style={{textAlign:'center'}}  className="font-weight-bold">Password</th>
										<th style={{textAlign:'center'}}  className="font-weight-bold">Branch</th>
										<th style={{textAlign:'center'}}  className="font-weight-bold">Manage</th>
									</tr>
								</thead>
								<tbody>
								{(data.map((item, id) => {
									return <tr>
										<td style={{textAlign:'center'}}>{id+1}</td>
										<td style={{textAlign:'center'}}>{item.id}</td>
										<td style={{textAlign:'center'}}>{item.username}</td>
										<td style={{textAlign:'center'}}>{item.password}</td>
										<td style={{textAlign:'center'}}>{item.password}</td>
										<td style={{textAlign:'center'}}>
											<Button variant="danger" size="sm" onClick={() => handleDeleteClick(item, item.id)}><i className="fas fa-trash"></i>  </Button>
										</td>
									</tr>
								}
								))}
								</tbody>
							</Table>
						</Col>
					</Row>
					{/*<Modal show={editUser} size="lg" onHide={()=>setEditUser(false)} backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Edit Personal Info</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditPersonal data={editData} afterFinish= {()=> {setEditUser(false); listdata();}} />
						</Modal.Body>
						<Modal.Footer>
							
						</Modal.Footer>
					</Modal>
					<Modal show={editService} size="lg" onHide={()=>setEditService(false)} backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Edit Service Info</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<EditService data={editData} afterFinish= {()=> {setEditService(false); listdata();}} />
						</Modal.Body>
						<Modal.Footer>
							
						</Modal.Footer>
                        </Modal> */}
				</Card.Body>
			</Card>
		</>
	);
};
export default Master;