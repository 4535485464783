import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
//import AddForm from './addReject';
//import EditDash from '././editReject';
//import ViewDash from '././viewReject';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { jsPDF } from "jspdf";
import PsContext from "../../context";
import API from "../../utils/api";
import $ from "jquery";
import { PsBredCrumb } from "../../comp";
import { Link } from "react-router-dom";

const Enquiries = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [data, changeData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [bookedData, changeBookedData] = useState([]);
  const [enquries, changeEnquries] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const btnClick = () => {
    toast("Welcome");
  };

  const viewdash = (item) => {
    setViewData(item);
    setViewUser(true);
  };

  useEffect(() => {
    //listdata();
    //listBooked();
    listEnquries();
    // savelog();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      API.get("v1/job/followuplist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listEnquries = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/listenquries", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeEnquries(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Dashboard");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listBooked = () => {
    try {
      setIsLoaded(true);
      API.get("v1/job/bookedlist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBookedData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title=""></PsBredCrumb>
      <Col md={12}>
        <Row>
          <Col md={12}>
            <Card>
              <Link to="/enquiries" className="links">
                <Card.Header style={{ backgroundColor: "skyblue" }}>
                  Enquiries
                </Card.Header>
              </Link>
              <Card.Body>
                {enquries.map((item, id) => {
                  return (
                    <Row>
                      <Col md={12}>
                        <p style={{ color: "green" }}>
                          Enquiry ID - {item.enquiry_id}
                        </p>
                        Customer: &nbsp;<b>{item.name}</b> ,{item.mobile} &nbsp;{" "}
                        <b>From</b>: {item.place} - <b>To</b> : {item.place_ds}
                        <br />
                        <hr />
                      </Col>
                    </Row>
                  );
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Col>
    </>
  );
};
export default Enquiries;
