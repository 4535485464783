import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {integerIndMobile, integerKeyPress ,lettersOnly, integerIndPincode} from '../../utils';

const EditPersonalInfo = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');

	const handleSubmit = (event) => {
		
	event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('/v1/user/updatepersonal',$("#frm_editpersonal").serialize()).then(res=>{
			if (res['data'].status == '1') {
			 toast.success("Updated Successfully..!", "success");
			 document.getElementById("frm_editpersonal").reset();
			 setBtnLoading(false);
			 if (props.afterFinish) props.afterFinish();
		}
		else {
			 toast.error("Something Went Wrong..!", "danger");
			 document.getElementById("frm_editpersonal").reset();
			
		}
		setIsLoaded(false);
		});
		}
		catch(error){

		}
	}
	
	useEffect(() => {
		savelog();
	},[] );
	
	const savelog = () => {
	   try {
		    setIsLoaded(true);
			var form = new FormData();
			form.append('user',context.adminUser.id);
			form.append('company',context.adminCompany.id);
			form.append('type','POST');
			form.append('note','Click to Edit Enquiry Personal Info '.props.data.id);
			API.post('v1/user/savelog',form).then(res => {
			   if (res['data'].status == '1') {
				 setIsLoaded(false);
			   }
			   else {
				//alert(res['data'].msg, "danger");
				setIsLoaded(false);
			   }
		 });
	   }
	   catch (error) {
			setIsLoaded(false);
	   }
	}
		
	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				<div className="container" >
					<Row className="mt-1" >
						<Col md={12}>
							
							<Form action="" method="post" id="frm_editpersonal" validated={validated} onSubmit={handleSubmit}>
								<input type="hidden" value={props.data.id} name="id" />
								<input type="hidden" value={context.adminUser.id} name="user" />
								<input type="hidden" value={context.adminCompany.id} name="company" />
									<Row className="mt-2">
										<Col md={8} >
											<Row className="mt-2">
												<Col md={2} >
													<label>Name <span className="text-danger">*</span></label>
												</Col>
													<Col md={3} >
													<Form.Control
														as="select"
														name="customer_title"
														required
														size="sm"
														defaultValue={props.data.customer_title}
													>
														<option value="M/S" > M/s </option>
														<option value="Mr"> Mr </option>
														<option value="Mrs"> Mrs </option>
														<option value="Er"> Er </option>
														<option value="Dr"> Dr </option>
														<option value="Fr"> Fr </option>
													</Form.Control>
												</Col> 
												<Col md={7} >
													<Form.Control
														type="text" 
														size="sm" 
														name="name"
														placeholder="Name of the customer"
														required
														defaultValue={props.data.name}
														onKeyPress={lettersOnly}
													/>
												</Col>
											</Row>
								
											<Row className="mt-2">
												<Col md={2}>
													<label>Mobile <span className="text-danger">*</span></label>
												</Col>
												<Col md={3}>
													<Form.Control
														type="text"
														size="sm"
														name="mobile"
														placeholder="Mobile Number"
														onKeyPress={integerIndMobile}
														required
														defaultValue={props.data.mobile}
													/>
												</Col>
												<Col md={2}>
													<label>Email-Id</label>
												</Col>
												<Col md={5}>
													<Form.Control
														type="email"
														size="sm"
														name="emailid"
														placeholder="Email-Id"
														defaultValue={props.data.emailid}
													/>
												</Col>
											</Row>
											
											<Row className="mt-2">
												<Col md={2}>
													<label>Phone</label>
												</Col>
												<Col md={3}>
													<Form.Control
														type="text"
														size="sm"
														name="phone"
														placeholder="Phone Number"
														defaultValue={props.data.phone}
														onKeyPress={integerIndMobile}
													/>
												</Col> 
												<Col md={2}>
													<label>WhatsApp</label>
												</Col>
												<Col md={5}>
													<Form.Control
														type="text"
														size="sm"
														name="whatsapp"
														placeholder="WhatsApp"
														defaultValue={props.data.whatsapp}
														onKeyPress={integerIndMobile}
													/>
												</Col>
											</Row>
										</Col>
										<Col md={4} >
											<Row className="mt-2">
												<Col md={5} >
													<label>Enquiry Id</label>
												</Col>
												<Col md={7} >
													<Form.Control
														type="text"
														size="sm" 
														name="enquiry_id"
														placeholder="Enquiry Id"
														disabled
														defaultValue={props.data.enquiry_id}
													/>
												</Col>
											</Row>
											<Row className="mt-2" >
												<Col md={5} >
													<label>Enquiry Date</label>
												</Col>
												<Col md={7} >
													<Form.Control
														type="date"
														size="sm" 
														name="enquiry_date"
														placeholder="Enquiry Date"
														required
														defaultValue={props.data.enquiry_date}
													/>
												</Col>
											</Row>
											
											<Row className="mt-2" >
												<Col md={5} >
													<label>Enquiry Time</label>
												</Col>
												<Col md={7} >
													<Form.Control
														type="time"
														size="sm" 
														name="enquiry_time"
														defaultValue={props.data.enquiry_time}
													/>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="mt-2">
										<Col md={6}>
											<h5>Address <span className="text-danger">*</span></h5>
											<Row className="mt-4">
												<Col md={3}>
													<label>Street :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="street"
														placeholder="Street"
														defaultValue={props.data.street}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Place :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="place"
														placeholder="Place"
														required
														defaultValue={props.data.place}
														onKeyPress={lettersOnly}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>City :</label>
												</Col>
												<Col md={9}>
													<Form.Control
													type="text"
													size="sm" 
													name="city"
													placeholder="City"
													required
													defaultValue={props.data.city}
													onKeyPress={lettersOnly}
												/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Country :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="country"
														placeholder="Country"
														defaultValue={props.data.country}
														onKeyPress={lettersOnly}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>State :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="state"
														placeholder="State"
														onKeyPress={lettersOnly}
														defaultValue={props.data.state}
												/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Pincode :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="pincode"
														placeholder="Pincode"
														onKeyPress={integerIndPincode}
														defaultValue={props.data.pincode}
												/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Floor/Lift :</label>
												</Col>
												<Col md={5}>
													<Form.Control
														as="select"
														name="frm_floor"
														required
														size="sm"
														defaultValue={props.data.floor}
													>
														<option value="0" > 0 </option>
														<option value="1" > 1 </option>
														<option value="2" > 2 </option>
														<option value="3" > 3 </option>
														<option value="4" > 4 </option>
														<option value="5" > 5 </option>
														<option value="6" > 6 </option>
														<option value="7" > 7 </option>
													</Form.Control>
												</Col>
												<Col md={4}>
													<Form.Control
														as="select"
														name="frm_lift"
														required
														size="sm"
														defaultValue={props.data.lift}
													>
														<option value="Yes" > Yes </option>
														<option value="No" > No </option>
													</Form.Control>
												</Col>
											</Row>
										</Col>
										<Col md={6}>
											<h5>Destination <span className="text-danger">*</span></h5>
											<Row className="mt-4">
												<Col md={3}>
													<label>Street :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="street_ds"
														placeholder="Street"
														onKeyPress={lettersOnly}
														defaultValue={props.data.street_ds}
												/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Place :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="place_ds"
														placeholder="Place"
														required
														onKeyPress={lettersOnly}
														defaultValue={props.data.place_ds}
												/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>City :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="city_ds"
														placeholder="City"
														onKeyPress={lettersOnly}
														required
														defaultValue={props.data.city_ds}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Country :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="country_ds"
														placeholder="Country"
														onKeyPress={lettersOnly}
														defaultValue={props.data.country_ds}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>State :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="state_ds"
														placeholder="State"
														onKeyPress={lettersOnly}
														defaultValue={props.data.state_ds}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Pincode :</label>
												</Col>
												<Col md={9}>
													<Form.Control
														type="text"
														size="sm" 
														name="pincode_ds"
														placeholder="Pincode"
														onKeyPress={integerIndPincode}
														
														defaultValue={props.data.pincode_ds}
													/>
												</Col>
											</Row>
											<Row className="mt-2">
												<Col md={3}>
													<label>Floor/Lift :</label>
												</Col>
												<Col md={5}>
													<Form.Control
														as="select"
														name="dest_floor"
														required
														size="sm"
														defaultValue={props.data.floor_ds}
													>
														<option value="0" > 0 </option>
														<option value="1" > 1 </option>
														<option value="2" > 2 </option>
														<option value="3" > 3 </option>
														<option value="4" > 4 </option>
														<option value="5" > 5 </option>
														<option value="6" > 6 </option>
														<option value="7" > 7 </option>
													</Form.Control>
												</Col>
												<Col md={4}>
													<Form.Control
														as="select"
														name="dest_lift"
														required
														size="sm"
														defaultValue={props.data.lift_ds}
													>
														<option value="Yes" > Yes </option>
														<option value="No" > No </option>
													</Form.Control>
												</Col>
											</Row>
										</Col>
									</Row>
									<Row className="mt-4" >
										<Col md={12} >
											<Button
												type="submit"
												size="md"
												variant="primary"
												className="btn-block"
												disabled={btnLoading}
											>
											{btnLoading ? 'Please wait..' : 'Update'}
											</Button>
										</Col>
									</Row>
							</Form>
							
						</Col>
					</Row>
				</div>
			</div>
			
		</>
	);
};

export default EditPersonalInfo;