import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { integerIndMobile, integerKeyPress } from "../../utils";

const OrderStatus = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      /* console.log(props.data.enquiry_date);
      if ((props.data.enquiry_date = "NULL")) {
        alert("Please Fill The Required Fileds");
      } */

      API.post("/v1/user/updatestatus", $("#frm_update").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Updated Successfully..!", "success");
            document.getElementById("frm_update").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("frm_update").reset();
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    savelog();
  }, []);

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to Edit Enquiry Service Info ".props.data.id);
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        <div className="container">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_update"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input type="hidden" value={props.data.id} name="id" />
                <input
                  type="hidden"
                  value={props.data.enquiry_date}
                  name="enquiry_date"
                />
                {/*}  <Row className="mt-3">
                    <Col md={2}>
                        ID
                    </Col>
                    <Col md="9">
                    <Form.Control
                        size="sm" 
                        placeholder=""
                        required
                        defaultValue={props.data.id}
                        disabled
                    />
                    </Col>
                    </Row> */}
                <Row className="mt-3">
                  <Col md={2}>
                    <label>Status</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      as="select"
                      name="update_status"
                      required
                      size="md"
                      defaultValue={props.data.update_status}
                    >
                      <option value="">- Select -</option>
                      <option value="Follow_Up">Follow Up</option>
                      <option value="Confirm">Confirm</option>
                      <option value="Rejected">Rejected</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2}>
                    <label>Survey</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      as="select"
                      name="survey"
                      //required
                      size="md"
                      defaultValue={props.data.survey}
                    >
                      <option value="">- Select -</option>
                      <option value="survey">Survey</option>
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2}>
                    <label>Text</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="md"
                      name="update_text"
                      placeholder="Update Text"
                      className="text-uppercase"
                      defaultValue={props.data.update_text}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={2}>
                    <label>Date</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="date"
                      size="md"
                      name="update_date"
                      placeholder="Date"
                      required
                      defaultValue={props.data.update_date}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default OrderStatus;
