import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../../context";
import API from "../../../utils/api";
import {
  integerIndMobile,
  integerKeyPress,
  lettersOnly,
  integerIndPincode,
} from "../../../utils";
import $ from "jquery";
import { Link } from "react-router-dom";

const PersonalInfo = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [adminCompany, setAdminCompany] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [branch, changeBranch] = useState([]);
  const [data, setDataList] = useState(false);

  useEffect(() => {
    savelog();
    listBranch();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/saveenquiry", $("#addform").serialize()).then(
        (res) => {
          if (res["data"].errorcode === "200") {
            toast.success("Saved Successfully..!", "success");
            //document.getElementById("addform").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish(res["data"].data);
            //props.setID=(res['data'].data);
            //alert("editID"  +props.editID);
            return res["data"].data;
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("addform").reset();
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  const listBranch = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listbranch").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBranch(d);
          //setBranchData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      console.log(context);
      form.append("user", Number(context.adminUser.id));
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to New Enquiry Personal Info");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Form
        action=""
        method="post"
        id="addform"
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col md={9}>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>
                      Customer Name <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={1}>
                    <Form.Control
                      as="select"
                      name="customer_title"
                      required
                      size="sm"
                    >
                      <option value="M/S"> M/s </option>
                      <option value="Mr"> Mr </option>
                      <option value="Mrs"> Mrs </option>
                      <option value="Er"> Er </option>
                      <option value="Dr"> Dr </option>
                      <option value="Fr"> Fr </option>
                    </Form.Control>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      placeholder="Name of the customer"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={2}>
                    <label>
                      Mobile No <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="mobile"
                      placeholder="Mobile Number"
                      onKeyPress={integerIndMobile}
                      required
                    />
                  </Col>
                  <Col md={2}>
                    <label>Email-Id</label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="email"
                      size="sm"
                      name="emailid"
                      placeholder="Email-Id"
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={2}>
                    <label>Phone</label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="phone"
                      placeholder="Phone Number"
                      onKeyPress={integerIndMobile}
                    />
                  </Col>
                  <Col md={2}>
                    <label>WhatsApp</label>
                  </Col>
                  <Col md={3}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="whatsapp"
                      placeholder="WhatsApp"
                      onKeyPress={integerIndMobile}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                {/*<Row className="mt-2">
									<Col md={4} >
										<label>Enquiry Id</label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="sm" 
											name="enquiry_id"
											placeholder="Enquiry Id"
											required
										/>
									</Col> 
								</Row> */}
                <Row className="mt-2">
                  <Col md={4}>
                    <label>Enquiry Date</label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="enquiry_date"
                      placeholder="Enquiry Date"
                      required
                    />
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col md={4}>
                    <label>Enquiry Time</label>
                  </Col>
                  <Col md={8}>
                    <Form.Control type="time" size="sm" name="enquiry_time" />
                  </Col>
                </Row>
                {/*<Row className="mt-2" >
									<Col md={4} >
										<label>Amount</label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="sm" 
											name="amount"
											required
											onKeyPress={integerKeyPress}
										/>
									</Col>
								</Row> */}
              </Col>
            </Row>
            <Row className="mt-2">
              <Col md={5}>
                <h5>
                  Address <span className="text-danger">*</span>
                </h5>
                <Row className="mt-4">
                  <Col md={3}>
                    <label>Street :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="street"
                      placeholder="Street"
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Place :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="place"
                      placeholder="Place"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>City :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="city"
                      placeholder="City"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                {/*<Row className="mt-2">
									<Col md={3}>
										<label>Country :</label>
									</Col>
									<Col md={9}>
										<Form.Control
											type="text"
											size="sm" 
											name="country"
											placeholder="Country"
											onKeyPress={lettersOnly}
											
										/>
									</Col>
							</Row> */}
                <Row className="mt-2">
                  <Col md={3}>
                    <label>State :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="state"
                      placeholder="State"
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Pincode :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="pincode"
                      placeholder="Pincode"
                      onKeyPress={integerIndPincode}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Floor/Lift :</label>
                  </Col>
                  <Col md={5}>
                    <Form.Control
                      as="select"
                      name="frm_floor"
                      required
                      size="sm"
                    >
                      <option value="0"> 0 </option>
                      <option value="1"> 1 </option>
                      <option value="2"> 2 </option>
                      <option value="3"> 3 </option>
                      <option value="4"> 4 </option>
                      <option value="5"> 5 </option>
                      <option value="6"> 6 </option>
                      <option value="7"> 7 </option>
                    </Form.Control>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      as="select"
                      name="frm_lift"
                      required
                      size="sm"
                    >
                      <option value="Yes"> Yes </option>
                      <option value="No"> No </option>
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col md={2}></Col>
              <Col md={5}>
                <h5>
                  Destination <span className="text-danger">*</span>
                </h5>
                <Row className="mt-4">
                  <Col md={3}>
                    <label>Street :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="street_ds"
                      placeholder="Street"
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Place :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="place_ds"
                      placeholder="Place"
                      onKeyPress={lettersOnly}
                      required
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>City :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="city_ds"
                      placeholder="City"
                      onKeyPress={lettersOnly}
                      required
                    />
                  </Col>
                </Row>
                {/*<Row className="mt-2">
                  <Col md={3}>
                    <label>Country :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="country_ds"
                      placeholder="Country"
                      onKeyPress={lettersOnly}
                    />
                  </Col>
						</Row> */}
                <Row className="mt-2">
                  <Col md={3}>
                    <label>State :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="state_ds"
                      placeholder="State"
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Pincode :</label>
                  </Col>
                  <Col md={9}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="pincode_ds"
                      placeholder="Pincode"
                      onKeyPress={integerIndPincode}
                    />
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={3}>
                    <label>Floor/Lift :</label>
                  </Col>
                  <Col md={5}>
                    <Form.Control
                      as="select"
                      name="dest_floor"
                      required
                      size="sm"
                    >
                      <option value="0"> 0 </option>
                      <option value="1"> 1 </option>
                      <option value="2"> 2 </option>
                      <option value="3"> 3 </option>
                      <option value="4"> 4 </option>
                      <option value="5"> 5 </option>
                      <option value="6"> 6 </option>
                      <option value="7"> 7 </option>
                    </Form.Control>
                  </Col>
                  <Col md={4}>
                    <Form.Control
                      as="select"
                      name="dest_lift"
                      required
                      size="sm"
                    >
                      <option value="Yes"> Yes </option>
                      <option value="No"> No </option>
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      Go To <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      as="select"
                      name="user_id"
                      id="user_id"
                      required
                      size="sm"
                    >
                      <option value=""> -SELECT- </option>
                      {branch.map((item, id) => {
                        return <option value={item.id}>{item.username}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Link
                  to={{
                    pathname: "/survey",
                  }}
                />
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  disabled={btnLoading}
                >
                  {btnLoading ? "Please wait.." : "Save & Next"}
                  <Link
                    to={{
                      pathname: "/survey",
                    }}
                  />
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
export default PersonalInfo;
