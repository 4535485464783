import React, { useState, useContext, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import toast from "react-hot-toast";
import AddConfirm from "./addConfirm";
import EditConfrim from "./editConfirm";
import ViewConfirm from "./viewConfirm";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import $ from "jquery";
import Booked from "./booked";
import Rejected from "./rejected";
import OrderConfrimStatus from "./orderConfirmStatus";
import EditConfirmList from "./EditConfirmList";
import { PsBredCrumb } from "../../../comp";
import QuotationConfirm from "./quotationConfirm";
import EditPersonal from "./editPersonalInfo";
import EditService from "./editServiceInfo";
//import MyDocument from './MyDocument';

const Confirm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [validated, setValidated] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editData, setEditData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const [editConfrim, setEditConfrim] = useState([]);
  const [orderStatus, setOrderStatus] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [smsSend, changeSmsSend] = useState([]);
  const [viewUser, setViewUser] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, changeData] = useState([]);
  const [whatsapp, changeWhatsapp] = useState([]);
  const [editService, setEditService] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [dateField, setDateField] = useState("");
  const [updateAmount, setUpdateAmount] = useState(false);
  const [endDate, setEndDate] = useState("");

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const doc = new jsPDF("l", "pt", "a4");

  const printPdf = () => {
    const pdfdoc = document.getElementById("confirmpdf");
    doc.html(pdfdoc, {
      margins: [40, 60, 40, 60],
      autoPaging: true,
      callback: function (doc) {
        doc.save(date);
      },
      x: 30,
      y: 30,
    });
  };

  useEffect(() => {
    var date =
      new Date().getDate() +
      "-" +
      (new Date().getMonth() + 1) +
      new Date().getFullYear();
    setDateField(date);
    listdata();
    savelog();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/jobconfirmlist", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setEnquiryData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const UpdateOrder = (item) => {
    setEditData(item);
    setOrderStatus(true);
  };

  const UpdateAmount = (item) => {
    setEditData(item);
    setUpdateAmount(true);
  };

  const handleDeleteClick = (item, enquiry_id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("enquiry_id", enquiry_id);
      API.post("v1/user/deleteenquiry", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          var d = data;
          var index = d.findIndex(function (obj) {
            return obj.enquiry_id == enquiry_id;
          });
          if (index > -1) d.splice(index, 1);
          setEnquiryData(d);
          changeData(d);
          setIsLoaded(false);
          listdata();
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Branches");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const onSearch = (e) => {
    try {
      var d = enquiryData;
      const s = e.target.value.toUpperCase();
      let ndata = d.filter(function (item) {
        const name = item.name.toUpperCase();
        const uname = item.mobile.toUpperCase();
        const fromplace = item.city.toUpperCase();
        const toplace = item.city_ds.toUpperCase();
        const amount = item.amount.toUpperCase();
        return (
          name.indexOf(s) > -1 ||
          uname.indexOf(s) > -1 ||
          fromplace.indexOf(s) > -1 ||
          toplace.indexOf(s) > -1 ||
          amount.indexOf(s) > -1
        );
      });
      if (s == "") {
        changeData(d);
      } else {
        changeData(ndata);
      }
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const viewdash = (item) => {
    setViewData(item);
    setViewUser(true);
  };

  const editdash = (item) => {
    setEditConfrim(item);
    setEditUser(true);
  };

  const changeDate = (e) => {
    setStartDate(e.target.value);
  };

  const enddate = (e) => {
    setEndDate(e.target.value);
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() === true) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    const data = { startDate: startDate, endDate: endDate };
    try {
      setBtnLoading(true);
      API.post(
        "v1/user/search/confirmdate",
        $("#date_search").serialize()
      ).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          //toast.success("Message Send Successfully", "success");
          //setEnquiryData(d);
          changeData(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          changeData([]);
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  //sms
  const handleSendSms = (item, mobile, name, update_date, customer_title) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("update_date", update_date);
      form.append("customer_title", customer_title);
      API.post("v1/user/sendsms", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Order Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  //whatsapp
  const handleSendWhatsapp = (
    item,
    mobile,
    name,
    update_date,
    customer_title
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("update_date", update_date);
      form.append("customer_title", customer_title);
      API.post("v1/user/sendwhatsapp", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Order Message Sended Successfully", "success");
          var d = res["data"].data;
          changeWhatsapp(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const editPersonal = (item) => {
    //setEditData(item);
    //setEditUser(true);
  };

  const editServiceInfo = (item) => {
    setEditData(item);
    setEditService(true);
  };

  return (
    <>
      <PsBredCrumb title="Confirm"></PsBredCrumb>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col md={3}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn-right btn btn-success btn-sm"
                  table="table-to-xls"
                  filename="confirmlist"
                  sheet="confirmlist"
                  buttonText={<i className="fas fa-file-excel"> Excel</i>}
                />
                &nbsp;
                <Button size="sm" variant="danger" onClick={printPdf}>
                  <i className="fas fa-file-pdf"> PDF</i>{" "}
                </Button>{" "}
              </Col>
              <Col md={5}>
                <Form
                  action=""
                  method="post"
                  id="date_search"
                  noValidate
                  validated={validated}
                  onSubmit={onSubmit}
                >
                  <Row>
                    <Col md={5} className="mt-2">
                      <MDBInput
                        label="From Date"
                        type="date"
                        name="startDate"
                        id="startDate"
                        size="sm"
                        required
                        onChange={changeDate}
                        selected={startDate}
                        defaultValue={dateField}
                      />
                    </Col>
                    <Col md={5} className="mt-2">
                      <MDBInput
                        label="To Date"
                        type="date"
                        name="endDate"
                        id="endDate"
                        size="sm"
                        required
                        onChange={endDate}
                        selected={endDate}
                        defaultValue={dateField}
                      />
                    </Col>
                    <Col md={2} className="mt-2">
                      <Button
                        type="submit"
                        variant="success"
                        size="sm"
                        style={{ float: "right" }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={4}>
                <div className="input-group">
                  <div className="input-group-text bg-white">
                    <i className="fa fa-search"></i>
                  </div>
                  <Form.Control
                    type="text"
                    size="md"
                    placeholder="Search by Name,Mobile,From,To,Amount"
                    style={{ width: "200px" }}
                    onChange={(e) => {
                      onSearch(e);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Card.Title>
          <Row>
            <Col md={12}>
              <Table bordered className="table table-striped" id="pdf">
                <thead>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      #
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Name
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Mobile
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      From
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      To
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Confirm Date
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Name
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Load Details
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Amount
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Edit
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Quotation
                    </th>
                    <th
                      style={{ textAlign: "center", width: "12%" }}
                      className="font-weight-bold"
                    >
                      Manage
                    </th>
                    <th
                      style={{ textAlign: "center", width: "12%" }}
                      className="font-weight-bold"
                    >
                      Messages
                    </th>
                    {/*<th style={{textAlign:'center'}}  className="font-weight-bold">Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{id + 1}</td>
                        <td style={{ textAlign: "center" }}>{item.name}</td>
                        <td style={{ textAlign: "center" }}>{item.mobile}</td>
                        <td style={{ textAlign: "center" }}>
                          <span data-toggle="tooltip" title={item.city}>
                            {item.city}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <span data-toggle="tooltip" title={item.city_ds}>
                            {item.city_ds}
                          </span>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {item.update_date}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.media}</td>
                        <td style={{ textAlign: "center" }}>{item.basis}</td>
                        <td style={{ textAlign: "center" }}>{item.amount}</td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => UpdateOrder(item)}
                          >
                            Update
                          </Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            size="sm"
                            variant="outline-warning"
                            onClick={() => UpdateAmount(item)}
                          >
                            View
                          </Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="warning"
                            size="sm"
                            onClick={() => viewdash(item, item.id)}
                          >
                            <i className="fas fa-eye"></i>{" "}
                          </Button>{" "}
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => editdash(item)}
                          >
                            <i className="fa fa-user"></i>
                          </Button>{" "}
                          {/*<Button
                            variant="success"
                            size="sm"
                            onClick={() => editPersonal(item)}
                          >
                            <i className="fa fa-user"></i>
                        </Button>{" "} */}
                          <Button
                            variant="info"
                            size="sm"
                            onClick={() => editServiceInfo(item)}
                          >
                            <i className="fa fa-taxi"></i>
                          </Button>{" "}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              handleDeleteClick(item, item.enquiry_id)
                            }
                          >
                            <i className="fas fa-trash"></i>{" "}
                          </Button>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            size="sm"
                            variant="warning"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="SMS"
                            onClick={() =>
                              handleSendSms(
                                item,
                                item.mobile,
                                item.name,
                                item.update_date,
                                item.customer_title
                              )
                            }
                          >
                            <i className="fas fa-comment-alt"></i>
                          </Button>{" "}
                          <Button
                            size="sm"
                            variant="success"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="WHATSAPP"
                            onClick={() =>
                              handleSendWhatsapp(
                                item,
                                item.mobile,
                                item.name,
                                item.update_date,
                                item.customer_title
                              )
                            }
                          >
                            <i className="fas fa-sms"></i>
                          </Button>{" "}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card style={{ display: "none", width: "auto" }}>
        <Table bordered id="confirmpdf">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Mobile
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                From
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                To
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Load Details
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Amount
              </th>
              <th style={{ textAlign: "center", width : '100%' }} className="font-weight-bold">
                Confirm Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, id) => {
              return (
                <tr>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.mobile}</td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city}>
                      {item.city}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city_ds}>
                      {item.city_ds}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>{item.media}</td>
                  <td style={{ textAlign: "center" }}>{item.basis}</td>
                  <td style={{ textAlign: "center" }}>{item.amount}</td>
                  <td style={{ width: "260px" }}>{item.update_date}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>

      <Modal
        show={orderStatus}
        size="md"
        onHide={() => setOrderStatus(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OrderConfrimStatus
            data={editData}
            afterFinish={() => {
              setOrderStatus(false);
            }}
            afterList={() => listdata()}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={viewUser}
        onHide={() => setViewUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewConfirm data={viewData} afterfinish={() => setViewUser(false)} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={editUser}
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditConfrim
            data={editConfrim}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={updateAmount}
        size="lg"
        onHide={() => setUpdateAmount(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Quote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuotationConfirm
            data={editData}
            afterFinish={() => {
              setUpdateAmount(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/*<Modal
        show={editUser}
        size="lg"
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Personal Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPersonal
            data={editData}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
          </Modal> */}
      <Modal
        show={editService}
        size="lg"
        onHide={() => setEditService(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Service Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditService
            data={editData}
            afterFinish={() => {
              setEditService(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Confirm;
