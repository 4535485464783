import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
//import AddForm from './addReject';
//import EditDash from '././editReject';
//import ViewDash from '././viewReject';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { jsPDF } from "jspdf";
import PsContext from "../../../context";
import API from "../../../utils/api";
import $ from "jquery";
import EditRejectedList from "./EditRejectedList";
import { PsBredCrumb } from "../../../comp";

const Rejected = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [editData, setEditData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, changeData] = useState([]);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const doc = new jsPDF("l", "pt", "a4");

  const printPdf = () => {
    const pdfdoc = document.getElementById("rejectedpdf");
    doc.html(pdfdoc, {
      margins: [40, 60, 40, 60],
      autoPaging: true,
      callback: function (doc) {
        doc.save(date);
      },
      x: 30,
      y: 30,
    });
  };

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/rejectlist", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const editdash = (item) => {
    setEditData(item);
    setEditUser(true);
  };

  return (
    <>
      <PsBredCrumb title="Rejected">
        <ul className="list-inline"></ul>
      </PsBredCrumb>
      <Card>
        <Card.Body>
          <Card.Title>
            {/*<Button size="sm"  variant="warning"><i class="fas fa-copy"></i>  Copy</Button>{' '}
					<Button size="sm"  variant="primary"><i class="fas fa-file-csv"></i> CSV</Button>{' '} */}
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button btn-right btn btn-success btn-sm"
              table="table-to-xls"
              filename="rejectedlist"
              sheet="rejectedlist"
              buttonText={<i className="fas fa-file-excel"> Excel</i>}
            />
            &nbsp;
            <Button size="sm" variant="danger" onClick={printPdf}>
              <i className="fas fa-file-pdf"> PDF</i>{" "}
            </Button>{" "}
          </Card.Title>
          <Table id="table-to-xls" bordered className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  #
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Name
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Mobile
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  From
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  To
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Date
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Quote
                </th>
                {/*<th style={{textAlign:'center'}}  className="font-weight-bold">Status</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((item, id) => {
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>{id + 1}</td>
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.mobile}</td>
                    <td style={{ textAlign: "center" }}>
                      <span data-toggle="tooltip" title={item.place}>
                        {item.city}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <span data-toggle="tooltip" title={item.place_ds}>
                        {item.city_ds}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>{item.update_date}</td>
                    <td style={{ textAlign: "center" }}>
                      {item.amount || "0"}
                    </td>
                    {/*<td style={{textAlign:'center'}}>
								<Button size="sm" variant="primary" onClick={() => editdash(item)}><i class="fas fa-eye"></i>  View</Button>
								</td> */}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card style={{ display: "none", width: "auto" }}>
        <Table bordered id="rejectedpdf">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Mobile
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                From
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                To
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Load Details
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Amount
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Confirm Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, id) => {
              return (
                <tr>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.mobile}</td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city}>
                      {item.city}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city_ds}>
                      {item.city_ds}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>{item.media}</td>
                  <td style={{ textAlign: "center" }}>{item.basis}</td>
                  <td style={{ textAlign: "center" }}>{item.amount}</td>
                  <td style={{ width: "250px" }}>{item.update_date}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>

      <Modal
        show={editUser}
        size="lg"
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditRejectedList
            data={editData}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Rejected;
