import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {integerIndMobile, decimalKeyPress, lettersOnly} from '../../utils';

const EditForm = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');

	const handleSubmit = (event) => {
		
	event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('/v1/user/updatemedia',$("#frm_editmedia").serialize()).then(res=>{
			if (res['data'].status == '1') {
			 toast.success("Updated Successfully..!", "success");
			 document.getElementById("frm_editmedia").reset();
			 setBtnLoading(false);
			 if (props.afterFinish) props.afterFinish();
		}
		else {
			 toast.error("Something Went Wrong..!", "danger");
			 document.getElementById("frm_editmedia").reset();
			
		}
		setIsLoaded(false);
		});
		}
		catch(error){

		}
	}
		
	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				{/* Login page design..*/}
					<div className="container" >
						<Row className="mt-1" >
							<Col md={12}>
								
								<Form action="" method="post" id="frm_editmedia" validated={validated} onSubmit={handleSubmit}>
									<input type="hidden" value={props.data.id} name="id" />
									<input type="hidden" value={context.adminUser.id} name="user" />
									<input type="hidden" value={context.adminCompany.id} name="company" />
										<Row>
											<Col md={3}>
												<label>Media <span className="text-danger">*</span></label>
											</Col>
											<Col md={9}>
												<Form.Control
													type="text"
													size="sm" 
													name="name"
													placeholder="Media"
													required
													defaultValue={props.data.media}
													onKeyPress={lettersOnly}
												/>
											</Col>
										</Row>
										
										<Row className="mt-4" >
											<Col md={12} >
												<Button
													type="submit"
													size="md"
													variant="primary"
													className="btn-block"
													disabled={btnLoading}
												>
												{btnLoading ? 'Please wait..' : 'Update'}
												</Button>
											</Col>
										</Row>
								</Form>
								
							</Col>
						</Row>
					</div>
				{/* Login page design..*/}
			</div>
			
		</>
	);
};

export default EditForm;