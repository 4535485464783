import React, { useState, useContext, useEffect } from "react";

import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import AddConfirm from "./addConfirm";
import EditDash from "././editConfirm";
import ViewDash from "././viewConfirm";
import PsContext from "../../../context";
import API from "../../../utils/api";
import $ from "jquery";
import Confirm from "./confirm";
import Booked from "./booked";
import Rejected from "./rejected";
import { PsBredCrumb } from "../../../comp";

const HomeConfirm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, changeData] = useState([]);

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/jobconfirmlist", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title="Jobs">
        <ul className="list-inline">
          <li className="list-inline-item">
            {/*}	<div className="input-group" >
							<div className="input-group-text bg-white">
								<i className="fa fa-search"></i>
							</div>
							<Form.Control 
								type="text"
								size="md"
								placeholder="Search.."
								style={{ width: '450px' }}
							/>
						</div> */}
          </li>
          {/*}	<li className="list-inline-item" >
						<button type="submit" className="btn btn-primary btn-md" onClick={()=>setAdminCompany(true)}>
							<i className="fa fa-plus"></i> New
						</button>
					</li>
					<li className="list-inline-item" >
						<a href="" className="btn btn-primary btn-md" >
							<i className="fa fa-user"></i> User
						</a>
				</li> */}
        </ul>
      </PsBredCrumb>
      <Tabs
        defaultActiveKey="Confirm"
        id="uncontrolled-tab-example"
        animation={true}
        className="mb-3"
      >
        <Tab eventKey="Confirm" title="Confirm">
          <Confirm />
        </Tab>
        <Tab eventKey="Booked" title="Booked">
          <Booked />
        </Tab>
        <Tab eventKey="rejected" title="Rejected">
          <Rejected />
        </Tab>
      </Tabs>
    </>
  );
};
export default HomeConfirm;
