import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Form, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import { PSListItem } from '../../comp';
import { MDBInput } from 'mdb-react-ui-kit';
import API from '../../utils/api';
import { lettersOnly } from '../../utils';
import $ from 'jquery';

const Add = (props) =>{
	
	const context = useContext(PsContext);
	const [submit, sethandleSubmit] = useState(false);
	const [validated, setValidated] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	//const [edit, setEdit] = useState(false);
	const [data, changeData] = useState([]);

	const handleSubmit = (event) => {
		event.preventDefault();
		try{
			setBtnLoading(true);;
			API.post('v1/user/add/namemaster',$("#addform").serialize()).then(res=>{
				if (res['data'].status == '1') {
				  toast.success("Name Added Successfully..!", "success");
				  document.getElementById("addform").reset();
				  setBtnLoading(false);
				  props.afterFinish();
				  props.afterList();
				}
				else {
				  toast.error("Something Went Wrong..!", "danger");
				  document.getElementById("addform").reset();
				}
				setIsLoading(false);
			});
		}
		catch(error){
			toast.error("Something Went Wrong..!", "danger");
		}
	};

	

	return(
		<>
			
			<div class="container">
			<Form action="" method="post" id="addform" noValidate validated={validated} onSubmit={handleSubmit} >
		
					<Row>
						<Col md={12} >
                            <MDBInput
                                label="Enter Username"
                                type="text"
                                id="username"
                                name="username"
                                size="lg"
                                required
                                onKeyPress={lettersOnly}
                            />
						</Col>
                        <Col md={12} className="mt-3" >
                            <MDBInput
                                label="Enter Password"
                                type="text"
                                id="password"
                                name="password"
                                size="lg"
                                required
                            />
						</Col>
                        <Col md={12} className="mt-3" >
                            <MDBInput
                                label="Enter Name"
                                type="text"
                                id="name"
                                name="name"
                                size="lg"
                                required
								onKeyPress={lettersOnly}
                            />
						</Col>
					</Row>
                <Col className="mt-3"> 
					<Button type="submit" style={{float:'right'}} type="submit" size="sm" variant="primary btn-block" disabled={btnLoading}
							>
							{btnLoading ? 'Please wait..' : 'Add'}</Button>
				</Col>
			
			</Form>
			</div>
		</>
	);
};

export default Add;