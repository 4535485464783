import React, { useState, useEffect } from 'react';

import PsContext from './index';
import { getLs, setLs } from '../utils';

const PsContextProvider=(props)=>{
	
	const checkUserLogged=()=>{
		return getLs('admin_logged') || 'no';
	};
	
	const getAdminUser=()=>{
		return getLs('admin_user_data') ? JSON.parse(getLs('admin_user_data')) : [];
	};
	
	const getAdminCompany=()=>{
		return getLs('admin_company_data') ? JSON.parse(getLs('admin_company_data')) : [];
	};
	
	const getAdminApi=()=>{
		return getLs('admin_api') || '';
	};
	
	const [state, setState] = useState([]);
	
	const [adminLogged, setAdminLogged] = useState(checkUserLogged());
	const [adminUser, setAdminUser] = useState(getAdminUser());
	const [adminCompany, setadminCompany] = useState(getAdminCompany());
	const [adminApi, setAdminApi] = useState(getAdminApi());
	
	const saveAdminLogin=(user, company, api)=>{
		setLs('admin_user_data', JSON.stringify(user));
		setAdminUser(user);
		setLs('admin_company_data', JSON.stringify(company));
		setadminCompany(company);
		setLs('admin_api', api);
		setAdminApi(api);
		setLs('admin_logged', 'yes');
		setAdminLogged('yes');
	};
	
	const logoutAdmin=()=>{
		setLs('admin_user_data', false);
		setAdminUser([]);
		setLs('admin_company_data', false);
		setadminCompany([]);
		setLs('admin_api', '');
		setAdminApi(null);
		setLs('admin_logge', 'no');
		setAdminLogged('no');
	};
	
	return (
      <PsContext.Provider value={{
			state: state,
			adminLogged: adminLogged,
			adminUser: adminUser,
			adminCompany: adminCompany,
			adminApi: adminApi,
			saveAdminLogin: saveAdminLogin,
			logoutAdmin: logoutAdmin,
      }}
      >
        {props.children}
      </PsContext.Provider>
	);
};
 
export default PsContextProvider;