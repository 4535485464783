import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { integerIndMobile, decimalKeyPress } from "../../utils";

const EditForm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/editdesptach", $("#frm_editmedia").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Updated Successfully..!", "success");
            document.getElementById("frm_editmedia").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            document.getElementById("frm_editmedia").reset();
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        <div className="container">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_editmedia"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input type="hidden" value={props.data.id} name="id" />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Customer Name"
                      type="text"
                      size="sm"
                      name="owner_name"
                      required
                      defaultValue={props.data.owner_name}
                    />
                  </Col>

                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Customer Contact"
                      type="text"
                      size="sm"
                      name="owner_contact"
                      required
                      defaultValue={props.data.owner_contact}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="From"
                      type="text"
                      size="sm"
                      name="orgin"
                      required
                      defaultValue={props.data.orgin}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Destination"
                      type="text"
                      size="sm"
                      name="destination"
                      required
                      defaultValue={props.data.destination}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Transport Name"
                      type="text"
                      size="sm"
                      name="transport_name"
                      required
                      defaultValue={props.data.transport_name}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Driver Contact"
                      type="text"
                      size="sm"
                      name="driver_contact"
                      required
                      defaultValue={props.data.driver_contact}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="License No"
                      type="text"
                      size="sm"
                      name="license_no"
                      required
                      defaultValue={props.data.license_no}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Vehicle Owner Name"
                      type="text"
                      size="sm"
                      name="driver_name"
                      required
                      defaultValue={props.data.driver_name}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Vehicle Hire"
                      type="text"
                      size="sm"
                      name="vehicle_hire"
                      required
                      defaultValue={props.data.vehicle_hire}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Advance"
                      type="text"
                      size="sm"
                      name="advance"
                      required
                      defaultValue={props.data.advance}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Balance"
                      type="text"
                      size="sm"
                      name="balance"
                      required
                      defaultValue={props.data.balance}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <MDBInput
                      label="Unloaded By"
                      type="text"
                      size="sm"
                      name="unloaded_by"
                      required
                      defaultValue={props.data.unloaded_by}
                    />
                  </Col>
                  <Col md={12} className="mt-3">
                    <label>Comments</label>
                    <Form.Control
                      as="textarea"
                      size="sm"
                      name="comments"
                      placeholder="Comments"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      <i className="fas fa-edit"></i>{" "}
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default EditForm;
