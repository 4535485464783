import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { integerIndMobile, integerKeyPress, lettersOnly } from "../../utils";

const AddService = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/updateserviceinfo", $("#frm_update").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Updated Successfully..!", "success");
            document.getElementById("frm_update").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("frm_update").reset();
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  useEffect(() => {
    savelog();
  }, []);

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to Edit Enquiry Service Info ".props.data.id);
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        <div className="">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_update"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <Row className="mt-2">
                  <Col md={12}>
                    <MDBInput
                      label="Item"
                      type="text"
                      size="sm"
                      name="product_name"
                      id="product_name"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="sm"
                      variant="primary"
                      className=""
                      style={{ float: "right" }}
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddService;
