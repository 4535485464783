import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';
import {integerIndMobile, decimalKeyPress,lettersOnly,integerKeyPress} from '../../utils';

const EditForm = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');

	const handleSubmit = (event) => {
		
	event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('/v1/user/updatevehicle',$("#frm_editvehicle").serialize()).then(res=>{
			if (res['data'].status == '1') {
			 toast.success("Updated Successfully..!", "success");
			 document.getElementById("frm_editvehicle").reset();
			 setBtnLoading(false);
			 if (props.afterFinish) props.afterFinish();
		}
		else {
			 toast.error("Something Went Wrong..!", "danger");
			 document.getElementById("frm_editvehicle").reset();
			
		}
		setIsLoaded(false);
		});
		}
		catch(error){

		}
	}
		
	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				{/* Login page design..*/}
					<div className="container" >
						<Row className="mt-1" >
							<Col md={12}>
								
								<Form action="" method="post" id="frm_editvehicle" validated={validated} onSubmit={handleSubmit}>
									<input type="hidden" value={props.data.id} name="id" />
									<input type="hidden" value={context.adminUser.id} name="user" />
									<input type="hidden" value={context.adminCompany.id} name="company" />
										<Row className="mt-2">
											<Col md={2}>
												<label>Vehicle No <span className="text-danger">*</span></label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="regno"
													placeholder="Vehicle No"
													required
													defaultValue={props.data.regno}
												/>
											</Col>
											<Col md={2}>
												<label>Make(Brand) <span className="text-danger">*</span></label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="brand"
													placeholder="Brand"
													required
													defaultValue={props.data.brand}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Model</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="model"
													placeholder="Model"
													defaultValue={props.data.model}
												/>
											</Col>
											<Col md={2}>
												<label>Model Year</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="modelyear"
													placeholder="Model Year"
													defaultValue={props.data.modelyear}
													onKeyPress={integerKeyPress}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Kilo Meter</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="km"
													placeholder="Kilo Meter"
													defaultValue={props.data.km}
													onKeyPress={integerKeyPress}
												/>
											</Col>
											<Col md={2}>
												<label>Color</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="color"
													placeholder="Color"
													defaultValue={props.data.color}
													onKeyPress={lettersOnly}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Type</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="type"
													placeholder="Type"
													defaultValue={props.data.type}
												/>
											</Col>
											<Col md={2}>
												<label>Driver</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="driver"
													placeholder="Driver"
													defaultValue={props.data.driver}
													onKeyPress={lettersOnly}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Engine No</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="engineno"
													placeholder="Engine No"
													defaultValue={props.data.engineno}
												/>
											</Col>
											<Col md={2}>
												<label>Tyre Size</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="tyresize"
													placeholder="Tyre Size"
													defaultValue={props.data.tyresize}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Insurance No</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="insuranceno"
													placeholder="Insurance No"
													defaultValue={props.data.insuranceno}
												/>
											</Col>
											<Col md={2}>
												<label>Insurance Company</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="insurancecompany"
													placeholder="Insurance Company"
													defaultValue={props.data.insurancecompany}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Insurance Amount</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="insuranceamount"
													placeholder="Insurance Amount"
													defaultValue={props.data.insuranceamount}
												/>
											</Col>
											<Col md={2}>
												<label>Insurance Renewal On</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="date"
													size="sm" 
													name="insurancerenewal"
													placeholder="Insurance Renewal On"
													defaultValue={props.data.insurancerenewal}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>FC No</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="text"
													size="sm" 
													name="fcno"
													placeholder="FC No"
													defaultValue={props.data.fcno}
												/>
											</Col>
											<Col md={2}>
												<label>FC Renewal Date</label>
											</Col>
											<Col md={4}>
												<Form.Control
													type="date"
													size="sm" 
													name="fcrenewalon"
													placeholder="FC Renewal Date"
													defaultValue={props.data.fcrenewalon}
												/>
											</Col>
										</Row>
										<Row className="mt-2">
											<Col md={2}>
												<label>Remarks</label>
											</Col>
											<Col md={10}>
												<Form.Control
													as="textarea"
													size="sm" 
													name="remarks"
													placeholder="Remarks"
													defaultValue={props.data.remarks}
												/>
											</Col>
										</Row>
										<Row className="mt-4" >
											<Col md={12} >
												<Button
													type="submit"
													size="md"
													variant="primary"
													className="btn-block"
													disabled={btnLoading}
												>
												{btnLoading ? 'Please wait..' : 'Update'}
												</Button>
											</Col>
										</Row>
								</Form>
								
							</Col>
						</Row>
					</div>
				{/* Login page design..*/}
			</div>
			
		</>
	);
};

export default EditForm;