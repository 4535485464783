import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerIndMobile, decimalKeyPress, lettersOnly } from "../../../utils";

const UpdateText = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/savetextgc", $("#frm").serialize()).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Saved Successfully..!", "success");
          document.getElementById("frm").reset();
          setBtnLoading(false);
          if (props.afterFinish) props.afterFinish();
          if (props.afterList) props.afterList();
        } else {
          toast.error("Something Went Wrong..!", "danger");
          document.getElementById("frm").reset();
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div className="container">
        <Row className="mt-1">
          <Col md={12}>
            <Form
              action=""
              method="post"
              id="frm"
              validated={validated}
              onSubmit={handleSubmit}
            >
              <input
                type="hidden"
                value={props.data.enquiry_id}
                name="enquiry_id"
              />
              <Row>
                <Col md={12} className="mt-4">
                  <MDBInput
                    label="Said To Contain"
                    type="text"
                    name="saidtocontain"
                    id="saidtocontain"
                    size="md"
                    onKeyPress={lettersOnly}
                  />
                </Col>
              </Row>
              {' '}
              {/* <div style={{marginTop: '20px' }}>
                <label>No of Packages</label>
              </div> */}
              <Row>
                <Col md={12} className="mt-4">
                  <MDBInput
                  label="No Of Packages"
                  type="text"
                  name="numberofpackages"
                  id="numberofpackages"

                  />
                </Col>
              </Row>

              <Row>
                <Col md={6} className="mt-4">
                  <MDBInput
                    label="Actual Wt (Kgs)"
                    type="text"
                    name="actual_wt"
                    id="actual_wt"
                    size="md"
                    // onKeyPress={decimalKeyPress}
                  />
                </Col>
                <Col md={6} className="mt-4">
                  <MDBInput
                    label="Charged Wt (Kgs)"
                    type="text"
                    name="charged_wt"
                    id="charged_wt"
                    size="md"
                    // onKeyPress={decimalKeyPress}
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mt-4">
                  <MDBInput
                    label="Declared Value"
                    type="text"
                    name="declaredvalue"
                    size="md"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mt-4">
                  <MDBInput label="Cash" type="text" name="cash" size="md" />
                </Col>
              </Row>

              <Row>
                <Col md={12} className="mt-4">
                  <MDBInput label="GST No" type="text" name="gstno" size="md" />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={12}>
                  <Button
                    type="submit"
                    size="md"
                    variant="success"
                    className="btn-block"
                    disabled={btnLoading}
                  >
                    <i className="fas fa-check"></i>{" "}
                    {btnLoading ? "Please wait.." : "Save"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
};
export default UpdateText;
