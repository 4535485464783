import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../../context';
import API from '../../../utils/api';

const ViewReceipt = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');
    const [printData, setPrintData] = useState([]);
	const [print, setPrint] = useState(false);
	


    const handlePrint = (dt) => {
		setPrintData(dt);
		setPrint(true);
		setTimeout(
			() => printDocument(),
			1000
		);
	};

    const printDocument = () => {

		var content = document.getElementById("newPrintReceipt");
		var pri = document.getElementById("printReceipt").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}


	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				
            <Card id="newPrintReceipt">
            <Card.Header style={{textAlign: 'center'}}>
                <h4>Leader Packers And Movers</h4>
                    <p>6,TTK Nagar, 2nd Main Road Irumbuliyur, West Tambaram,Chennai - 45.<br />
                    <b>E-Mail :</b> leaderpackersindia@gmail.com{' '}
                    <b>Website : </b>www.leaderpackers.com{' '}
                    <b>Cell :</b> 9944029699
                    </p>
            </Card.Header>
            <div style={{textAlign: 'center'}}>
                <h5 className="mt-2">RECEIPT</h5>
				<Card.Body>
                    <Row>
                        <Col md={12}>
                            <Row className="mt-2">
                                <Col md={4}>
                                    <label>Received with thanks from M/s. </label>
                                </Col>
                                <Col md={8} >
                                    <Form.Control
                                        type="text" 
                                        size="sm" 
                                        name="name"
                                        className="text-uppercase"
                                        defaultValue={props.data.name}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className="mt-2">
                                <Col md={4}>
                                    <label>the sum of rupees</label>
                                </Col>
                                 <Col md={8} >
                                    <Form.Control
                                        type="text" 
                                        size="sm" 
                                        name="name"
                                        className="text-uppercase"
                                        defaultValue={props.data.amount}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                         <Col md={12}>
                            <Row className="mt-2">
                                <Col md={4}>
                                    <label>by <input type="checkbox" />{' '}Cash/{' '}<input type="checkbox" />{' '}Cheque/{' '}<input type="checkbox" />{' '}DD.No.</label>
                                </Col>
                                 <Col md={8} >
                                    <Form.Control
                                        type="text" 
                                        size="sm" 
                                        name="name"
                                        className="text-uppercase"
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className="mt-2">
                                <Col md={4}>
                                    <label>towards</label>
                                </Col>
                                 <Col md={8} >
                                    <Form.Control
                                        type="text" 
                                        size="sm" 
                                        name="name"
                                        className="text-uppercase"
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={3}>
                            <Row>
                                <Col md={2}>
                                    <label>Rs.</label>
                                </Col>
                                <Col md={10}>
                                     <Form.Control
                                        type="text" 
                                        size="sm" 
                                        name="name"
                                        className="text-uppercase"
                                        defaultValue={props.data.amount}
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={4}>
                        </Col>
                        <Col md={5}>
                            <label>For Leaders Packers And Movers</label>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                    <Col md={9}>
                    </Col>
                    <Col md={3}>
                        <label>Authorized Signatory</label>
                    </Col>
                    </Row>
				</Card.Body>
            </div>
            <iframe name="printReceipt" id="printReceipt" width="0" height="0" frameborder="0" src="about:blank"></iframe>
			</Card>
			</div>
			
		</>
	);
};
export default ViewReceipt;