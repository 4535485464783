export default {
  top: [
    {
      name: "Dashboard",
      path: "/home",
      exact: true,
      allowed: ["admin_staff"],
    },
    {
      name: "Dashboard",
      path: "/home/admin",
      exact: true,
      allowed: ["admin"],
    },
    /* {name: 'Profiles', path:'/profiles', childrens: [
			{name: 'List Profiles', path: '/profiles/list'},
			{name: 'Add Profile', path: '/profiles/add'},
			{name: 'Quick Add Profile', path: '/profiles/quick-add'},
			{divider: true},
			{name: 'Profile Delete Request', path: '/profiles/delete-request'}
		]}, */

    {
      name: "Branches",
      path: "/branches",
      allowed: ["admin"],
    },
    /* { name: "Media", path: "/media", allowed: ["admin", "admin_staff"] },
    {
      name: "Vehicle",
      path: "/vehicle",
      allowed: ["admin", "admin_staff"],
    },
    {
      name: "Settings",
      path: "/settings/update",
      allowed: ["admin", "admin_staff"],
    }, */
    {
      name: "Quick Enquiry",
      path: "/quickenquiry",
      allowed: ["admin"],
    },
    {
      name: "Enquiry",
      path: "/enquiries",
      allowed: ["admin_staff"],
      /*childrens: [
        {
          name: "Enquiries",
          path: "/enquiries",
          allowed: ["admin"],
        },
       
      ], */
    },
    {
      name: "Enquiry",
      path: "/enquiry/admin",
      allowed: ["admin"],
      /*childrens: [
        {
          name: "Enquiries",
          path: "/enquiries",
          allowed: ["admin"],
        },
       
      ], */
    },
    {
      name: "New Enquiry",
      path: "/new-enquiry",
      allowed: ["admin", "admin_staff"],
    },

    {
      name: "Jobs",
      path: "/jobs",
      allowed: ["admin", "admin_staff"],
      childrens: [
        //{name: 'Confirm', path: '/confirm'},
        //{name: 'Booked', path: '/booked'},
        //{name: 'Rejected', path: '/rejected'},
      ],
      allowed: ["admin", "admin_staff"],
    },

    {
      /* name: "Master",
      path: "/master",
      childrens: [
        { name: "Name", path: "/namemaster" },
        //{name: 'Media', path: '/mediamaster'},
        { name: "SMS Template", path: "/smstemplatemaster" },
      ],
      allowed: ["admin"],
    */
    },

    {
      name: "Despatch",
      path: "/viewdespatch",
      exact: true,
      allowed: ["admin", "admin_staff"],
      /*childrens: [
        { allowed: ["admin"] },
        {
          name: "Add",
          path: "/adddespatch",
          allowed: ["admin", "admin_staff"],
        },
        {
          name: "View",
          path: "/viewdespatch",
          allowed: ["admin", "admin_staff"],
        },
      ], 
      allowed: ["admin"], */
    },

    /*{name: 'Expens', path:'/expens', childrens: [
			{name: 'Add', path: '/addexpens'},
			{name: 'View', path: '/viewexpens'},
		]},
		
		{name: 'Delivery', path:'/delivery', exact: true }, */
  ],
  bottom: [],
};
