import React, { useState, useContext, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import { MDBInput } from "mdb-react-ui-kit";
import $ from "jquery";
import { PsBredCrumb } from "../../comp";
//import MyDocument from './MyDocument';

const Confirm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [data, changeData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [bookedData, changeBookedData] = useState([]);
  const [enquries, changeEnquries] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      API.get("v1/job/followuplist/admin").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title=""></PsBredCrumb>
      <Col md={12}>
        <Card>
          <Card.Header style={{ backgroundColor: "tan" }}>
            Today Follow Ups
          </Card.Header>

          <Card.Body>
            <Table className="table table-striped">
              <thead>
                <tr>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    Name
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    Mobile
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    From
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    To
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    Date
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    Load Details
                  </th>
                  <th
                    style={{ textAlign: "center" }}
                    className="font-weight-bold"
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, id) => {
                  return (
                    <tr>
                      <td style={{ textAlign: "center" }}>{item.name}</td>
                      <td style={{ textAlign: "center" }}>{item.mobile}</td>
                      <td style={{ textAlign: "center" }}>{item.city}</td>
                      <td style={{ textAlign: "center" }}>{item.city_ds}</td>
                      <td style={{ textAlign: "center" }}>
                        {item.update_date}
                      </td>
                      <td style={{ textAlign: "center" }}>{item.basis}</td>
                      <td style={{ textAlign: "center" }}>{item.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};
export default Confirm;
