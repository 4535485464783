import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Form, Modal, Table, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import API from "../../utils/api";
import PsContext from "../../context";
import { MDBInput } from "mdb-react-ui-kit";
import { PsBredCrumb } from "../../comp";
import $ from "jquery";

const Delivery = () => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setDataList] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("v1/user/delivery", $("#addform").serialize()).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Saved Successfully..!", "success");
          document.getElementById("addform").reset();
          setBtnLoading(false);
        } else {
          //toast.error("Something Went Wrong..!", "danger");
          document.getElementById("addform").reset();
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title="Delivery Details"></PsBredCrumb>
      <Form
        action=""
        method="post"
        id="addform"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <Row>
                  <Col md={12}>
                    <MDBInput
                      className="mt-2"
                      label="Company Name"
                      type="text"
                      name="company_name"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Floor"
                      type="text"
                      name="floor"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Payment Charges"
                      type="text"
                      name="payment_charges"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Expense"
                      type="text"
                      name="expense"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={12}>
                    <MDBInput
                      className="mt-2"
                      label="Vehicle No"
                      type="text"
                      name="vehicle_no"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Labour"
                      type="text"
                      name="labour"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Payment Received"
                      type="text"
                      name="payment_received"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Balance"
                      type="text"
                      name="balance"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={4}>
                <Row>
                  <Col md={12}>
                    <MDBInput
                      className="mt-2"
                      label="Driver Mobile"
                      type="text"
                      name="driver_mobile"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="No.Of.Labours"
                      type="text"
                      name="no_of_labour"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Wages"
                      type="text"
                      name="wages"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col md={12}>
                    <MDBInput
                      label="Extra"
                      type="text"
                      name="extra"
                      size="lg"
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  disabled={btnLoading}
                >
                  {btnLoading ? "Please wait.." : "Save"}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
export default Delivery;
