import Home from "../pages/home";
import DashboardAdmin from "../pages/dashboardAdmin";
import UpdateCompany from "../pages/company/updateCompany";
import Enquiry from "../pages/enquiry";
import EnquiryAdmin from "../pages/enquiry/enquiryAdmin";
import Quotation from "../pages/enquiry/quotation";
import NewEnquiry from "../pages/enquiry/NewEnquiry";
import Delivery from "../pages/delivery";
import Confirm from "../pages/jobs/confirm";
import ReceiptView from "../pages/jobs/confirm/ReceiptView";
//import Booked from '../pages/jobs/book/booked';
//import Rejected from '../pages/jobs/reject/rejected';
import Survey from "../pages/survey";
import Branches from "../pages/branch";
import Master from "../pages/master";
//import Master_Media  from '../pages/master/media';
import Master_Sms from "../pages/master/sms";
import Media from "../pages/media";
import Vehicle from "../pages/vehicle";
import Despatch from "../pages/despatch";
import Expens from "../pages/expens";
//import Header from '../pages/layout/header';
import AddDespatch from "../pages/despatch/AddDespatch";
import QuickEnquiry from "../pages/quickEnquiry";

const pages = [
  {
    name: "Dashboard",
    path: "/home",
    component: Home,
    exact: true,
    allowed: ["admin_staff"],
  },
  {
    name: "DashboardAdmin",
    path: "/home/admin",
    component: DashboardAdmin,
    exact: true,
    allowed: ["admin"],
  },
  {
    name: "Enquiry",
    path: "/enquiries",
    component: Enquiry,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Quotation",
    path: "/quotation",
    component: Quotation,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "New Enquiry",
    path: "/new-enquiry",
    component: NewEnquiry,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Delivery",
    path: "/delivery",
    component: Delivery,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Jobs",
    path: "/jobs",
    component: Confirm,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "ReceiptView",
    path: "/receiptview",
    component: ReceiptView,
    allowed: ["admin", "admin_staff"],
  },
  //{name: 'Booked', path:'/booked', component: Booked },
  //{name: 'Rejected', path:'/rejected', component: Rejected },
  { name: "Survey", path: "/survey", component: Survey, allowed: ["admin"] },
  {
    name: "Update Company",
    path: "/settings/update",
    component: UpdateCompany,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Branches",
    path: "/branches",
    component: Branches,
    allowed: ["admin"],
  },
  {
    name: "Master",
    path: "/namemaster",
    component: Master,
    allowed: ["admin"],
  },
  //{name: 'Master_Media', path:'/mediamaster', component: Master_Media } ,
  {
    name: "Master_Sms",
    path: "/smstemplatemaster",
    component: Master_Sms,
    allowed: ["admin"],
  },
  {
    name: "Despatch",
    path: "/viewdespatch",
    component: Despatch,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Expens",
    path: "/viewexpens",
    component: Expens,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Media",
    path: "/media",
    component: Media,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "Vehicle",
    path: "/vehicle",
    component: Vehicle,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "AddDespatch",
    path: "/adddespatch",
    component: AddDespatch,
    allowed: ["admin", "admin_staff"],
  },
  {
    name: "QuickEnquiry",
    path: "/quickenquiry",
    component: QuickEnquiry,
    allowed: ["admin"],
  },

  {
    name: "EnquiryAdmin",
    path: "/enquiry/admin",
    component: EnquiryAdmin,
    allowed: ["admin"],
  },

  //{name: 'Header', path:'/logout', component: Header } ,
];

export default pages;
