import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Form, Modal, Table, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import AddForm from "./addForm";
import EditForm from "././editForm";
import API from "../../utils/api";
import PsContext from "../../context";
import { PsBredCrumb } from "../../comp";
import $ from "jquery";
import { Link } from "react-router-dom";

const Branch = () => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [data, changeData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [viewId, setViewId] = useState("");

  const btnClick = () => {
    toast("Welcome");
  };

  const editdash = (item) => {
    setEditData(item);
    setEditUser(true);
  };

  const onSearch = (e) => {
    try {
      var d = branchData;
      const s = e.target.value.toUpperCase();
      let ndata = d.filter(function (item) {
        const name = item.branch_name.toUpperCase();
        const uname = item.username.toUpperCase();
        return name.indexOf(s) > -1 || uname.indexOf(s) > -1;
      });
      if (s == "") {
        changeData(d);
      } else {
        changeData(ndata);
      }
    } catch (error) {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    listdata();
    savelog();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listbranch").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setBranchData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Branches");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleDeleteClick = (item, id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("id", id);
      API.post("v1/user/deletebranch", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          var d = data;
          var index = d.findIndex(function (obj) {
            return obj.id == id;
          });
          if (index > -1) d.splice(index, 1);
          setBranchData(d);
          changeData(d);
          setIsLoaded(false);
          listdata();
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const onPassView = (psw, id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("psw", psw);
      form.append("id", id);
      API.post("v1/user/getpassword", form).then((res) => {
        if (res["data"].status == "1") {
          //toast.success("Delete Successfully","success");
          setViewId(id);
          var sid = "#" + id;
          $(sid).html(res["data"].data);
          setIsLoaded(false);
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const onPassHide = (id) => {
    try {
      setViewId("");
      var sid = "#" + id;
      $(sid).html(".....");
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Link to="/media">
        <Button size="sm">
          <i className="fas fa-fighter-jet"></i> Media
        </Button>
      </Link>{" "}
      <Link to="/vehicle">
        <Button size="sm">
          <i className="fas fa-bus"></i> Vehicle
        </Button>
      </Link>
      <Card className="no-shadow" className="mt-3">
        <PsBredCrumb title="Branches">
          <ul className="list-inline">
            <li className="list-inline-item">
              <div className="input-group">
                <div className="input-group-text bg-white">
                  <i className="fa fa-search"></i>
                </div>
                <Form.Control
                  type="text"
                  size="md"
                  placeholder="Search.."
                  style={{ width: "200px" }}
                  onChange={(e) => {
                    onSearch(e);
                  }}
                />
              </div>
            </li>
            <li className="list-inline-item">
              <button
                type="submit"
                className="btn btn-primary btn-md"
                onClick={() => setAdminCompany(true)}
              >
                <i className="fa fa-plus"></i> New
              </button>
            </li>
          </ul>
        </PsBredCrumb>
        <Card.Body>
          <Row>
            <Col md={12}>
              <Modal
                show={adminCompany}
                onHide={() => setAdminCompany(false)}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <AddForm
                    afterFinish={() => {
                      setAdminCompany(false);
                      listdata();
                    }}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              <Modal
                show={editUser}
                onHide={() => setEditUser(false)}
                backdrop="static"
              >
                <Modal.Header closeButton>
                  <Modal.Title>Edit Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <EditForm
                    data={editData}
                    afterFinish={() => {
                      setEditUser(false);
                      listdata();
                    }}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>

              <Table bordered className="table table-striped">
                <thead>
                  <tr>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      S.No
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Branch
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Userame
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Password
                    </th>
                    <th
                      style={{ textAlign: "center" }}
                      className="font-weight-bold"
                    >
                      Manage
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((item, id) => {
                    return (
                      <tr>
                        <td style={{ textAlign: "center" }}>{id + 1}</td>
                        <td style={{ textAlign: "center" }}>
                          {item.branch_name}
                        </td>
                        <td style={{ textAlign: "center" }}>{item.username}</td>
                        <td style={{ textAlign: "center" }}>
                          <b id={item.id}>.....</b>&emsp;&emsp;
                          {viewId == item.id ? (
                            <Button
                              variant="warning"
                              size="sm"
                              k={() => onPassHide(item.id)}
                            >
                              <i className="fa fa-eye-slash"></i>
                            </Button>
                          ) : (
                            <Button
                              variant="warning"
                              size="sm"
                              onClick={() => onPassView(item.password, item.id)}
                            >
                              <i className="fa fa-eye"></i>
                            </Button>
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Button
                            variant="success"
                            size="sm"
                            onClick={() => editdash(item)}
                          >
                            <i className="fas fa-edit"></i>{" "}
                          </Button>
                          &nbsp;
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleDeleteClick(item, item.id)}
                          >
                            <i className="fas fa-trash"></i>{" "}
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
export default Branch;
