import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { integerIndMobile, decimalKeyPress, lettersOnly } from "../../utils";

const AddForm = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/savebranch", $("#frm_branch").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            toast.success("Saved Successfully..!", "success");
            document.getElementById("frm_branch").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish();
            if (props.afterList) props.afterList();
          } else {
            toast.error("Something Went Wrong..!", "danger");
            document.getElementById("frm_branch").reset();
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />

      <div>
        {/* Login page design..*/}
        <div className="container">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_branch"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input
                  type="hidden"
                  value={context.adminCompany.id}
                  name="company"
                />
                <input type="hidden" value={context.adminUser.id} name="user" />
                <Row>
                  <Col md={12} className="mt-4">
                    <MDBInput
                      label="Branch Name"
                      type="text"
                      name="name"
                      required
                      size="lg"
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mt-4">
                    <MDBInput
                      label="Username"
                      type="text"
                      name="username"
                      required
                      size="lg"
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12} className="mt-4">
                    <MDBInput
                      label="Password"
                      type="password"
                      name="password"
                      required
                      size="lg"
                    />
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Save"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
        {/* Login page design..*/}
      </div>
    </>
  );
};
export default AddForm;
