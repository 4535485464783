export const appName = "Packers And Movers";

export const baseUrl = 'https://appleaderpackers.resellerindia.in/api/';
//export const baseUrl = "http://localhost/mariappan/developing/leaders_and_packers/api/";
//export const photoUrl ="http://localhost/office/others/packersandmovers/public/logo.jpeg";
export const photoUrl = 'https://appleaderpackers.resellerindia.in/api/public/logo.jpeg';

/**
 * setLs
 * to store the user inputs into localStorage
 **/
export function setLs(key, value) {
  localStorage.setItem(key, value);
}

/**
 * getLs
 * to get the stored values from localStorage
 **/
export function getLs(key) {
  return localStorage.getItem(key) || false;
}

/**
 * integerKeyPress
 * to handle textbox key press event and check whether the input is integer or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerKeyPress(e) {
  if (e.which != 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * decimalKeyPress
 * to handle textbox key press event and check whether the input is decimal or not
 *
 * @param EVENT
 * @return NULL
 */
export function decimalKeyPress(e) {
  if (e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Decimal numbers only");
    e.preventDefault();
    return false;
  }
  if (e.which == 46 && e.target.value.indexOf(".") != -1) {
    // showAdvice(this, "Only one period allowed in decimal numbers");
    e.preventDefault();
    return false; // only one decimal allowed
  }
}

/**
 * lettersOnly
 * to handle textbox key press event and check whether the input is alphabet or not
 *
 * @param EVENT
 * @return NULL
 */
export function lettersOnly(e) {
  const inputValue = e.which;
  // allow letters and whitespaces only.
  if (
    e.which != 8 &&
    !(inputValue >= 65 && inputValue <= 122) &&
    inputValue != 32 &&
    inputValue != 0
  ) {
    e.preventDefault();
    return false;
  }
}

export function integerIndPincode(e) {
  const len = e.target.value.length;
  if (len >= 6) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * integerIndMobile
 * to handle textbox key press event and check whether the input is integer and less than 10 characters or not
 *
 * @param EVENT
 * @return NULL
 */
export function integerIndMobile(e) {
  const len = e.target.value.length;
  if (len >= 10) {
    e.preventDefault();
    return false;
  }

  if (e.which !== 8 && (e.which < 48 || e.which > 57)) {
    // showAdvice(this, "Integer values only");
    e.preventDefault();
    return false;
  }
}

/**
 * numberToWords
 * to convert number to words
 *
 * @param1 number (int)
 * @return string
 */
export function numberToWords(amount) {
  var words = new Array();
  words[0] = "";
  words[1] = "One";
  words[2] = "Two";
  words[3] = "Three";
  words[4] = "Four";
  words[5] = "Five";
  words[6] = "Six";
  words[7] = "Seven";
  words[8] = "Eight";
  words[9] = "Nine";
  words[10] = "Ten";
  words[11] = "Eleven";
  words[12] = "Twelve";
  words[13] = "Thirteen";
  words[14] = "Fourteen";
  words[15] = "Fifteen";
  words[16] = "Sixteen";
  words[17] = "Seventeen";
  words[18] = "Eighteen";
  words[19] = "Nineteen";
  words[20] = "Twenty";
  words[30] = "Thirty";
  words[40] = "Forty";
  words[50] = "Fifty";
  words[60] = "Sixty";
  words[70] = "Seventy";
  words[80] = "Eighty";
  words[90] = "Ninety";
  amount = amount.toString();
  var atemp = amount.split(".");
  var number = atemp[0].split(",").join("");
  var n_length = number.length;
  var words_string = "";
  if (n_length <= 9) {
    var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
    var received_n_array = new Array();
    for (var i = 0; i < n_length; i++) {
      received_n_array[i] = number.substr(i, 1);
    }
    for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
      n_array[i] = received_n_array[j];
    }
    for (var i = 0, j = 1; i < 9; i++, j++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        if (n_array[i] == 1) {
          n_array[j] = 10 + parseInt(n_array[j]);
          n_array[i] = 0;
        }
      }
    }
    var value = "";
    for (var i = 0; i < 9; i++) {
      if (i == 0 || i == 2 || i == 4 || i == 7) {
        value = n_array[i] * 10;
      } else {
        value = n_array[i];
      }
      if (value != 0) {
        words_string += words[value] + " ";
      }
      if (
        (i == 1 && value != 0) ||
        (i == 0 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Crores ";
      }
      if (
        (i == 3 && value != 0) ||
        (i == 2 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Lakhs ";
      }
      if (
        (i == 5 && value != 0) ||
        (i == 4 && value != 0 && n_array[i + 1] == 0)
      ) {
        words_string += "Thousand ";
      }
      if (i == 6 && value != 0 && n_array[i + 1] != 0 && n_array[i + 2] != 0) {
        words_string += "Hundred and ";
      } else if (i == 6 && value != 0) {
        words_string += "Hundred ";
      }
    }
    words_string = words_string.split("  ").join(" ");
  }
  return words_string;
}
