import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Form } from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import { PSListItem } from "../../comp";
import { MDBInput } from "mdb-react-ui-kit";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import API from "../../utils/api";
import { Table } from "antd";
import EditForm from "./editForm";
import { PsBredCrumb } from "../../comp";
import $ from "jquery";
import { jsPDF } from "jspdf";
import { Link } from "react-router-dom";

const Despatch = (props) => {
  const context = useContext(PsContext);
  const [masterData, setMasterData] = useState([]);
  const [adminCompany, setAdminCompany] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [editData, setEditData] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editService, setEditService] = useState(false);
  const [data, changeData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [print, setPdfPrint] = useState(false);
  const [paginationPageSize, setpaginationPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  const doc = new jsPDF("l", "pt", "a3");

  useEffect(() => {
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/user/listdespatch", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          //toast.success(res['data'].message, "success");
          changeData(d);
          setMasterData(d);
          setIsLoaded(false);
        } else {
          alert(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const printPdf = () => {
    setPdfPrint(true);
    const pdfdoc = document.getElementById("pdf");
    doc.html(pdfdoc, {
      margins: [40, 60, 40, 60],
      autoPaging: true,
      callback: function (doc) {
        doc.save(date);
      },
      x: 30,
      y: 30,
    });
  };

  const onSearch = (e) => {
    try {
      var d = masterData;
      const s = e.target.value.toUpperCase();
      let ndata = d.filter(function (item) {
        const name = item.owner_name.toUpperCase();
        const uname = item.driver_name.toUpperCase();
        const owno = item.owner_contact.toUpperCase();
        const drno = item.driver_contact.toUpperCase();
        const lno = item.license_no.toUpperCase();
        const tname = item.transport_name.toUpperCase();
        const vtype = item.vehicle_type.toUpperCase();
        const vhire = item.vehicle_hire.toUpperCase();
        const bal = item.balance.toUpperCase();
        const uat = item.unloaded_at.toUpperCase();
        const uby = item.unloaded_by.toUpperCase();
        const orgin = item.orgin.toUpperCase();
        const des = item.destination.toUpperCase();
        const adv = item.advance.toUpperCase();
        return (
          name.indexOf(s) > -1 ||
          uname.indexOf(s) > -1 ||
          owno.indexOf(s) > -1 ||
          drno.indexOf(s) > -1 ||
          lno.indexOf(s) > -1 ||
          tname.indexOf(s) > -1 ||
          vtype.indexOf(s) > -1 ||
          vhire.indexOf(s) > -1 ||
          bal.indexOf(s) > -1 ||
          uat.indexOf(s) > -1 ||
          uby.indexOf(s) > -1 ||
          uby.indexOf(s) > -1 ||
          orgin.indexOf(s) > -1 ||
          des.indexOf(s) > -1 ||
          adv.indexOf(s) > -1
        );
      });
      if (s == "") {
        changeData(d);
      } else {
        changeData(ndata);
      }
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleDeleteClick = (item, id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("id", id);
      API.post("v1/user/delete/despatch", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          var d = data;
          var index = d.findIndex(function (obj) {
            return obj.id == id;
          });
          if (index > -1) d.splice(index, 1);
          changeData(d);
          setMasterData(d);
          setIsLoaded(false);
          listdata();
        } else {
          toast.error(res["data"].message, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handlePaginationSizeChange = (current, pageSize) => {
    console.log("current" + current);
    setpaginationPageSize(pageSize);
  };

  const handleTablePageChange = (current) => {
    setCurrentPage(current);
  };

  const editdash = (item) => {
    setEditData(item);
    setEditUser(true);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, item, index) => (
        <>{(currentPage - 1) * paginationPageSize + (index + 1)}</>
      ),
    },
    {
      title: "Despatch Date",
      dataIndex: "despatch_date",
      key: "despatch_date",
      align: "center",
    },
    {
      title: "Customer Name",
      dataIndex: "owner_name",
      key: "owner_name",
      align: "center",
    },
    {
      title: "Customer Contact",
      dataIndex: "owner_contact",
      key: "owner_contact",
      align: "center",
    },
    {
      title: "From",
      dataIndex: "orgin",
      key: "orgin",
      align: "center",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "destination",
      align: "center",
    },
    {
      title: "Transport Name",
      dataIndex: "transport_name",
      key: "transport_name",
      align: "center",
    },
    {
      title: "Driver No",
      dataIndex: "driver_contact",
      key: "driver_contact",
      align: "center",
    },
    {
      title: "License No",
      dataIndex: "license_no",
      key: "license_no",
      align: "center",
    },
    {
      title: "Vehicle Owner Name",
      dataIndex: "driver_name",
      key: "driver_name",
      align: "center",
    },
    {
      title: "Vehicle Hire",
      dataIndex: "vehicle_hire",
      key: "vehicle_hire",
      align: "center",
    },
    {
      title: "Advance",
      dataIndex: "advance",
      key: "advance",
      align: "center",
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance",
      align: "center",
    },
    {
      title: "Unloaded By",
      dataIndex: "unloaded_by",
      key: "unloaded_by",
      align: "center",
    },
    {
      title: "Comments",
      dataIndex: "comments",
      key: "comments",
      align: "center",
      width: "220px",
    },
    {
      title: "Actions",
      dataIndex: "",
      key: "",
      align: "center",
      render: (text, item) => (
        <>
          <Button variant="success" size="sm" onClick={() => editdash(item)}>
            <i className="fas fa-edit"></i>
          </Button>
          &nbsp;
          {/*<Button variant="danger" size="sm" onClick={() => handleDeleteClick(item, item.id)}><i className="fas fa-trash"></i></Button> */}
        </>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col md={9}></Col>
        <Col md={3}>
          <Link to="/adddespatch">
            <Button style={{ float: "right" }} variant="success">
              <i className="fas fa-plus"></i> ADD Despatch
            </Button>
          </Link>
        </Col>
      </Row>
      <div className="mt-3"></div>
      <Card className="no-shadow">
        <PsBredCrumb title="Despatch">
          <ul className="list-inline">
            <li className="list-inline-item">
              <div className="input-group">
                <div className="input-group-text bg-white">
                  <i className="fa fa-search"></i>
                </div>
                <Form.Control
                  type="text"
                  size="md"
                  placeholder="Search.."
                  style={{ width: "400px" }}
                  onChange={(e) => {
                    onSearch(e);
                  }}
                />
              </div>
            </li>
            {/*<li className="list-inline-item" >
				<Button onClick={()=> setAddModal(true) }><i class="fa fa-plus"></i> Add</Button>
			</li> */}
          </ul>
        </PsBredCrumb>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col md={4}>
                {/*<ReactHTMLTableToExcel
					id="test-table-xls-button"
					className="download-table-xls-button btn-right btn btn-success btn-sm"
					table="table-to-xls"
					filename="listdespatch"
					sheet="listdespatch"
					buttonText={<i className="fas fa-file-excel"> Excel</i>} />&nbsp; */}
                <Button size="sm" variant="danger" onClick={printPdf}>
                  <i className="fas fa-file-pdf"> PDF</i>{" "}
                </Button>{" "}
              </Col>
            </Row>
          </Card.Title>
          <Row>
            <Col md={12}>
              <div className="mt-10 ps-table">
                <Table
                  size="large"
                  columns={columns}
                  bordered
                  id="pdf"
                  footer={() =>
                    `Total ${Object.keys(data).length} Despatch  in list`
                  }
                  dataSource={data}
                  pagination={{
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "50", "100"],
                    pageSize: paginationPageSize,
                    position: ["bottomRight"],
                    onChange: handleTablePageChange,
                    onShowSizeChange: handlePaginationSizeChange,
                  }}
                />
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card style={{ display: "none", width: "auto" }}>
        <Table bordered id="table-to-xls">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Mobile
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                From
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                To
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Load Details
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Amount
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Confirm Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, id) => {
              return (
                <tr>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.mobile}</td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city}>
                      {item.city}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city_ds}>
                      {item.city_ds}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>{item.media}</td>
                  <td style={{ textAlign: "center" }}>{item.basis}</td>
                  <td style={{ textAlign: "center" }}>{item.amount}</td>
                  <td style={{ width: "250px" }}>{item.update_date}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>

      <Modal
        show={editUser}
        onHide={() => setEditUser(false)}
        backdrop="static"
        //size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Despatch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditForm
            data={editData}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Despatch;
