import React, { useState, useContext } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";

import PsContext from "../../context";
import API from "../../utils/api";

const LoginPage = () => {
  const context = useContext(PsContext);
  const history = useHistory();

  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (!form.checkValidity() === true) {
      event.stopPropagation();
      setValidated(true);
      return;
    }
    setBtnLoading(true);
    //API.post("v1/user/login", $("#frm_Login").serialize()).then((res) => {
    API.post("v2/login", $("#frm_Login").serialize()).then((res) => {
      if (res["data"].errorcode != 200) {
        toast.error(res["data"].content || res["data"].message);
        setBtnLoading(false);
        console.log(context.adminUser.role);
        return context.adminUser.role;
      } else {
		  //toast.error("Something Went Wrong");
        context.saveAdminLogin(
          res["data"].data.user,
          res["data"].data.company,
          res["data"].token
        );
        history.push("/");
      }
    });
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <div className="mt-5">
        <h5 id="app_name">Packers And Movers</h5>
        <div className="container">
          {/* Login page design..*/}
          <div style={{ float: "center" }}>
            <Row className="mt-5">
              <Col md={{ span: 4, offset: 4 }}>
                <Card>
                  <Card.Body>
                    <h5 style={{ color: "blue" }}>Login Page</h5>
                    <hr />
                    <Form
                      action=""
                      method="post"
                      id="frm_Login"
                      noValidate
                      validated={validated}
                      onSubmit={handleSubmit}
                    >
                      <Row>
                        <Col md={12}>
                          <MDBInput
                            label="Username"
                            type="text"
                            name="userName"
                            id="userName"
                            required
                            size="lg"
                          />
                          <Form.Control.Feedback type="invalid">
                            Username is required
                          </Form.Control.Feedback>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <MDBInput
                            label="Password"
                            type="password"
                            name="passWord"
                            id="passWord"
                            required
                            size="lg"
                          />
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={12}>
                          <Button
                            type="submit"
                            size="sm"
                            variant="primary"
                            className="btn-block"
                            disabled={btnLoading}
                          >
                            {btnLoading ? "Please wait.." : "Login"}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
          {/* Login page design..*/}
        </div>
      </div>
    </>
  );
};

export default withRouter(LoginPage);
