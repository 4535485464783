import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../context";
import API from "../../utils/api";
import {
  integerIndMobile,
  integerKeyPress,
  lettersOnly,
  integerIndPincode,
} from "../../utils";
import $ from "jquery";
import { Link } from "react-router-dom";

const QuickEnquiry = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [adminCompany, setAdminCompany] = useState(false);
  const [branch, changeBranch] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setDataList] = useState(false);

  useEffect(() => {
    savelog();
    listBranch();
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/savequickenquiry", $("#addform").serialize()).then(
        (res) => {
          if (res["data"].errorcode === "200") {
            toast.success("Saved Successfully..!", "success");
            document.getElementById("addform").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish(res["data"].data);
            return res["data"].data;
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("addform").reset();
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      console.log(context);
      form.append("user", Number(context.adminUser.id));
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to New Enquiry Personal Info");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listBranch = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listbranch").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBranch(d);
          //setBranchData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Form
        action=""
        method="post"
        id="addform"
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Card>
          <Card.Header>
            <h4>Quick Enquiry</h4>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={6}>
                <Row className="mt-2">
                  <Col md={2}>
                    <label>
                      Customer Name <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={1}>
                    <Form.Control
                      as="select"
                      name="customer_title"
                      required
                      size="sm"
                    >
                      <option value="M/S"> M/s </option>
                      <option value="Mr"> Mr </option>
                      <option value="Mrs"> Mrs </option>
                      <option value="Er"> Er </option>
                      <option value="Dr"> Dr </option>
                      <option value="Fr"> Fr </option>
                    </Form.Control>
                  </Col>
                  <Col md={7}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      placeholder="Name of the customer"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={2}>
                    <label>
                      Mobile No <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="mobile"
                      placeholder="Mobile Number"
                      onKeyPress={integerIndMobile}
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      Email-Id <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="email"
                      size="sm"
                      name="emailid"
                      placeholder="Email-Id"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      Phone <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="phone"
                      placeholder="Phone Number"
                      onKeyPress={integerIndMobile}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      From <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="place"
                      placeholder="Place"
                      required
                      onKeyPress={lettersOnly}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      Destinaton <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="place_ds"
                      placeholder="Place"
                      onKeyPress={lettersOnly}
                      required
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6} className="mt-3">
                <Row>
                  <Col md={2}>
                    <label>
                      Go To <span className="text-danger">*</span>
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      as="select"
                      name="user_id"
                      id="user_id"
                      required
                      size="sm"
                    >
                      <option value=""> -SELECT- </option>
                      {branch.map((item, id) => {
                        return <option value={item.id}>{item.username}</option>;
                      })}
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
              <Col md={4}></Col>
              <Col md={2} className="mt-4">
                <Row></Row>
                <Col className="d-flex justify-content-end">
                  <Link
                    to={{
                      pathname: "/enquiry",
                    }}
                  />
                  <Button
                    type="submit"
                    size="sm"
                    variant="success"
                    disabled={btnLoading}
                  >
                    <i className="fas fa-check"></i>{" "}
                    {btnLoading ? "Please wait.." : "Save"}
                    <Link
                      to={{
                        pathname: "/enquiry",
                      }}
                    />
                  </Button>
                </Col>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
export default QuickEnquiry;
