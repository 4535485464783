import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../context';
import API from '../../utils/api';

const Survey = (props) =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [enquiry, setEnquiry] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');
	const [data, changeData] = useState([]);
	
	/* const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		setBtnLoading(true);
		API.post('v1/user/login', $("#frm_Login").serialize()).then(res=>{
			if(res['data'].errorcode != 200){
				toast.error(res['data'].content || res['data'].message);
				setBtnLoading(false);
			}
			else{
				context.saveAdminLogin(res['data'].data.user, res['data'].data.company, res['data'].token);
				history.push('/');
			}
		});
	};
	
	useEffect(() => {
		listdata();
	},[] );
	
	const listdata = () => {
	   try {
		    setIsLoaded(true);
			API.get('v1/user/surveylist').then(res => {
			   if (res['data'].status == '1') {
				 var d = res['data'].data;
				 changeData(d);
				 setIsLoaded(false);
			   }
			   else {
				setIsLoaded(false);
			   }
		 });
	   }
	   catch (error) {
			setIsLoaded(false);
	   }
	}  */
	
	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
				<div className="container" >
					<Row className="mt-1" >
						<Col md={12}>
							<Row>
								<Col md={12} className="mt-4">
									<MDBInput 
										label="Name"
										type="text"
										name="name"
										required
										size="lg"
										data={editData}
									 />
								</Col>
							</Row>
							
							<Row>
								<Col md={12} className="mt-4">
									<MDBInput 
										label="Mobile"
										type="text"
										name="mobile"
										required
										size="lg"
										data={editData}
									 />
								</Col>
							</Row>
							
							<Row>
								<Col md={12} className="mt-4">
									<MDBInput 
										label="To"
										type="text"
										name="emailid"
										required
										size="lg"
										data={editData}
									 />
								</Col>
							</Row>
						</Col>
						<Col md={6}>
							<Table bordered  className="table table-striped">
								<tbody>
									{(data.map((product) => {
										return <tr>
											<td style={{textAlign:'center'}} className="font-weight-bold">{product.protect_name}</td><input type="text" name ="protect_name" />
										</tr>
										}
										))}
								</tbody>
							</Table>
						</Col>
					</Row>
				</div>
			
		</>
	);
};
export default Survey;