import React, { useContext } from "react";
import { Switch, Route, withRouter, useHistory } from "react-router-dom";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";

import PsContext from "../../context";
import Header from "./header";
import pages from "../../routes/main";

const TopHeader = (props) => {
  const context = useContext(PsContext);
  const history = useHistory();

  const getRole = () => {
    console.log(context.adminUser.role);
    return context.adminUser.role;
  };

  if (context.adminLogged != "yes") {
    history.push("/login");
    return null;
  } else if (context.adminLogged == "yes") {
    return (
      <div className="app app-content-full-width app-sidebar-minified">
        <Toaster
          toastOptions={{
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          }}
        />
        <Header role={context.adminUser.role} />

        <div className="app-content">
          <Switch>
            {pages.map((item, i) => {
              return item.allowed.indexOf(context.adminUser.role) > -1 ? (
                <Route
                  path={item.path}
                  component={item.component}
                  exact={item.exact}
                />
              ) : null;
            })}
          </Switch>
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default withRouter(TopHeader);
