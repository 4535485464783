import React, {useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, Row, Col, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../context';
import { PsBredCrumb } from '../../comp';
import General from './component/general';
import Address from './component/address';
import SocialInfo from './component/socialInfo';

const UpdateCompany = (props) =>{
	
	const context = useContext(PsContext);
	const [hashValue, setHashValue] = useState(props.location.hash);
	console.log(hashValue);
	const btnClick=()=>{
		toast('Welcome');
	};
	 
	const links = [
		{text: 'General', link: '#general'},
		{text: 'Address Details', link: '#address'},
		{text: 'Social Info', link: '#socialinfo'},
	];
	
	return(
		<>
			
			<PsBredCrumb title={<span><i className="fas fa-md fa-fw me-1 fa-cog"></i> Update Company</span>} />
			
			<div className="container" >
				<Row>
					<Col md={9}>
						
						{(hashValue=='' || hashValue=='#general') && (<General />)}
						{hashValue=='#address' && (<Address />)}
						{hashValue=='#socialinfo' && (<SocialInfo />)}
						
					</Col>
					<Col md={3} >
						<nav id="" className="navbar navbar-sticky d-none d-xl-block shadow-none">
							<nav className="navs">
								{links.map((item,i)=>{
									return <a href={`${item.link}`} className="nav-link" onClick={()=> setHashValue(item.link) }> {item.text}</a>;
								})
								}
							</nav>
						</nav>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default withRouter(UpdateCompany);