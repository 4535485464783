import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Navbar,
  Container,
  Nav,
  NavDropdown,
  Button,
  Collapse,
} from "react-bootstrap";
import {
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from "mdb-react-ui-kit";

import { PsDropDown } from "../../../comp";
import PsContext from "../../../context";
import { appName } from "../../../utils";
import menus from "../../menus";
import $ from "jquery";

const Header = (props) => {
  const context = useContext(PsContext);
  const [logoutModal, setLogoutModal] = useState(false);
  const [currentLink, setCurrentLink] = useState("");

  const topMenu = () => menus.top.map((item, i) => menuType(item, i));

  const menuType = (item, i) => {
    if (item.childrens && item.childrens.length > 0) {
      return dropDown(item.name, item.childrens);
    } else {
      return item.allowed && item.allowed.indexOf(props.role) > -1 ? (
        <li className="nav-item">
          <NavLink
            to={item.path}
            className="nav-link pe-2 d-flex align-items-center gap-2"
            exact={item.exact}
          >
            <i className={`${item.icon} fs-5 ps-2 pe-3`}></i>
            <span>{item.name}</span>
          </NavLink>
        </li>
      ) : null;
    }
  };

  const dropDown = (item, items) => {
    /*return (
      <NavDropdown title={title}>
        {items.map((item, i) => dropDownItem(item))}
      </NavDropdown>
    ); */
    return item.allowed && item.allowed.indexOf(props.role) > -1 ? (
      <li className="nav-item">
        <a
          className="nav-link pe-2 d-flex align-items-center gap-2 dropdown-toggle"
          href="javascript:;"
          onClick={() => sideBarLink(slug(item.name))}
        >
          <i className={`${item.icon} fs-5 ps-2 pe-3`}></i>
          <span className="me-auto">{item.name}</span>
        </a>
        {dropDownItem(items, item.name)}
      </li>
    ) : null;
  };

  const dropDownItem = (item, title) => {
    if (item.divider) return <NavDropdown.Divider />;

    return (
      <Collapse in={currentLink == slug(title)}>
        <ul className="ms-5 navbar-nav">
          {item.map((it, i) => {
            return it.allowed && it.allowed.indexOf(props.role) > -1 ? (
              <li className="nav-item">
                <NavLink className="nav-link" to={it.path} exact={item.exact}>
                  {it.name}
                </NavLink>
              </li>
            ) : null;
          })}
        </ul>
      </Collapse>
    );
  };

  const logoutClick = () => {
    setLogoutModal(true);
    //context.logoutAdmin();
  };

  const slug = (str) =>
    str
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");

  const sideBarLink = (v) => {
    if (currentLink == v) {
      setCurrentLink("");
    } else {
      setCurrentLink(v);
    }
  };

  const sidebarToggleSm = () => {
    if ($("#sidebar").hasClass("show")) {
      $("#sidebar").removeClass("show");
    } else {
      $("#sidebar").addClass("show");
    }
  };
  return (
    <>
      <Navbar bg="dark" expand="lg" variant="dark" className="app-header">
        <Container fluid>
          <Navbar.Brand>{appName}</Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">{topMenu()}</Nav>

            <Nav className="ml-auto my-2 my-lg-0 nav-left">
              <NavDropdown
                title={`${context.adminUser.username}`}
                id="navbarScrollingDropdown"
                drop="start"
              >
                {/* <NavLink to="/p" className="dropdown-item" >
					<i className="fa fa-user fa-fw ms-auto text-gray-400 fs-14px"></i> My Profile
				  </NavLink>
				  <NavDropdown.Divider />  */}
                <NavLink to="/settings/update" className="dropdown-item">
                  <i className="fa fa-cogs fa-fw ms-auto text-gray-400 fs-14px"></i>{" "}
                  Settings
                </NavLink>
              </NavDropdown>
              <a className="nav-link" href="javascript:;" onClick={logoutClick}>
                <i className="fa fa-sign-out-alt fa-fw ms-auto text-gray-400 fs-16px "></i>
              </a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <MDBModal
        show={logoutModal}
        getOpenState={(e: any) => setLogoutModal(e)}
        tabIndex="-1"
      >
        <MDBModalDialog className="modal-frame-top">
          <MDBModalContent>
            <MDBModalBody>
              <div className="d-flex justify-content-center align-items-center my-3">
                <h5 className="pe-5">Do you want to logout ? </h5>
                <Button
                  type="button"
                  size="sm"
                  onClick={() => context.logoutAdmin()}
                >
                  Yes
                </Button>
                &emsp;
                <Button
                  type="button"
                  size="sm"
                  variant="info"
                  onClick={() => setLogoutModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default Header;
