import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Form, Modal, Table, Button } from "react-bootstrap";
import toast from "react-hot-toast";
import API from "../../utils/api";
import PsContext from "../../context";
import { MDBInput } from "mdb-react-ui-kit";
import { PsBredCrumb } from "../../comp";
import { integerIndMobile, integerKeyPress, lettersOnly } from "../../utils";
import $ from "jquery";
import { Link } from "react-router-dom";

const AddDespatch = () => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setDataList] = useState(false);
  const [dateField, setDateField] = useState("");
  const [vehicleData, setVehicleData] = useState([]);

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() === true) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    try {
      setBtnLoading(true);
      API.post("v1/user/despatch", $("#addform").serialize()).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Saved Successfully..!", "success");
          document.getElementById("addform").reset();
          setBtnLoading(false);
        } else {
          //toast.error("Something Went Wrong..!", "danger");
          document.getElementById("addform").reset();
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  useEffect(() => {
    var date =
      new Date().getDate() +
      "-" +
      (new Date().getMonth() + 1) +
      new Date().getFullYear();
    setDateField(date);
    getvehicle();
  }, []);

  const getvehicle = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listvehicle").then((res) => {
        if (res["data"].status == "1") {
          setVehicleData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <Row>
        <Col md={9}></Col>
        <Col md={3}>
          <Link to="/viewdespatch">
            <Button style={{ float: "right" }} variant="success">
              <i className="fas fa-eye"></i> View Despatch
            </Button>
          </Link>
        </Col>
      </Row>
      <PsBredCrumb title="Add Despatch"></PsBredCrumb>
      <Form
        action=""
        method="post"
        id="addform"
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <input
          type="hidden"
          name="user"
          id="user"
          value={context.adminUser.id}
        />
        <Card>
          <Card.Body>
            <Row>
              {/* <Col md={4}>
                <Form.Control
                    as="select"
                    name="vehicle"
                    id="vehicle"
                    size="sm"
                    required
                >
                    <option value=""> Select Vehicle </option>
                    {(vehicleData && vehicleData.map((item, id) => {
                        return <option value={item.id} > {item.regno} </option>
                    }
                    ))}
                </Form.Control>
              </Col> */}
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Despatch Date"
                  type="date"
                  name="despatch_date"
                  size="sm"
                  required
                  defaultValue={dateField}
                  onKeyPress={lettersOnly}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Customer Name"
                  type="text"
                  name="owner_name"
                  size="sm"
                  required
                  onKeyPress={lettersOnly}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Customer Contact"
                  type="text"
                  name="owner_contact"
                  size="sm"
                  required
                  onKeyPress={integerIndMobile}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="From"
                  type="text"
                  name="orgin"
                  size="sm"
                  required
                  onKeyPress={lettersOnly}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Destination"
                  type="text"
                  name="destination"
                  size="sm"
                  required
                  onKeyPress={lettersOnly}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Transport Name"
                  type="text"
                  name="transport_name"
                  size="sm"
                  required
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Driver No"
                  type="text"
                  name="driver_contact"
                  size="sm"
                  required
                  onKeyPress={integerIndMobile}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="License No"
                  type="text"
                  name="license_no"
                  size="sm"
                  required
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Vehicle OWner Name"
                  type="text"
                  name="driver_name"
                  size="sm"
                  required
                  onKeyPress={lettersOnly}
                />
              </Col>

              <Col md={4} className="mt-3">
                <MDBInput
                  label="Vehicle Hire"
                  type="text"
                  name="vehicle_hire"
                  size="sm"
                  required
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Advance"
                  type="number"
                  name="advance"
                  size="sm"
                  required
                  onKeyPress={integerKeyPress}
                />
              </Col>
              <Col md={4} className="mt-3">
                <MDBInput
                  label="Balance"
                  type="text"
                  name="balance"
                  size="sm"
                  required
                  onKeyPress={integerKeyPress}
                />
              </Col>

              <Col md={4} className="mt-3">
                <MDBInput
                  label="Unloaded By"
                  type="text"
                  name="unloaded_by"
                  size="sm"
                  required
                />
              </Col>

              <Col md={12} className="mt-3">
                <label>Comments</label>
                <Form.Control
                  as="textarea"
                  size="sm"
                  name="comments"
                  placeholder="Comments"
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="d-flex justify-content-end">
                <Button
                  type="submit"
                  size="sm"
                  variant="primary"
                  disabled={btnLoading}
                >
                  <i className="fas fa-check"></i>{" "}
                  {btnLoading ? "Please wait.." : "Save"}
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </>
  );
};
export default AddDespatch;
