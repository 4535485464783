import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../../context';
import API from '../../../utils/api';
import { Select } from 'antd';

const EditBooked = (props) => {

	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');


	const handleSubmit = (event) => {
		event.preventDefault();
		try {
			setBtnLoading(true);
			API.post('/v1/job/editConfirm', $("#frm_Login").serialize()).then(res => {
				if (res['data'].status == '1') {
					toast.success("Updated Successfully..!", "success");
					document.getElementById("frm_Login").reset();
					setBtnLoading(false);
					if (props.afterFinish) props.afterFinish();
					if (props.afterList) props.afterList();
				}
				else {
					toast.error("Something Went Wrong..!", "danger");
					document.getElementById("frm_Login").reset();

				}
				setIsLoaded(false);
			});
		}
		catch (error) {

		}
	}
	console.log(props.data);
	return (

		<>
			<Toaster
				toastOptions={{
					style: {
						borderRadius: '10px',
						background: '#333',
						color: '#fff',
					},
				}}
			/>

			<div>
				{/* Login page design..*/}
				<div className="container">
					<Row className="mt-1">
						<Col md={12}>
							<Form action="" method="post" id="frm_Login" noValidate validated={validated} onSubmit={handleSubmit}>
								<input type="hidden" value={props.data.enquiry_id} name="enquiry_id" />
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Name"
											type="text"
											name="name"
											required
											size="sm"
											defaultValue={props.data.name  || ''}
										/>
									</Col>
								</Row>

								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Mobile"
											type="text"
											name="mobile"
											required
											size="sm"
											defaultValue={props.data.mobile  || ''}
										/>
									</Col>
								</Row>

								{/* <Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Floor"
											type="text"
											name="floor"
											required
											size="sm"
											defaultValue={props.data.floor}
										/>
									</Col>
								</Row> */}


								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Street"
											type="text"
											name="street"
											required
											size="sm"
											defaultValue={props.data.street  || ''}
										/>
									</Col>
								</Row>

								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Place"
											type="text"
											name="place"
											required
											size="sm"
											defaultValue={props.data.place  || ''}
										/>
									</Col>
								</Row>

								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="City"
											type="text"
											name="city"
											required
											size="sm"
											defaultValue={props.data.city  || ''}
										/>
									</Col>
								</Row>



								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Pincode"
											type="text"
											name="pincode"
											required
											size="sm"
											defaultValue={props.data.pincode  || ''}
										/>
									</Col>
								</Row>


								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="State"
											type="text"
											name="state"
											required
											size="sm"
											defaultValue={props.data.state  || ''}
										/>
									</Col>
								</Row>



								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="From"
											type="text"
											name="city"
											required
											size="sm"
											defaultValue={props.data.city  || ''}
										/>
									</Col>
								</Row>

								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="To"
											type="text"
											name="city_ds"
											required
											size="sm"
											defaultValue={props.data.city_ds  || ''}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="street"
											type="text"
											name="street_ds"
											required
											size="sm"
											defaultValue={props.data.street_ds  || ''}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Load Details"
											type="text"
											name="media"
											required
											size="sm"
											defaultValue={props.data.media  || ''}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Towards"
											type="text"
											name="towards"
										//	required
											size="sm"
											defaultValue={props.data.towards || ''}
										/>
									</Col>
								</Row>
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="vehicle number"
											type="text"
											name="vehicle"
											required
											size="sm"
											defaultValue={props.data.vehicle || ''}
											
										/>
									</Col>
								</Row>
								<Row className="mt-2">
                   				
                 			   <Col md={12}>
                    		    <Form.Control
									//label=" Transaction Type"
                      		      	as="select"
                      				 name="transferdetails"
                       			     size="sm"
                       		  		className="fw-bold"
                      		  >
							<option vlaue="">-Select-</option>
							<option value="cash"> cash </option>
							<option value="Cheque"> Cheque </option>
							<option value="Online"> Online Transfer</option>
                           
                        </Form.Control>
                    </Col>
                </Row>
								{/* <Row>
								<Col md={12} className="mt-4">
									<Select id="">
										<option>---</option>
										<option>cash</option>
										<option>Cheque</option>
										<option>Online Transfer</option>
									</Select>
									</Col>
								</Row> */}
						 
								<Row>
									<Col md={12} className="mt-4">
										<MDBInput
											label="Amount"
											type="text"
											name="amount"
											required
											size="sm"
											defaultValue={props.data.amount  || ''}
											disabled
										/>
									</Col>
								</Row>
						
								

								<Row className="mt-4" >
									<Col md={12} >
										<Button
											type="submit"
											size="sm"
											variant="primary"
											className="btn-block"
											disabled={btnLoading}
										>
											<i className="fas fa-edit"></i> {btnLoading ? 'Please wait..' : 'Update'}
										</Button>
									</Col>
								</Row>
							</Form>

						</Col>
					</Row>
				</div>
				{/* Login page design..*/}
			</div>

		</>
	);
};
export default EditBooked;