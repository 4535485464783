import React, { useState, useContext, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import $ from "jquery";
import toast, { Toaster } from "react-hot-toast";
import { Row, Col, Card, Form, Button, Modal } from "react-bootstrap";
import { MDBInput } from "mdb-react-ui-kit";
import PsContext from "../../context";
import API from "../../utils/api";
import { integerIndMobile, integerKeyPress } from "../../utils";
import AddService from "./addService";

const EditPersonalInfo = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editData, setEditData] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [value, setValue] = useState("");
  const [productData, setProductData] = useState([]);
  const [mediaData, setMediaData] = useState([]);
  const [addSelect, setaddSelect] = useState([]);
  const [stdata, stchangeData] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [AddModal, setAddModal] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post(
        "/v1/user/updateservice",
        $("#frm_editservice").serialize()
      ).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Updated Successfully..!", "success");
          document.getElementById("frm_editservice").reset();
          setBtnLoading(false);
          if (props.afterFinish) props.afterFinish();
        } else {
          toast.error("Something Went Wrong..!", "danger");
          document.getElementById("frm_editservice").reset();
        }
        setIsLoaded(false);
      });
    } catch (error) {}
  };

  useEffect(() => {
    savelog();
    getproduct();
    getmedia();
    getvehicle();
    listItem();
    editEnquiryService();
  }, []);

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click to Edit Enquiry Service Info ".props.data.id);
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getproduct = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("id", props.data.id);
      API.post("v1/user/serviceinfo", form).then((res) => {
        if (res["data"].status == "1") {
          setProductData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getmedia = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listmedia").then((res) => {
        if (res["data"].status == "1") {
          setMediaData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const getvehicle = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listvehicle").then((res) => {
        if (res["data"].status == "1") {
          setVehicleData(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const productChange = (record, e) => {
    var d = productData;
    var index = d.findIndex((item) => item.id == record.id);

    if (index > -1) {
      d[index]["product_value"] = e.target.value;
      //setProductData(...productData, ...{id: record.id, product_name: record.product_name, product_value: e.target.value});
      setProductData(d);
    }
    /* console.log("target id "+e.target.name + ", "+e.target.value );
		console.log(productData);
		let d = productData;
		var id = e.target.name;
		var index = d.findIndex(function(obj){
			return(obj.id == id);
		});

		console.log("target id index : "+index); 
		if(index > -1){
			console.log("d[index]['product_value'] : "+d[index]['product_value']+" : tv  "+e.target.value)
			d[index]['product_value'] = e.target.value;
			setProductData(d);
		} */
  };

  const handleChange = (id, name, e) => {
    var d = [];
    var index = addSelect.findIndex(function (obj) {
      return obj.product_id == id;
    });
    if (index > -1) {
      d.push({ product_id: id, product_name: name, product_value: e });
      const arr = [...addSelect.filter((item, i) => i != index), ...d];
      setaddSelect(arr);
    }
  };

  const openBox = () => {
    var d = [];
    var id = $("#service").val();
    var product_list = $("#service  option:selected").attr("data-name");
    d.push({ product_id: id, product_name: product_list, product_value: "" });
    const arr = [...addSelect, ...d];
    setaddSelect(arr);
  };

  const listItem = () => {
    try {
      setIsLoaded(true);
      API.get("v1/user/listitem").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          stchangeData(d);
          setIsLoaded(false);
        } else {
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const editEnquiryService = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("id", props.data.id);
      API.post("v1/user/editenquiryservice", form).then((res) => {
        if (res["data"].status == "1") {
          setaddSelect(res["data"].data);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const openModal = () => {
    setAddModal(true);
  };

  const checkService = (id) => {
    var d = addSelect;
    var bstate = false;
    var index = d.findIndex(function (obj) {
      return obj.product_id == id;
    });
    if (index === -1) {
      bstate = true;
    }
    return bstate;
  };

  return (
    <>
      <Toaster
        toastOptions={{
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        }}
      />
      <div>
        <div className="container">
          <Row className="mt-1">
            <Col md={12}>
              <Form
                action=""
                method="post"
                id="frm_editservice"
                validated={validated}
                onSubmit={handleSubmit}
              >
                <input type="hidden" value={props.data.id} name="id" />
                <input type="hidden" value={context.adminUser.id} name="user" />
                <input
                  type="hidden"
                  value={context.adminCompany.id}
                  name="company"
                />
                <input
                  type="hidden"
                  value={JSON.stringify(addSelect)}
                  name="products"
                />

                {/*	<Row>
										{(productData.map((item, id) => {
											console.log(item);
											return <Col md={3} >
													<Row className="mt-3">
														<Col md={12} >
															<label style={{textTransform : 'capitalize'}}>{item.product_name.replaceAll('_',' ')}</label>
															<Form.Control
																type="text"
																size="sm" 
																//name={item.id}
																//id={item.id}
																placeholder={item.product_name.replaceAll('_',' ')}
																onKeyPress={integerKeyPress}
																defaultValue={item.product_value}
																onChange={e=>productChange(item, e)}
															/>
														</Col> 
													</Row> 
												</Col> 
											}
										))}
									</Row> */}
                <Row>
                  <Col md={10}></Col>
                  <Col md={2}>
                    <span style={{ float: "right" }}>
                      <Button variant="success" size="sm" onClick={openModal}>
                        <i className="fa fa-plus"></i> Add
                      </Button>
                    </span>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={4}>
                    <Form.Control
                      as="select"
                      name="product_name"
                      id="service"
                      size="sm"
                    >
                      <option value=""> -SELECT ITEM- </option>
                      {stdata.map((user, i) => {
                        if (checkService(user.id)) {
                          return (
                            <option
                              value={user.id}
                              data-name={user.product_name}
                            >
                              {user.product_name}
                            </option>
                          );
                        }
                      })}
                    </Form.Control>
                  </Col>
                  <Col md={2}>
                    <Button variant="primary" size="sm" onClick={openBox}>
                      <i className="fa fa-plus"></i>{" "}
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-3">
                  {addSelect.map((item, i) => {
                    return (
                      <>
                        <Col md={3} className="mt-3">
                          <label>{item.product_name}</label>
                        </Col>
                        <Col md={1} className="mt-3">
                          <Form.Control
                            type="text"
                            size="sm"
                            placeholder={item.product_name}
                            defaultValue={item.product_value}
                            onChange={(e) => {
                              handleChange(
                                item.product_id,
                                item.product_name,
                                e.target.value
                              );
                            }}
                          ></Form.Control>
                        </Col>
                      </>
                    );
                  })}
                </Row>
                <Row className="mt-3">
                  {/*<Col md={3} >
											<label>Vehicle</label>
											<Form.Control
												as="select"
												name="vehicle"
												required
												size="sm"
												defaultValue={props.data.vehicle}
											>
												<option value="" > Select Vehicle </option>
												{(vehicleData && vehicleData.map((item, id) => {
													return <option value={item.id} > {item.regno} </option>
													}
												))}
											</Form.Control>
										</Col>  */}
                  <Col md={3}>
                    <label>Vehicle</label>
                    <Form.Control
                      type="text"
                      name="vehicle"
                      size="sm"
                      defaultValue={props.data.vehicle}
                    ></Form.Control>
                  </Col>
                  <Col md={3}>
                    <label>
                      Basis <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      as="select"
                      name="basis"
                      required
                      size="sm"
                      defaultValue={props.data.basis}
                    >
                      <option value="Part Load"> Part Load </option>
                      <option value="Full Load"> Full Load </option>
                    </Form.Control>
                  </Col>
                  <Col md={3}>
                    <label>
                      Media <span className="text-danger">*</span>
                    </label>
                    {/*<Form.Control
												as="select"
												name="media"
												required
												size="sm"
												defaultValue={props.data.media}
											>
												<option value=""> Select Media </option>
												{(mediaData && mediaData.map((item, id) => {
													return <option value={item.id} > {item.media} </option>
													}
												))}
											</Form.Control> */}
                    <Form.Control
                      as="select"
                      name="media"
                      required
                      size="sm"
                      defaultValue={props.data.media}
                    >
                      <option value="">Select Media</option>
                      {mediaData &&
                        mediaData.map((item, i) => {
                          return (
                            <option
                              value={item.id}
                              selected={
                                item.id == props.data.media ? true : false
                              }
                            >
                              {item.media}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                  <Col md={3}>
                    <label>
                      Moving Date <span className="text-danger">*</span>
                    </label>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="moving_date"
                      placeholder="Moving Date"
                      required
                      defaultValue={props.data.moving_date}
                    />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col md={12}>
                    <label>Comments</label>
                    <Form.Control
                      as="textarea"
                      size="sm"
                      name="comments"
                      placeholder="Comments"
                      defaultValue={props.data.comments}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col md={12}>
                    <Button
                      type="submit"
                      size="md"
                      variant="primary"
                      className="btn-block"
                      disabled={btnLoading}
                    >
                      {btnLoading ? "Please wait.." : "Update"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Modal
            show={AddModal}
            size="sm"
            onHide={() => setAddModal(false)}
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Add</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddService
                afterFinish={() => {
                  setAddModal(false);
                  listItem();
                }}
              />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default EditPersonalInfo;
