import React, { useState, useContext, useEffect } from "react";
import { Card, Row, Col, Button, Modal, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
//import AddForm from './addReject';
//import EditDash from '././editReject';
//import ViewDash from '././viewReject';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { jsPDF } from "jspdf";
import PsContext from "../../context";
import API from "../../utils/api";
import $ from "jquery";
import { PsBredCrumb } from "../../comp";

const Rejected = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [data, changeData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [bookedData, changeBookedData] = useState([]);
  const [enquries, changeEnquries] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const btnClick = () => {
    toast("Welcome");
  };

  const viewdash = (item) => {
    setViewData(item);
    setViewUser(true);
  };

  useEffect(() => {
    //listdata();
    listBooked();
    //listEnquries();
    // savelog();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      API.get("v1/job/followuplist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listEnquries = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      API.get("v1/job/listenquries").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeEnquries(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Dashboard");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listBooked = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/upcomingfollowup", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBookedData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title=""></PsBredCrumb>

      <Card>
        <Card.Header style={{ backgroundColor: "pink" }}>
          Upcoming Packing
        </Card.Header>
        <Card.Body>
          <Table className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Name
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Mobile
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  From
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  To
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Date
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Load Details
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {bookedData.map((item, id) => {
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.mobile}</td>
                    <td style={{ textAlign: "center" }}>{item.city}</td>
                    <td style={{ textAlign: "center" }}>{item.city_ds}</td>
                    <td style={{ textAlign: "center" }}>{item.update_date}</td>
                    <td style={{ textAlign: "center" }}>{item.basis}</td>
                    <td style={{ textAlign: "center" }}>{item.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};
export default Rejected;
