import { Switch, Route, HashRouter } from "react-router-dom";

import LoginPage from "../pages/loginPage";
import TopHeader from "../pages/layout/topHeader";

export default () => (
  <HashRouter>
    <Switch>
      <Route path="/login" component={LoginPage} />
      <Route path="/" component={TopHeader} />
    </Switch>
  </HashRouter>
);
