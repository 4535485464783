import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Modal,
  Table,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import AddForm from "./addForm";
import EditDash from "././editDash";
import ViewDash from "././viewDash";
import API from "../../utils/api";
import PsContext from "../../context";
import { PsBredCrumb } from "../../comp";
import Confirm from "./confirm";
import Booked from "./booked";
import Rejected from "./rejected";
import Survey from "./survey";
import Enquiries from "./enquiries";
import { Link } from "react-router-dom";

const Home = () => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [viewUser, setViewUser] = useState(false);
  const [data, changeData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [bookedData, changeBookedData] = useState([]);
  const [enquries, changeEnquries] = useState([]);
  const [viewData, setViewData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const btnClick = () => {
    toast("Welcome");
  };

  const viewdash = (item) => {
    setViewData(item);
    setViewUser(true);
  };

  useEffect(() => {
    listdata();
    listBooked();
    listEnquries();
    savelog();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      API.get("v1/job/followuplist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listEnquries = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      API.get("v1/job/listenquries").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeEnquries(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const savelog = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      form.append("company", context.adminCompany.id);
      form.append("type", "POST");
      form.append("note", "Click Dashboard");
      API.post("v1/user/savelog", form).then((res) => {
        if (res["data"].status == "1") {
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const listBooked = () => {
    try {
      setIsLoaded(true);
      API.get("v1/job/bookedlist").then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeBookedData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  return (
    <>
      <PsBredCrumb title="Dashboard">
        <ul className="list-inline">
          <li className="list-inline-item"></li>
        </ul>
      </PsBredCrumb>
      <Tabs
        defaultActiveKey="Booked"
        id="uncontrolled-tab-example"
        animation={true}
        className="mb-3"
      >
        <Tab eventKey="Booked" title="Today Packing">
          <Booked />
        </Tab>
        <Tab eventKey="rejected" title="Upcoming Packing">
          <Rejected />
        </Tab>
        <Tab eventKey="Confirm" title="Today Follow Ups">
          <Confirm />
        </Tab>
        <Tab eventKey="Enquiries" title="Enquiries">
          <Enquiries />
        </Tab>
        <Tab eventKey="Survey" title="Today Survey">
          <Survey />
        </Tab>
      </Tabs>
      {/*
      </PsBredCrumb>
      <Row>
        <Col md={8}>
          <Row>
            <Col md={12}>
              <Card>
                <Link to="/enquiries" className="links">
                  <Card.Header style={{ backgroundColor: "tan" }}>
                    Today Follow Ups
                  </Card.Header>
                </Link>
                <Card.Body>
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Name
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Mobile
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          From
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          To
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Date
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Load Details
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, id) => {
                        return (
                          <tr>
                            <td style={{ textAlign: "center" }}>{item.name}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>{item.city}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.city_ds}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.enquiry_date}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.basis}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.amount}
                            </td>
                           
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} className="mt-3">
              <Card>
                <Link to="/enquiries" className="links">
                  <Card.Header style={{ backgroundColor: "mediumseagreen" }}>
                    Upcoming Follow Ups
                  </Card.Header>
                </Link>
                <Card.Body>
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Name
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Mobile
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          From
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          To
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Date
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Load Details
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Amount
                        </th>
                      
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, id) => {
                        return (
                          <tr>
                            <td style={{ textAlign: "center" }}>{item.name}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>{item.city}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.city_ds}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.enquiry_date}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.basis}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.amount}
                            </td>
                           
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} className="mt-3">
              <Card>
                <Link to="/jobs" className="links">
                  <Card.Header style={{ backgroundColor: "pink" }}>
                    Upcoming Orders
                  </Card.Header>
                </Link>
                <Card.Body>
                  <Table className="table table-striped">
                    <thead>
                      <tr>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Name
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Mobile
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          From
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          To
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Date
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Load Details
                        </th>
                        <th
                          style={{ textAlign: "center" }}
                          className="font-weight-bold"
                        >
                          Amount
                        </th>
                       
                      </tr>
                    </thead>
                    <tbody>
                      {bookedData.map((item, id) => {
                        return (
                          <tr>
                            <td style={{ textAlign: "center" }}>{item.name}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.mobile}
                            </td>
                            <td style={{ textAlign: "center" }}>{item.city}</td>
                            <td style={{ textAlign: "center" }}>
                              {item.city_ds}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.enquiry_date}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.basis}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              {item.amount}
                            </td>
                            
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <Row>
            <Col md={12}>
              <Card>
                <Link to="/enquiries" className="links">
                  <Card.Header style={{ backgroundColor: "skyblue" }}>
                    Enquiries
                  </Card.Header>
                </Link>
                <Card.Body>
                  {enquries.map((item, id) => {
                    return (
                      <Row>
                        <Col md={12}>
                          <p style={{ color: "green" }}>
                            Enquiry ID - {item.enquiry_id}
                          </p>
                          Customer: &nbsp;<b>{item.name}</b> ,{item.mobile}{" "}
                          &nbsp; <b>From</b>: {item.place} - <b>To</b> :{" "}
                          {item.place_ds}
                          <br />
                          <hr />
                        </Col>
                      </Row>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        </Row>  */}
    </>
  );
};
export default Home;
