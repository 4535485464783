import React, {useState, useContext, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Form, Table, Tabs, Tab} from 'react-bootstrap';
import toast from 'react-hot-toast';
import PsContext from '../../../context';
import API from '../../../utils/api';
import $ from 'jquery';
import {Link, useHistory} from 'react-router-dom';

import PersonalInfo from './personalInfo';
import ServiceInfo from './serviceInfo';

const NewEnquiry = (props) =>{
	
	const context = useContext(PsContext);
	const history = useHistory();
	
	const [tabId, setTabId] = useState(1);
	const [editId, setEditId] = useState('');
	const [redirect, setRedirect] = useState(false);

	var setID = '';
	
	const tabs = [
		{name: 'Personal Info', id: '1'},
		{name: 'Service Info', id: '2'},
	];
	
	const tabLinkClick=(tab)=> console.log(tab.id);
	
	const tabItem=(tab)=>{
		return <li key={tab.id}>
			<a className={tabId==tab.id ? "nav-link active" : 'nav-link'} href="javascript:;" >
				{tab.name}
			</a>
		</li>;
	};
	
	if(redirect) {
		history.push('/enquiries');
	}
	
	const tabSave=(id)=>{
		setEditId(id);
		setTabId(2);
	}
	return(
	<>
		<ul className="nav nav-tabs">
		{tabs.map(item => tabItem(item))}
		</ul>
		
		{tabId==1 && (<PersonalInfo afterFinish={tabSave} />)} 
		
		{tabId==2 && editId && (<ServiceInfo editId={editId} afterFinish={()=> setRedirect(true)} />)}
		
	</>
	);
};
export default NewEnquiry;