import React, { useState, useContext } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import $ from 'jquery';
import toast, { Toaster } from 'react-hot-toast';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { MDBInput } from 'mdb-react-ui-kit';
import PsContext from '../../../context';
import API from '../../../utils/api';

const EditAddress = () =>{
	
	const context = useContext(PsContext);
	const [adminCompany, setAdminCompany] = useState(false);
	const [editData, setEditData] = useState(false);
	const [validated, setValidated] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);
	const [value, setValue] = useState('');
	
	/* const handleSubmit = (event) => {
		const form = event.currentTarget;
		event.preventDefault();
		if (!form.checkValidity() === true) {
		  event.stopPropagation();
		  setValidated(true);
		  return;
		}
		setBtnLoading(true);
		API.post('v1/user/login', $("#frm_Login").serialize()).then(res=>{
			if(res['data'].errorcode != 200){
				toast.error(res['data'].content || res['data'].message);
				setBtnLoading(false);
			}
			else{
				context.saveAdminLogin(res['data'].data.user, res['data'].data.company, res['data'].token);
				history.push('/');
			}
		});
	}; */
	
	const handleSubmit = (event) => {
	event.preventDefault();
		try{
			setBtnLoading(true);
			API.post('/v1/user/update',$("#frm_Login").serialize()).then(res=>{
			if (res['data'].status == '1') {
			 alert("Updated Successfully..!", "success");
			 document.getElementById("frm_Login").reset();
			 setBtnLoading(false);
			  context.saveAdminLogin(res['data'].data.user, res['data'].data.company, res['data'].token);
		}
		else {
			 alert("Something Went Wrong..!", "danger");
			 document.getElementById("frm_Login").reset();
		}
		setIsLoaded(false);
		});
		}
		catch(error){

		}
	}
		
	return(
		<>
			<Toaster 
				toastOptions={{
					style: {
					  borderRadius: '10px',
					  background: '#333',
					  color: '#fff',
					},
				}}
			/>
			
			<div>
				{/* Login page design..*/}
					<div className="container" >
						<Row className="mt-1" >
							<Col md={12}>
								
								<Form action="" method="post" id="frm_Login" validated={validated} onSubmit={handleSubmit}>
									<input type="hidden" value={context.adminCompany.id} name="id" />
									<input type="hidden" value={context.adminUser.id} name="user" />
									<input type="hidden" value={context.adminCompany.name} name="name" />
									<input type="hidden" value={context.adminCompany.gst} name="gst" />
									<input type="hidden" value={context.adminCompany.whatsapp} name="whatsapp" />
									<input type="hidden" value={context.adminCompany.instagram} name="instagram" />
									<input type="hidden" value={context.adminCompany.twitter} name="twitter" />
									<input type="hidden" value={context.adminCompany.facebook} name="facebook" />
										<Row>
											<Col md={12} className="mt-4">
												<MDBInput 
													label="Address"
													type="text"
													name="address"
													id="address"
													required
													size="lg"
													defaultValue={context.adminCompany.address || ''}
												 />
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="mt-4">
												<MDBInput 
													label="City"
													type="text"
													name="city"
													id="city"
													required
													size="lg"
													defaultValue={context.adminCompany.city || ''}
												 />
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="mt-4">
												<MDBInput 
													label="Country"
													type="text"
													name="country"
													id="country"
													required
													size="lg"
													defaultValue={context.adminCompany.country || ''}
												 />
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="mt-4">
												<MDBInput 
													label="Pincode"
													type="text"
													name="pincode"
													id="pincode"
													required
													size="lg"
													defaultValue={context.adminCompany.pincode || ''}
												 />
											</Col>
										</Row>
										
										<Row>
											<Col md={12} className="mt-4">
												<MDBInput 
													label="State"
													type="text"
													name="state"
													id="state"
													required
													size="lg"
													defaultValue={context.adminCompany.state || ''}
												 />
											</Col>
										</Row>
										
										<Row className="mt-4" >
											<Col md={12} >
												<Button
													type="submit"
													size="md"
													variant="primary"
													className="btn-block"
													disabled={btnLoading}
												>
												{btnLoading ? 'Please wait..' : 'Update'}
												</Button>
											</Col>
										</Row>
								</Form>
								
							</Col>
						</Row>
					</div>
				{/* Login page design..*/}
			</div>
			
		</>
	);
};
export default EditAddress;