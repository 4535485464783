import React, { useState, useContext, useEffect } from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { Card, Row, Col, Button, Modal, Form, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { jsPDF } from "jspdf";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { photoUrl, numberToWords } from "../../../utils";
import { PsBredCrumb } from "../../../comp";
import $ from "jquery";
import { Link } from "react-router-dom";
import ViewBooked from "./viewBooked";
import EditBooked from "./editBooked";
import ViewReceipt from "./viewReceipt";
import EditPersonal from "./editPersonalInfo";
import EditService from "./editServiceInfo";
import QuotationBooked from "./quotationBooked";
import UpdateText from "./updateText";
import { MDBInput } from "mdb-react-ui-kit";



const Booked = (props) => {
  const context = useContext(PsContext);
  const [adminCompany, setAdminCompany] = useState(false);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [enquiryData, setEnquiryData] = useState([]);
  const [editService, setEditService] = useState(false);
  const [viewData, setViewData] = useState([]);
  const [viewReceiptData, setViewReceiptData] = useState([]);
  const [viewUser, setViewUser] = useState(false);
  const [viewReceiptUser, setViewReceiptUser] = useState(false);
  const [editConfrim, setEditConfrim] = useState([]);
  const [editUser, setEditUser] = useState(false);
  const [data, changeData] = useState([]);
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth() + 1
    }/${current.getFullYear()}`;
  const doc = new jsPDF("l", "pt", "a4");
  const [printData, setPrintData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [print, setPrint] = useState(false);
  const [receiptData, setReceiptData] = useState([]);
  const [smsSend, changeSmsSend] = useState([]);
  const [whatsapp, changeWhatsapp] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [dateField, setDateField] = useState("");
  const [endDate, setEndDate] = useState("");
  const [receipt, setReceipt] = useState(false);
  const [updateAmount, setUpdateAmount] = useState(false);
  const [updateGCText, setUpdateGCText] = useState(false);

  const tdStyle = {
    border: "1px solid grey",
    borderCollapse: "collapse",
    padding: "1px",
  };
  const tddStyle = { border: "1px solid grey" };
  const thStyle = {
    border: "1px solid black",
    borderRadius: "10px",
    padding: "1px",
  };

  /* const imgStyle = {
    position: "fixed",
    bottom: "0px",
    right: "0px",
    width: "100",
    zIndex: "-1",
    paddingBottom: "700px"
  }
 */
  const imgStyle1 = {
    position: "absolute",
    bottom: "0px",
    right: "0px",
    width: "100%",
    zIndex: "-1",
  }


  const printPdf = () => {
    const pdfdoc = document.getElementById("bookedpdf");
    doc.html(pdfdoc, {
      margins: [40, 60, 40, 60],
      autoPaging: true,
      callback: function (doc) {
        doc.save(date);
      },
      x: 30,
      y: 30,
    });
  };

  useEffect(() => {
    var date =
      new Date().getDate() +
      "-" +
      (new Date().getMonth() + 1) +
      new Date().getFullYear();
    setDateField(date);
    listdata();
  }, []);

  const listdata = () => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("user", context.adminUser.id);
      API.post("v1/job/bookedlist", form).then((res) => {
        if (res["data"].status == "1") {
          var d = res["data"].data;
          changeData(d);
          setEnquiryData(d);
          setIsLoaded(false);
        } else {
          //alert(res['data'].msg, "danger");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const editPersonal = (item) => {
    setEditData(item);
    //setEditUser(true);
  };

  const editServiceInfo = (item) => {
    setEditData(item);
    setEditService(true);
  };

  const onSearch = (e) => {
     console.log('test2',e.target.value);
    //try {
      var d = enquiryData;
      console.log(typeof(enquiryData[0].amount));
      const s = e.target.value.toUpperCase();
       //console.log(s);
       console.log('testing',d,s)
      let ndata = d.filter(function (item) {
        const name = item.name.toUpperCase();
        const uname = item.mobile.toUpperCase();
        const fromplace = item.city.toUpperCase();
        const toplace = item.city_ds.toUpperCase();
         const amount =item.amount ? item.amount.toString():'';
        console.log(typeof(amount));
        // const amount = item.amount.toUpperCase();
       
        return (
          name.indexOf(s) > -1 ||
          uname.indexOf(s) > -1 ||
          fromplace.indexOf(s) > -1 ||
          toplace.indexOf(s) > -1 ||
          amount== s 
        )
      });
      if (s === "") {
        changeData(d);
        console.log(changeData);
      } else {
        changeData(ndata);
        console.log(changeData);
      }
   // } catch (error) {
     // setIsLoaded(false);
    //}
  };
  // const onSearch = (e) => {
  //   try {
  //     var d = enquiryData;
  //     const s = e.target.value.toUpperCase();
  //     let ndata = d.filter(function (item) {
  //       const name = item.name.toUpperCase();
  //       const mobile = item.mobile.toUpperCase();
  //       const amount = item.amount.toUpperCase();
  //       // const placefrom = item.place.toUpperCase();
  //       // const placeto = item.place_ds.toUpperCase();
  //       const city = item.city.toUpperCase();
  //       const cityds = item.city_ds.toUpperCase();
  //       return (
  //         name.indexOf(s) > -1 ||
  //         mobile.indexOf(s) > -1 ||
  //         amount.indexOf(s) > -1 ||
  //         // placefrom.indexOf(s) > -1 ||
  //         // placeto.indexOf(s) > -1 ||
  //         city.indexOf(s) > -1 ||
  //         cityds.indexOf(s) > -1
  //       );
  //     });
  //     if (s == "") {
  //       changeData(d);
  //     } else {
  //       changeData(ndata);
  //     }
  //   } catch (error) {
  //     setIsLoaded(false);
  //   }
  // };
  const viewdash = (item) => {
    setViewData(item);
    setViewUser(true);
  };

  const viewModal = (item) => {
    setViewReceiptData(item);
    setViewReceiptUser(true);
  };

  const editdash = (item) => {
    setEditConfrim(item);
    setEditUser(true);
  };

  const handleDeleteClick = (item, enquiry_id) => {
    try {
      setIsLoaded(true);
      var form = new FormData();
      form.append("enquiry_id", enquiry_id);
      API.post("v1/user/deleteenquiry", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Delete Successfully", "success");
          var d = data;
          var index = d.findIndex(function (obj) {
            return obj.enquiry_id == enquiry_id;
          });
          if (index > -1) d.splice(index, 1);
          setEnquiryData(d);
          changeData(d);
          setIsLoaded(false);
          listdata();
        } else {
          //toast.error(res['data'].message, "danger");
          toast.success("Delete Successfully", "success");
          setIsLoaded(false);
        }
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handlePrint = (item) => {
    setPrintData(item);
    setPrint(true);
    setTimeout(() => printDocument(), 1000);
  };

  //receipt
  const handleReceipt = (item) => {
    setPrintData(item);
    setReceipt(true);
    setTimeout(() => printReceipt(), 1000);
  };

  const handleInvoice = (item) => {
    setPrintData(item);
    setReceipt(true);
    setTimeout(() => printInvoice(), 1000);
  };

  const printDocument = () => {
    var content = document.getElementById("new_print_tbl");
    var pri = document.getElementById("print_frame").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const printReceipt = () => {
    var content = document.getElementById("new_print_receipt");
    console.log(content.innerHTML);
    var pri = document.getElementById("print_receipt").contentWindow;

    pri.document.open();
    pri.document.write(content.innerHTML);
    console.log(pri);
    pri.document.close();
    console.log(document.getElementById("print_receipt").innerHTML);
    pri.focus();
    pri.print();
  };

  const printInvoice = () => {
    var content = document.getElementById("new_print_invoice");
    var pri = document.getElementById("print_invoice").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  const onSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (!form.checkValidity() === true) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    const data = { startDate: startDate, endDate: endDate };
    try {
      setBtnLoading(true);
      API.post("v1/user/search/bookeddate", $("#datefrm").serialize()).then(
        (res) => {
          if (res["data"].status == "1") {
            var d = res["data"].data;
            //setEnquiryData(d);
            changeData(d);
            setIsLoaded(false);
            setBtnLoading(false);
          } else {
            changeData([]);
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const changeDate = (e) => {
    setStartDate(e.target.value);
  };

  const enddate = (e) => {
    setEndDate(e.target.value);
  };

  const UpdateAmount = (item) => {
    setEditData(item);
    setUpdateAmount(true);
  };

  const UpdateGCText = (item) => {
    setEditData(item);
    setUpdateGCText(true);
  };

  const handleWhatsappMessages = (
    item,
    mobile,
    name,
    update_date,
    customer_title,
    amount,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("update_date", update_date);
      form.append("customer_title", customer_title);
      form.append("amount", amount);
      API.post("v1/user/sendwhatsappreceipt", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeWhatsapp(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleReceiptMail = (
    item,
    mobile,
    name,
    emailid,
    amount,
    city,
    city_ds,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("emailid", emailid);
      form.append("amount", amount);
      form.append("city", city);
      form.append("city_ds", city_ds);
      API.post("v1/user/sendmailreceipt", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleInvoicetMail = (
    item,
    customer_title,
    emailid,
    mobile,
    name,
    street,
    place,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    amount,
    sgst,
    cgst,
    igst,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("customer_title", customer_title);
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("street", street);
      form.append("place", place);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("acremovingcharges", acremovingcharges);
      form.append("carpentercharges", carpentercharges);
      form.append("amount", amount);
      form.append("sgst", sgst);
      form.append("cgst", cgst);
      form.append("igst", igst);

      API.post("v1/user/sendinvoicemail", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleInvoicetWhatsapp = (
    item,
    customer_title,
    emailid,
    mobile,
    name,
    street,
    place,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    amount,
    sgst,
    cgst,
    igst,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("customer_title", customer_title);
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("street", street);
      form.append("place", place);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("acremovingcharges", acremovingcharges);
      form.append("carpentercharges", carpentercharges);
      form.append("amount", amount);
      form.append("sgst", sgst);
      form.append("cgst", cgst);
      form.append("igst", igst);

      API.post("v1/user/sendinvoicewhatsapp", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleGCtMail = (
    item,
    customer_title,
    emailid,
    mobile,
    name,
    street,
    place,
    place_ds,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    amount,
    sgst,
    cgst,
    igst,
    storagecharges,
    saidtocontain,
    declaredvalue,
    cash,
    gstno,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("customer_title", customer_title);
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("street", street);
      form.append("place", place);
      form.append("place_ds", place_ds);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("acremovingcharges", acremovingcharges);
      form.append("carpentercharges", carpentercharges);
      form.append("amount", amount);
      form.append("sgst", sgst);
      form.append("cgst", cgst);
      form.append("igst", igst);
      form.append("storagecharges", storagecharges);
      form.append("saidtocontain", saidtocontain);
      form.append("declaredvalue", declaredvalue);
      form.append("cash", cash);
      form.append("gstno", gstno);

      API.post("v1/user/sendmailgc", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };

  const handleGCWhatsapp = (
    item,
    customer_title,
    emailid,
    mobile,
    name,
    street,
    place,
    place_ds,
    pincode,
    transportcharges,
    cartransportationcharges,
    packingcharges,
    loadingcharges,
    unloadingcharges,
    unpackingcharges,
    city,
    city_ds,
    acremovingcharges,
    carpentercharges,
    amount,
    sgst,
    cgst,
    igst,
    storagecharges,
    saidtocontain,
    declaredvalue,
    cash,
    gstno,
    e
  ) => {
    try {
      setBtnLoading(true);
      var form = new FormData();
      form.append("customer_title", customer_title);
      form.append("emailid", emailid);
      form.append("mobile", mobile);
      form.append("name", name);
      form.append("street", street);
      form.append("place", place);
      form.append("place_ds", place_ds);
      form.append("pincode", pincode);
      form.append("transportcharges", transportcharges);
      form.append("cartransportationcharges", cartransportationcharges);
      form.append("packingcharges", packingcharges);
      form.append("loadingcharges", loadingcharges);
      form.append("unloadingcharges", unloadingcharges);
      form.append("unpackingcharges", unpackingcharges);
      form.append("city", city);
      form.append("city_ds", city_ds);
      form.append("acremovingcharges", acremovingcharges);
      form.append("carpentercharges", carpentercharges);
      form.append("amount", amount);
      form.append("sgst", sgst);
      form.append("cgst", cgst);
      form.append("igst", igst);
      form.append("storagecharges", storagecharges);
      form.append("saidtocontain", saidtocontain);
      form.append("declaredvalue", declaredvalue);
      form.append("cash", cash);
      form.append("gstno", gstno);

      API.post("v1/user/sendwhatsappgc", form).then((res) => {
        if (res["data"].status == "1") {
          toast.success("Message Sended Successfully", "success");
          var d = res["data"].data;
          changeSmsSend(d);
          setIsLoaded(false);
          setBtnLoading(false);
        } else {
          setBtnLoading(false);
        }
        setIsLoaded(false);
      });
    } catch (error) {
      setIsLoaded(false);
    }
  };
  // const value = '  'TO' 
  // const towards = printData.towards ? printData.towards :  {printData.place} {printData.place_ds} ;

  return (
    <>
      <PsBredCrumb title="Booked">
        <ul className="list-inline">
          <li className="list-inline-item">
            {/*<div className="input-group" >
							<div className="input-group-text bg-white">
								<i className="fa fa-search"></i>
							</div>
							<Form.Control 
								type="text"
								size="md"
								placeholder="Search.."
								style={{ width: '450px' }}
							/>
						</div> */}
          </li>
          {/*<li className="list-inline-item" >
						<button type="submit" className="btn btn-primary btn-md" onClick={()=>setAdminCompany(true)}>
							<i className="fa fa-plus"></i> New
						</button>
					</li>
					<li className="list-inline-item" >
						<a href="" className="btn btn-primary btn-md" >
							<i className="fa fa-user"></i> User
						</a>
					</li> */}
        </ul>
      </PsBredCrumb>
      <Card>
        <Card.Body>
          <Card.Title>
            <Row>
              <Col md={3}>
                {/*<Button size="sm"  variant="warning"><i class="fas fa-copy"></i>  Copy</Button>{' '}
								<Button size="sm"  variant="success"><i class="fas fa-file-excel"></i>  Excel</Button>{' '}
								<Button size="sm"  variant="primary"><i class="fas fa-file-csv"></i> CSV</Button>{' '} */}
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn-right btn btn-success btn-sm"
                  table="table-to-xls"
                  filename="bookedlist"
                  sheet="bookedlist"
                  buttonText={<i className="fas fa-file-excel"> Excel</i>}
                />
                &nbsp;
                <Button size="sm" variant="danger" onClick={printPdf}>
                  <i className="fas fa-file-pdf"> PDF</i>{" "}
                </Button>{" "}
              </Col>
              <Col md={5}>
                <Form
                  action=""
                  method="post"
                  id="datefrm"
                  noValidate
                  validated={validated}
                  onSubmit={onSubmit}
                >
                  <Row>
                    <Col md={5} className="mt-2">
                      <MDBInput
                        label="From Date"
                        type="date"
                        name="startDate"
                        id="startDate"
                        size="sm"
                        required
                        //onChange={changeDate}
                        //selected={startDate}
                        defaultValue={dateField}
                      />
                    </Col>
                    <Col md={5} className="mt-2">
                      <MDBInput
                        label="To Date"
                        type="date"
                        name="endDate"
                        id="endDate"
                        size="sm"
                        required
                        //onChange={endDate}
                        //selected={endDate}
                        defaultValue={dateField}
                      />
                    </Col>
                    <Col md={2} className="mt-2">
                      <Button
                        type="submit"
                        variant="success"
                        size="sm"
                        style={{ float: "right" }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col md={4}>
                <div className="input-group">
                  <div className="input-group-text bg-white">
                    <i className="fa fa-search"></i>
                  </div>
                  <Form.Control
                    type="text"
                    size="md"
                    placeholder="Search by Name,Mobile,From,To,Amount"
                    style={{ width: "200px" }}
                    onChange={(e) => {
                      onSearch(e);
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Card.Title>
          <Table id="table-to-xls" bordered className="table table-striped">
            <thead>
              <tr>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  #
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Name
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Mobile
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  From
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  To
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Booking Date
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Name
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Load Details
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Quotation
                </th>
                <th
                  style={{ textAlign: "center" }}
                  className="font-weight-bold"
                >
                  Amount
                </th>
                <th
                  style={{ textAlign: "center", width: "10%" }}
                  className="font-weight-bold"
                >
                  Receipt
                </th>
                <th
                  style={{ textAlign: "center", width: "10%" }}
                  className="font-weight-bold"
                >
                  Invoice
                </th>
                <th
                  style={{ textAlign: "center", width: "10%" }}
                  className="font-weight-bold"
                >
                  GC Print
                </th>
                <th
                  style={{ textAlign: "center", width: "10%" }}
                  className="font-weight-bold"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, id) => {
                return (
                  <tr>
                    <td style={{ textAlign: "center" }}>{id + 1}</td>
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.mobile}</td>
                    <td style={{ textAlign: "center" }}>
                      <span data-toggle="tooltip" title={item.city}>
                        {item.city}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <span data-toggle="tooltip" title={item.city_ds}>
                        {item.city_ds}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }}>{item.update_date}</td>
                    <td style={{ textAlign: "center" }}>{item.media}</td>
                    <td style={{ textAlign: "center" }}>{item.basis}</td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        size="sm"
                        variant="outline-warning"
                        onClick={() => UpdateAmount(item)}
                      >
                        View
                      </Button>
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {item.amount || "0"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        type="button"
                        variant="dark"
                        size="sm"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Receipt"
                        onClick={(e) => handleReceipt(item)}
                      //onClick={() => viewModal(item,item.id)}
                      >
                        <i className="fas fa-receipt"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="info"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Receipt MAIL"
                        onClick={(e) =>
                          handleReceiptMail(
                            item,
                            item.mobile,
                            item.name,
                            item.emailid,
                            item.amount,
                            item.city,
                            item.city_ds,
                            e
                          )
                        }
                      >
                        <i className="fas fa-envelope"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Receipt WHATSAPP"
                        onClick={(e) =>
                          handleWhatsappMessages(
                            item,
                            item.mobile,
                            item.name,
                            item.update_date,
                            item.customer_title,
                            item.amount,
                            e
                          )
                        }
                      >
                        <i className="fas fa-sms"></i>
                      </Button>{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        type="button"
                        variant="dark"
                        size="sm"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="InVoice Bill"
                        onClick={(e) => handleInvoice(item)}
                      >
                        <i className="fas fa-money-bill"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="info"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Invoice MAIL"
                        onClick={(e) =>
                          handleInvoicetMail(
                            item,
                            item.customer_title,
                            item.emailid,
                            item.mobile,
                            item.name,
                            item.street,
                            item.place,
                            item.pincode,
                            item.transportcharges,
                            item.cartransportationcharges,
                            item.packingcharges,
                            item.loadingcharges,
                            item.unloadingcharges,
                            item.unpackingcharges,
                            item.city,
                            item.city_ds,
                            item.acremovingcharges,
                            item.carpentercharges,
                            item.amount,
                            item.sgst,
                            item.cgst,
                            item.igst,
                            e
                          )
                        }
                      >
                        <i className="fas fa-envelope"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Invoice WHATSAPP"
                        onClick={(e) =>
                          handleInvoicetWhatsapp(
                            item,
                            item.customer_title,
                            item.emailid,
                            item.mobile,
                            item.name,
                            item.street,
                            item.place,
                            item.pincode,
                            item.transportcharges,
                            item.cartransportationcharges,
                            item.packingcharges,
                            item.loadingcharges,
                            item.unloadingcharges,
                            item.unpackingcharges,
                            item.city,
                            item.city_ds,
                            item.acremovingcharges,
                            item.carpentercharges,
                            item.amount,
                            item.sgst,
                            item.cgst,
                            item.igst,
                            e
                          )
                        }
                      >
                        <i className="fas fa-sms"></i>
                      </Button>{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="dark"
                        size="sm"
                        onClick={(e) => handlePrint(item)}
                        data-toggle="tooltip"
                        data-placement="top"
                        title="GC Print"
                      >
                        <i className="fa fa-print"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="info"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="GC MAIL"
                        onClick={(e) =>
                          handleGCtMail(
                            item,
                            item.customer_title,
                            item.emailid,
                            item.mobile,
                            item.name,
                            item.street,
                            item.place,
                            item.place_ds,
                            item.pincode,
                            item.transportcharges,
                            item.cartransportationcharges,
                            item.packingcharges,
                            item.loadingcharges,
                            item.unloadingcharges,
                            item.unpackingcharges,
                            item.city,
                            item.city_ds,
                            item.acremovingcharges,
                            item.carpentercharges,
                            item.amount,
                            item.sgst,
                            item.cgst,
                            item.igst,
                            item.storagecharges,
                            item.saidtocontain,
                            item.declaredvalue,
                            item.cash,
                            item.gstno,
                            e
                          )
                        }
                      >
                        <i className="fas fa-envelope"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="success"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="GC WHATSAPP"
                        onClick={(e) =>
                          handleGCWhatsapp(
                            item,
                            item.customer_title,
                            item.emailid,
                            item.mobile,
                            item.name,
                            item.street,
                            item.place,
                            item.place_ds,
                            item.pincode,
                            item.transportcharges,
                            item.cartransportationcharges,
                            item.packingcharges,
                            item.loadingcharges,
                            item.unloadingcharges,
                            item.unpackingcharges,
                            item.city,
                            item.city_ds,
                            item.acremovingcharges,
                            item.carpentercharges,
                            item.amount,
                            item.sgst,
                            item.cgst,
                            item.igst,
                            item.storagecharges,
                            item.saidtocontain,
                            item.declaredvalue,
                            item.cash,
                            item.gstno,
                            e
                          )
                        }
                      >
                        <i className="fas fa-sms"></i>
                      </Button>{" "}
                      <Button
                        size="sm"
                        variant="primary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="GC Copy"
                        onClick={(e) => UpdateGCText(item)}
                      >
                        Text
                      </Button>{" "}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => viewdash(item, item.id)}
                      >
                        <i className="fas fa-eye"></i>{" "}
                      </Button>{" "}
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => editdash(item)}
                      >
                        <i className="fa fa-user"></i>
                      </Button>{" "}
                      {/*} <Button
                        variant="success"
                        size="sm"
                        onClick={() => editPersonal(item)}
                      >
                        <i className="fa fa-user"></i>
                      </Button>{" "} */}
                      <Button
                        variant="info"
                        size="sm"
                        onClick={() => editServiceInfo(item)}
                      >
                        <i className="fa fa-taxi"></i>
                      </Button>{" "}
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteClick(item, item.enquiry_id)}
                      >
                        <i className="fas fa-trash"></i>{" "}
                      </Button>{" "}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card style={{ display: "none", width: "auto" }}>
        <Table bordered id="bookedpdf" style={{ backgroundImage: "URL('https://cdn.pixabay.com/photo/2012/03/04/00/01/background-21717__480.jpg')" }}>
          <thead>
            <tr>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Mobile
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                From
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                To
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Name
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Load Details
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Amount
              </th>
              <th style={{ textAlign: "center" }} className="font-weight-bold">
                Confirm Date
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, id) => {
              return (
                <tr>
                  <td style={{ textAlign: "center" }}>{item.name}</td>
                  <td style={{ textAlign: "center" }}>{item.mobile}</td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city}>
                      {item.city}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <span data-toggle="tooltip" title={item.city_ds}>
                      {item.city_ds}
                    </span>
                  </td>
                  <td style={{ textAlign: "center" }}>{item.media}</td>
                  <td style={{ textAlign: "center" }}>{item.basis}</td>
                  <td style={{ textAlign: "center" }}>{item.amount}</td>
                  <td style={{ width: "250px" }}>{item.update_date}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>

      <Modal
        show={viewUser}
        onHide={() => setViewUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewBooked data={viewData} afterfinish={() => setViewUser(false)} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={viewReceiptUser}
        size="lg"
        onHide={() => setViewReceiptUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Receipt View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewReceipt
            data={viewReceiptData}
            afterfinish={() => setViewReceiptUser(false)}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={editUser}
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditBooked
            data={editConfrim}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* GC Print */}
      <div>

        <Card id="new_print_tbl" style={{ display: "none", zIndex: '1000' }}>
          <Card.Header >

            <div id="watermark">
              <img src="https://appleaderpackers.resellerindia.in/api/public/uploads/gc_new_one1.jpeg" height="1200" width="884" style={{ zIndex: "-1", position: "fixed" }} />
            </div>

          </Card.Header>
          <div>
            <Card.Body>
              <table width="120" height="25">
                <tbody>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "139px", paddingLeft: "110px", fontSize: "13px" }}>LP00{printData.enquiry_id}</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "139px", fontSize: "13px", paddingLeft: "310px" }}>{date}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "15px", paddingLeft: "185px", fontSize: "13px" }}>{printData.city}</p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "15px", fontSize: "13px", paddingLeft: "180px" }}>{printData.city_ds}</p>
                     
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "28px", paddingLeft: "145px", fontSize: "13px" }}>{printData.customer_title} {printData.name}</p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "28px", fontSize: "13px", paddingLeft: "180px" }}>{printData.customer_title} {printData.name}</p>
                    </td>
                  </tr>
                  
                  <tr>
                    <td>
                      <p style={{ paddingTop: "8px", paddingLeft: "145px", fontSize: "13px" }}> {printData.street} {printData.place}</p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "8px", fontSize: "13px", paddingLeft: "180px" }}>{printData.street_ds} {printData.place_ds}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "13px", paddingLeft: "145px", fontSize: "13px" }}>{printData.city} </p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "13px", fontSize: "13px", paddingLeft: "180px" }}>{printData.city_ds} </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "13px", paddingLeft: "145px", fontSize: "13px" }}>{printData.state} </p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "13px", fontSize: "13px", paddingLeft: "180px" }}>{printData.state_ds} </p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "35px", paddingLeft: "145px", fontSize: "13px" }}>{printData.mobile}</p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "35px", fontSize: "13px", paddingLeft: "180px" }}>{printData.mobile}</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "8px", paddingLeft: "370px", fontSize: "13px" }}>{printData.pincode}</p>
                    </td>
                    <td >
                      <p style={{ paddingTop: "20px", fontSize: "13px", paddingLeft: "320px" }}>{printData.pincode_ds}</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p style={{ paddingTop: "22px", paddingLeft: "200px", fontSize: "14px" }}>{printData.saidtocontain}</p>
                    </td>
                    <td >
                     <p style={{ paddingTop: "25px", fontSize: "13px", paddingLeft: "160px" }}>{printData.numberofpackages} &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {printData.actual_wt}<br></br><br></br><br></br><br></br>&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {printData.charged_wt}</p>
                    </td>
                  <tr>
                    <td>
                      <tr>
                        <td>
                          <p style={{ paddingTop: "75px", paddingLeft: "200px", fontSize: "14px" }}>{printData.declaredvalue}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p style={{ paddingTop: "70px", paddingLeft: "250px", fontSize: "14px" }}>{printData.cash}</p>
                        </td>
                      </tr>
                       
                        <tr>
                          <td> <p style={{ paddingTop: "138px", paddingLeft: "200px", fontSize: "14px" }}></p></td>
                        </tr>
                     
                      <tr>
                        <td>
                        <p style={{ paddingTop: "0px", paddingLeft: "200px", fontSize: "14px" }}>{printData.vehicle}</p>
                        
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p style={{paddingTop: "0px", fontSize: "13px", paddingLeft:"150%"}}>{printData.cgst}</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p style={{paddingTop: "0px", fontSize: "13px", paddingLeft:"150%"}}>{printData.sgst}</p>
                        </td>
                      </tr>
                     <tr>
                     <td >
                      <p style={{ paddingTop: "80px", fontSize: "13px", paddingLeft:"150%" }}>{printData.amount}</p>
                    </td>
                     </tr>
                     <tr>
                    <td colspan={2}>
                      <p style={{ paddingTop: "20px", paddingLeft: "200px", fontSize: "14px" }}>{numberToWords(parseFloat(printData.amount))} Rupees only.</p>
                    </td>

                  </tr>
                      
                    </td> 
                    </tr>
                   
                  </tr>
               
                  
                  <tr>
                    <td>
                      <p style={{ paddingTop: "586px", paddingLeft: "145px", fontSize: "13px" }}></p>
                    </td>
                   
                  </tr>

                  


                  
                 

                </tbody>
              </table>

              <img src="https://appleaderpackers.resellerindia.in/api/public/uploads/gc_new_two.jpg" height="1200" width="884" />
            </Card.Body>
          </div>
        </Card>
        <iframe
          name="print_frame"
          id="print_frame"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe>
      </div>

      {/*RECEIPT */}

      {/*<Card  style={{display: 'block' }}  id="new_print_receipt"   >
			<div  style={{ backgroundImage: `url(${photoUrl})` , backgroundRepeat:"no-repeat",backgroundSize: "100%" , height:"900px"}}>
				<div className="receiptprint">
					<span style={{top:'262px'}}>{printData.name}</span><br/>
					<span style={{top:'319px'}}>{printData.amount}</span><br/>
					<span style={{top:'377px'}}>Cash</span><br/>
					<span style={{top:'437px'}}>remarks</span><br/>
					<span style={{top: '527px',left: '-174px'}}>{printData.amount}</span>				  
				</div> 
			</div>
		</Card>
		<iframe name="print_receipt" id="print_receipt" width="0" height="0" frameborder="0" src="about:blank"></iframe>  */}
      <div>



        <Card id="new_print_receipt" style={{ display: "none", zIndex: '1000' }}>
          <Card.Header >

            {/*   <img src="https://thumbs.dreamstime.com/b/yellow-textured-plain-background-wallpaper-design-use-text-image-yellow-textured-plain-background-wallpaper-137515345.jpg" width="800" height="800" /> */}
            {/*  <img src="https://app.leaderpackers.com/api/public/uploads/receipt_header.jpg" alt="wrong path" /> */}

            <div id="watermark">
              <img src="https://appleaderpackers.resellerindia.in/api/public/uploads/receipt_resize.jpg" height="480" width="700" style={{ zIndex: "-1", position: "fixed" }} />
            </div>


          </Card.Header>
          <div>
            <Card.Body>
              <table width="100" height="25">
                <tbody>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "93px", paddingLeft: "80px", fontSize: "12px" }}>LP00{printData.enquiry_id}</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "93px", paddingLeft: "422px", fontSize: "12px" }}>{date}</p>
                    </td>
                  </tr>

                  <tr className="mt-5">
                    <td></td>
                    <td>
                      <p style={{ paddingTop: "22px", paddingLeft: "120px", fontSize: "12px" }}>{printData.customer_title} {printData.name}</p>
                    </td>

                  </tr>

                  <tr>
                    <td></td>
                    <td>
                      <p style={{ paddingTop: "20px", paddingLeft: "120px", fontSize: "12px" }}>{numberToWords(parseFloat(printData.amount))}  Only</p>
                    </td>
                  </tr>

                  <tr>
                    <td></td>
                    {printData.transferdetails ?
                    <td>
                      <p style={{ paddingTop: "65px", paddingLeft: "120px", fontSize: "12px" }}>{printData.transferdetails}</p>
                    </td>
                    :
                    <td> 
                      <p style={{ paddingTop: "65px", paddingLeft: "120px", fontSize: "12px" }}>-------</p>
                    </td>
                    }
                  </tr>

                  <tr>
                    <td></td>
                    {printData.towards ?
                    <td>
                      <p style={{ paddingTop: "20px", paddingLeft: "120px", fontSize: "9px" }}>
          
                        {printData.towards} 
                      </p>
                      
                    </td>
:
                    <td>
                      <p style={{ paddingTop: "20px", paddingLeft: "120px", fontSize: "9px" }}>
          
                       THE HOUSE HOLD ARTICLE TRANSPORTED FROM {printData.place} TO {printData.place_ds}
                      </p>
                      
                    </td>
}
                  </tr>


                  <tr>
                    <td></td>
                    <td>
                      <p style={{ paddingTop: "50px", fontSize: "14px" }}>
                        {printData.amount}
                      </p>
                    </td>
                  </tr>

                </tbody>
              </table>
            </Card.Body>
          </div>

        </Card>
        < iframe
          name="print_receipt"
          id="print_receipt"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe >
      </div >


      <div>



        <Card id="new_print_invoice" style={{ display: "none", zIndex: '1000' }}>
          <Card.Header >

            {/*   <img src="https://thumbs.dreamstime.com/b/yellow-textured-plain-background-wallpaper-design-use-text-image-yellow-textured-plain-background-wallpaper-137515345.jpg" width="800" height="800" /> */}
            {/*  <img src="https://app.leaderpackers.com/api/public/uploads/receipt_header.jpg" alt="wrong path" /> */}

            <div id="watermark">
              <img src="https://appleaderpackers.resellerindia.in/api/public/uploads/invoice_resize_new.jpg" height="1000" width="760" style={{ zIndex: "-1", position: "fixed" }} />
            </div>


          </Card.Header>
          <div>
            <Card.Body>
              <table width="100" height="100">
                <tbody>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "120px", paddingLeft: "80px", fontSize: "12px" }}>LP00{printData.enquiry_id}</p>
                    </td>
                    <td></td>
                    <td></td>
                    
                  </tr>

                  <tr>
                    <td colspan="2">
                      <p style={{ paddingTop: "7px", paddingLeft: "80px", fontSize: "11px" }}>{printData.customer_title} {printData.name}</p>
                      
                      <p style={{ paddingLeft: "80px", fontSize: "11px" }}>{printData.floor_ds}, {printData.street_ds}</p>
                      <p style={{ paddingLeft: "80px", fontSize: "11px" }}>{printData.place_ds},{printData.city_ds}</p>
                      <p style={{ paddingLeft: "80px", fontSize: "11px" }}>{printData.state_ds} {printData.pincode_ds}</p>

                    </td>
                    <td>
                      <p style={{ paddingBottom: "22px", paddingLeft: "23px", fontSize: "12px" }}>{printData.enquiry_id}</p>
                      <p style={{ paddingBottom: "8px", paddingLeft: "23px", fontSize: "12px" }}>{date}</p>
                    </td>

                  </tr>
                  <tr>
                    <td style={{ paddingTop: "30px", paddingLeft: "150px", fontSize: "8px" }}>{printData.place}</td>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "300px", fontSize: "8px" }}>{printData.place_ds}</p>
                    </td>
                    <td></td>
                  </tr>


                  <tr>
                    <td>
                      <p style={{ paddingTop: "32px", paddingLeft: "60px", fontSize: "11px" }}>1</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "36px", paddingLeft: "60px", fontSize: "11px" }}>Transportation Charges for house hold</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "60px", paddingTop: "45px", fontSize: "11px" }}>Rs.{printData.amount}</p>
                    </td>
                  </tr>


                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>2</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Packing Materials & Packing Charges</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "30px", fontSize: "11px", paddingRight: "40px" }}>-</p>
                    </td>
                  </tr>



                  <tr>
                    <td>
                      <p style={{ paddingTop: "25px", paddingLeft: "65px", fontSize: "11px" }}>3</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Loading Charges</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "32px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>4</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Unloading Charges</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "32px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>


                  <tr>
                    <td>
                      <p style={{ paddingTop: "31px", paddingLeft: "65px", fontSize: "11px" }}>5</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Rearranging Charges</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "31px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>6</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Hamlai Charges @ unloading Place</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "31px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>7</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "25px", paddingLeft: "60px", fontSize: "11px" }}>Docket Charges</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "31px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>8</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Service Tax</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "30px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>


                  <tr>
                    <td>
                      <p style={{ paddingTop: "30px", paddingLeft: "65px", fontSize: "11px" }}>9</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "27px", paddingLeft: "60px", fontSize: "11px" }}>Insurance 3% of Declard Value</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "80px", paddingTop: "31px", fontSize: "11px" }}>-</p>
                    </td>
                  </tr>


                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <p style={{ paddingLeft: "75px", paddingTop: "10px", fontSize: "11px" }}>
                        {printData.amount}
                      </p>
                      <p style={{ paddingLeft: "75px", fontSize: "11px" }}>
                        67.%
                      </p>
                      <p style={{ paddingLeft: "75px", fontSize: "11px" }}>
                        67.%
                      </p>
                      <p style={{ paddingLeft: "75px", fontSize: "11px" }}>
                        67.%
                      </p>
                      <p style={{ paddingLeft: "75px", fontSize: "11px" }}>
                        {printData.amount}
                      </p>
                    </td>
                  </tr>


                  {/*   <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <p style={{ paddingLeft: "75px", fontSize: "11px" }}>
                        {printData.amount}
                      </p>
                    </td>
                  </tr> */}

                  <tr>
                    <td></td>
                    <td>
                      <p style={{ paddingTop: "15px", fontSize: "11px" }}>
                        {numberToWords(parseFloat(printData.amount))} Rupees only.
                      </p>
                    </td>
                    <td></td>
                  </tr>


                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <p style={{ paddingLeft: "65px", paddingTop: "110px", fontSize: "8px" }}>
                        V.VEEERAGANABATHI
                      </p>
                    </td>
                  </tr>


                </tbody>
              </table>
            </Card.Body>
          </div>

        </Card>
        < iframe
          name="print_invoice"
          id="print_invoice"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe >
      </div >

      {/* Invoice Bill */}
      {/*   <div>

        <Card id="new_print_invoice" style={{ display: "none", zIndex: '1000' }}>
          <Card.Header >


            <div id="watermark">
              <img src="https://app.leaderpackers.com/api/public/uploads/invoice_resize_new.jpg" height="1050" width="740" style={{ zIndex: "-1", position: "fixed" }} />
            </div>

          </Card.Header>

          <div>
            <Card.Body>
              <table width="100" height="100" >
                <tbody>
                  <tr>
                    <td>
                      <p style={{ paddingTop: "115px", paddingLeft: "78px", fontSize: "11px" }}>LP00{printData.enquiry_id}</p>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "23px", paddingLeft: "60px", fontSize: "10px" }}>{printData.name}</p>
                    </td>
                    <td>
                      <p style={{ paddingLeft: "400px", fontSize: "11px" }}>{printData.id}</p>
                    </td>
                    <td></td>
                  </tr>


                  <tr>
                    <td></td>
                    <td>
                      <p style={{ paddingTop: "25px", paddingLeft: "400px", fontSize: "11px" }}>{date}</p>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "35px", paddingLeft: "140px", fontSize: "8px" }}>{printData.place}</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "35px", paddingLeft: "140px", fontSize: "8px" }}>{printData.place_ds}</p>
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td>
                      <p style={{ paddingTop: "35px", fontSize: "8px" }}>1</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "35px", fontSize: "8px" }}>Transportation Charges for house hold</p>
                    </td>
                    <td>
                      <p style={{ paddingTop: "35px", fontSize: "8px" }}>Rs.{printData.amount}</p>
                    </td>
                  </tr>







                  <tr>
                    <td style={{ fontSize: "11px", paddingTop: "30px", paddingLeft: "60px" }}>1</td>
                    <td style={{ fontSize: "11px", paddingTop: "30px" }}>Transportation Charges for house hold</td>
                    <td style={{ fontSize: "11px", paddingTop: "30px" }}>Rs.{printData.amount}</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "11px", paddingTop: "30px" }}>2</td>
                    <td style={{ fontSize: "11px", paddingTop: "30px" }}>Packing Materials & Packing Charges</td>
                    <td style={{ fontSize: "11px", paddingTop: "30px" }}>Rs. -</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "11px" }}>3</td>
                    <td style={{ fontSize: "11px" }}>Loading Charges</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>

                  <tr>
                    <td style={{ fontSize: "11px" }}>4</td>
                    <td style={{ fontSize: "11px" }}>Unloading Charges</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>

                  <tr>
                    <td style={{ fontSize: "11px" }}>5</td>
                    <td style={{ fontSize: "11px" }}>Rearranging Charges</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>


                  <tr>
                    <td style={{ fontSize: "11px" }}>6</td>
                    <td style={{ fontSize: "11px" }}>Hamlai Charges @ unloading Place</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "11px" }}>7</td>
                    <td style={{ fontSize: "11px" }}>Docket Charges</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "11px" }}>8</td>
                    <td style={{ fontSize: "11px" }}>Service Tax</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>
                  <tr>
                    <td style={{ fontSize: "11px" }}>9</td>
                    <td style={{ fontSize: "11px" }}>Insurance 3% of Declard Value</td>
                    <td style={{ fontSize: "11px" }}>Rs. -</td>
                  </tr>

                  <tr >
                    <td style={{ fontSize: "11px" }}>{printData.amount}</td>
                  </tr>



                  <tr>
                    <td style={{ fontSize: "11px" }}>{printData.amount}</td>
                  </tr>

                  <tr>
                    <td style={{ fontSize: "11px" }}><b>{numberToWords(parseFloat(printData.amount))} Rupees only.</b>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ fontSize: "11px" }}> V.VEEERAGANABATHI</td>
                  </tr>


                </tbody>
              </table>
            </Card.Body>
          </div>
        </Card>
        <iframe
          name="print_invoice"
          id="print_invoice"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe>
      </div> */}


      <Modal
        show={updateAmount}
        size="lg"
        onHide={() => setUpdateAmount(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Quoteee</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QuotationBooked
            data={editData}
            afterFinish={() => {
              setUpdateAmount(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={updateGCText}
        size="md"
        onHide={() => setUpdateGCText(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Text</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UpdateText
            data={editData}
            afterFinish={() => {
              setUpdateGCText(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/*} <Modal
        show={editUser}
        size="lg"
        onHide={() => setEditUser(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Personal Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditPersonal
            data={editData}
            afterFinish={() => {
              setEditUser(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
          </Modal> */}
      <Modal
        show={editService}
        size="lg"
        onHide={() => setEditService(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Service Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditService
            data={editData}
            afterFinish={() => {
              setEditService(false);
              listdata();
            }}
          />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
export default Booked;
