import React, { useState, useContext, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import toast from "react-hot-toast";
import PsContext from "../../../context";
import API from "../../../utils/api";
import { integerIndMobile, integerKeyPress } from "../../../utils";
import $ from "jquery";
import { Link } from "react-router-dom";

const ReceiptView = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [adminCompany, setAdminCompany] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setDataList] = useState(false);
  const [printData, setPrintData] = useState([]);
  const [print, setPrint] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      setBtnLoading(true);
      API.post("/v1/user/saveenquiry", $("#addform").serialize()).then(
        (res) => {
          if (res["data"].errorcode === "200") {
            toast.success("Saved Successfully..!", "success");
            //document.getElementById("addform").reset();
            setBtnLoading(false);
            if (props.afterFinish) props.afterFinish(res["data"].data);
          } else {
            toast.error("Something Went Wrong..!", "danger");
            //document.getElementById("addform").reset();
            setBtnLoading(false);
          }
          setIsLoaded(false);
        }
      );
    } catch (error) {}
  };

  const handlePrint = (dt) => {
    setPrintData(dt);
    setPrint(true);
    setTimeout(() => printDocument(), 1000);
  };

  const printDocument = () => {
    var content = document.getElementById("new_print_tbl");
    var pri = document.getElementById("print_frame").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <>
      <Button
        style={{ float: "right" }}
        variant="primary"
        size="sm"
        onClick={(e) => handlePrint}
        data-toggle="tooltip"
        data-placement="top"
        title="GC Print"
      >
        <i className="fa fa-print"></i> Print
      </Button>{" "}
      <Card className="mt-5" id="new_print_tbl">
        <Card.Header style={{ textAlign: "center" }}>
          <h4>Leader Packers And Movers</h4>
          <p>
            6,TTK Nagar, 2nd Main Road Irumbuliyur, West Tambaram,Chennai - 45.
            <br />
            <b>E-Mail :</b> leaderpackersindia@gmail.com <b>Website : </b>
            www.leaderpackers.com <b>Cell :</b> 9944029699
          </p>
        </Card.Header>
        <div style={{ textAlign: "center" }}>
          <h5 className="mt-2">RECEIPT</h5>
          <Card.Body>
            <Row>
              <Col md={12}>
                <Row className="mt-2">
                  <Col md={4}>
                    <label>Received with thanks from M/s. </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      className="text-uppercase"
                      //defaultValue={props.data.name}
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mt-2">
                  <Col md={4}>
                    <label>the sum of rupees</label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      className="text-uppercase"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mt-2">
                  <Col md={4}>
                    <label>
                      by <input type="checkbox" /> Cash/{" "}
                      <input type="checkbox" /> Cheque/{" "}
                      <input type="checkbox" /> DD.No.
                    </label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      className="text-uppercase"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={12}>
                <Row className="mt-2">
                  <Col md={4}>
                    <label>towards</label>
                  </Col>
                  <Col md={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      className="text-uppercase"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={3}>
                <Row>
                  <Col md={2}>
                    <label>Rs.</label>
                  </Col>
                  <Col md={10}>
                    <Form.Control
                      type="text"
                      size="sm"
                      name="name"
                      className="text-uppercase"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}></Col>
              <Col md={3}>
                <label>For Leaders Packers And Movers</label>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={10}></Col>
              <Col md={2}>
                <label>Authorized Signatory</label>
              </Col>
            </Row>
          </Card.Body>
        </div>
        <iframe
          name="print_frame"
          id="print_frame"
          width="0"
          height="0"
          frameborder="0"
          src="about:blank"
        ></iframe>
      </Card>
    </>
  );
};
export default ReceiptView;
